import { FormEvent, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { finishEnrollMultiFactor } from "./mfaHelper";
import styles from "./mfa.module.css";
import { AuthContext } from "components/authProvider/authProvider";
import { host } from "config";

const ConfirmMFAEnroll: React.FC = () => {
  const [mfaCode, setMFACode] = useState("");
  const [mfaSuccess, setMFASuccess] = useState(false);

  const navigate = useNavigate();

  const authContext = useContext(AuthContext);
  if (!authContext) {
    throw new Error("AuthContext is undefined");
  }
  const { firstName, lastName, currentUser, setHasMultiFactor } = authContext;

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      await finishEnrollMultiFactor(mfaCode);
      setMFACode("");
      currentUser?.getIdToken().then((idToken) => {
        fetch(`${host}/api/firebase/api/enrolledIn2FA`, {
          method: "GET",
          headers: {
            authorization: `Bearer ${idToken}`,
          },
        }).then((response: Response) => {
          response.json().then((res) => {
            setHasMultiFactor(res.data.hasMultiFactor);
            setMFASuccess(true);
          });
        });
      });
    } catch (e) {
      console.log(e);
      alert(e);
    }
  };
  return (
    <div className={styles["centered-container"]}>
      {/* <h3>
        Welcome! {firstName} {lastName}
      </h3> */}
      <div className={styles["card"]}>
        {mfaSuccess ? (
          <>
            <h3>You have successfully enrolled in Two Factor Authentication</h3>
            <button onClick={() => navigate("/basePage")}>Go to Dashboard</button>
          </>
        ) : (
          <>
            {/* <h4>Two Factor Authentication</h4>
            <p>Please input the code that you received in the text message sent to you.</p> */}
            <div className={styles.boxStyle}>
              <h2>OptiAdmin Two Factor Authentication</h2>

              <h6>Please input the 6 digit code that you received via text message</h6>
            </div>
            <form onSubmit={handleSubmit}>
              <label>Two Factor Authentication Required</label>
              <input
                type="text"
                value={mfaCode}
                onChange={(e) => setMFACode(e.target.value)}
                placeholder="MFA Verification Code"
              ></input>
              <button type="submit">Submit</button>
            </form>
          </>
        )}
      </div>
    </div>
  );
};

export default ConfirmMFAEnroll;
