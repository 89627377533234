import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "../authProvider/authProvider";

import React from "react";
import { CircularProgress } from "@mui/material";

type AuthProps = {
  allowedRoles: string[];
  // tileAccess2: string[];
};

const Auth: React.FunctionComponent<AuthProps> = (props) => {
  const { allowedRoles } = props;
  const context = useContext(AuthContext);
  const location = useLocation();
  if (!context) {
    throw new Error(`Auth context is undefined`);
  }

  //console.log(AuthContext)
  const { role, currentUser, loading, hasMultiFactor, tileAccess } = context;
  if (loading) {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </div>
    );
  }

  console.log("CHECKING LOCATION BASENAME: ", location.pathname);
  // console.log(currentUser);
  if (currentUser != null && currentUser !== undefined) {
    if (tileAccess != null || tileAccess !== undefined) {
      if ((tileAccess != null || tileAccess !== undefined) && tileAccess.includes(location.pathname)) {
        return <Navigate to="/basepage" state={{ from: location }} replace />;
      }
    }
  }

  return currentUser ? (
    !hasMultiFactor && location.pathname != "/mfaEnroll" && location.pathname != "/confirmMFAEnroll" ? (
      <Navigate to="/mfaEnroll" state={{ from: location }} replace />
    ) : allowedRoles.length == 0 || allowedRoles.find((allowedRole) => role === allowedRole) ? (
      // auth.role.find(role => allowedRoles?.includes(role))
      <Outlet />
    ) : (
      <Navigate to="/unauthorized" state={{ from: location }} replace />
    )
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default Auth;
