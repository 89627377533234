import { AuthContext } from "components/authProvider/authProvider";
import { FormEvent, useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CircularProgress, FormControl, Input, InputLabel } from "@mui/material";
import styles from "./acctManagement.module.css";
import { applyActionCode, checkActionCode, confirmPasswordReset, verifyPasswordResetCode } from "firebase/auth";
import { auth } from "config";

const AcctManagement: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [mode, setMode] = useState("");
  const [resetPasswordSubmitted, setResetPasswordSubmitted] = useState(false);
  const [resetPasswordSuccess, setResetPasswordSuccess] = useState(false);
  const [restoredEmail, setRestoredEmail] = useState("");
  const [recoverEmailSuccess, setRecoverEmailSuccess] = useState(false);
  const [verifyEmailSuccess, setVerifyEmailSuccess] = useState(false);
  const [actionCode, setActionCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordMatch, setPasswordMatch] = useState(true);

  useEffect(() => {
    const inMode = searchParams.get("mode");
    setMode(inMode as string);
    const actionCode = searchParams.get("oobCode") as string;
    setActionCode(actionCode);

    switch (inMode) {
      case "resetPassword":
        break;
      case "recoverEmail":
        let inRestoredEmail: any = null;
        checkActionCode(auth, actionCode)
          .then((info) => {
            inRestoredEmail = info["data"]["email"];
            return applyActionCode(auth, actionCode);
          })
          .then(() => {
            setRestoredEmail(inRestoredEmail as string);
            setRecoverEmailSuccess(true);
            setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
            alert(error);
          });
        break;
      case "verifyEmail":
        applyActionCode(auth, actionCode)
          .then((resp) => {
            setVerifyEmailSuccess(true);
            setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
            alert(error);
          });
        break;
      default:
        alert("The action code you specified is not an allowed action.");
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    if (newPassword != confirmPassword) {
      setPasswordMatch(false);
    } else {
      setPasswordMatch(true);
    }
  }, [newPassword, confirmPassword]);

  const resetPassword = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (newPassword != confirmPassword) {
      alert("Your passwords do not match.");
      return;
    }
    setLoading(true);
    setResetPasswordSubmitted(true);

    verifyPasswordResetCode(auth, actionCode)
      .then((email) => {
        confirmPasswordReset(auth, actionCode, newPassword)
          .then((resp) => {
            setResetPasswordSuccess(true);
            setLoading(false);
          })
          .catch((error) => {
            setResetPasswordSuccess(false);
            setLoading(false);
            alert(error);
          });
      })
      .catch((error) => {
        setResetPasswordSuccess(false);
        setLoading(false);
        alert(error);
      });
  };

  if (loading) {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      <div className={styles["centered-container"]}>
        <h3>Welcome!</h3>
        {mode === "resetPassword" &&
          (!resetPasswordSubmitted ? (
            <div className={styles["card"]}>
              <h4>Reset Your Password</h4>
              <p>Please input your new password and click submit to change your password.</p>
              <form onSubmit={resetPassword}>
                <label>New Password</label>
                <input
                  type="password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  placeholder="New Password"
                ></input>
                <label htmlFor="confirmPassword" className="form-label">
                  Confirm Password
                </label>
                <input
                  type="password"
                  name="confirmPassword"
                  placeholder="Confirm Password"
                  className="form-input"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                {!passwordMatch && <div className="error-message">Your passwords do not match.</div>}
                <button type="submit" id="sign-in-button">
                  Submit
                </button>
              </form>
            </div>
          ) : (
            <>
              <div className={styles["card"]}>
                <h4>Reset Your Password</h4>
                {resetPasswordSuccess ? (
                  <p>Your password has successfully been reset.</p>
                ) : (
                  <p>An error occured while resetting your password.</p>
                )}
              </div>
            </>
          ))}
        {mode === "recoverEmail" && (
          <>
            <div className={styles["card"]}>
              <h4>Recover Your Email</h4>
              {recoverEmailSuccess ? (
                <p>Your email {restoredEmail} was successfully recoverd.</p>
              ) : (
                <p>An error occured while recovering your email.</p>
              )}
            </div>
          </>
        )}
        {mode === "verifyEmail" && (
          <>
            <div className={styles["card"]}>
              <h4>Verify Your Email</h4>
              {verifyEmailSuccess ? (
                <p>Your email was successfully verified.</p>
              ) : (
                <p>An error occured while verifying your email.</p>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default AcctManagement;
