import * as React from "react";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
// import "./dataComponent.css";
import "./MUIstyling.css";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import GlobalStyles from "@mui/material/GlobalStyles";

// Define the custom theme
// const theme = createTheme({
//   components: {
//     MuiDataGrid: {
//       styleOverrides: {
//         columnHeaders: {
//           '& .MuiDataGrid-sortIcon': {
//             color: 'inherit', // Default color
//             '&:hover': {
//               color: 'green', // Change to green on hover
//             },
//           },
//         },
//       },
//     },
//   },

export type DataType = {
  uid: string; // Unique identifier
  Ind?: string; // Optional if not always present
  Last_Name: string;
  Status: string;
  Claim_Number: string;
  Paid_Amount: string;
  Paid_Date: string;
  Provider_Name: string;
  Tax_ID: string;
  Provider_Addr1: string;
  Provider_City: string;
  Provider_State: string;
  Provider_Zip: string;
  Billed_Amount: string;
  Not_Covered_Amount: string;
  Date_Of_Service: string;
  Co_Pay_Amount: string;
};

type ApiDataComponentProps = {
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  data: DataType[];
};

interface fieldsToDisplay {
  Ind: string;
  Last_Name: string;
  Status: string;
  Claim_Number: string;
  Paid_Amount: string;
  Billed_Amount: string;
  Not_Covered_Amount: string;
  Co_Pay_Amount: string;
  Paid_Date: string;
  Date_Of_Service: string;
  Provider_Name: string;
}

function formatData(input: DataType): DataType {
  return {
    uid: input.uid,
    Ind: input.Ind,
    Last_Name: input.Last_Name,
    Status: input.Status,
    Claim_Number: input.Claim_Number,
    Paid_Amount: input.Paid_Amount,
    Billed_Amount: input.Billed_Amount,
    Not_Covered_Amount: input.Not_Covered_Amount,
    Co_Pay_Amount: input.Co_Pay_Amount,
    Paid_Date: input.Paid_Date,
    Date_Of_Service: input.Date_Of_Service,
    Provider_Name: input.Provider_Name,
    Tax_ID: input.Tax_ID,
    Provider_Addr1: input.Provider_Addr1,
    Provider_City: input.Provider_City,
    Provider_State: input.Provider_State,
    Provider_Zip: input.Provider_Zip,
  };
}

const moneyFields = ["Paid_Amount", "Billed_Amount", "Not_Covered_Amount", "Co_Pay_Amount"];

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

// const theme = createTheme({
//   // any other theme customizations can go here
// });

// // Global style overrides specifically for DataGrid components
// const globalStyles = (
//   <GlobalStyles
//     styles={{
//       ".MuiDataGrid-columnHeaders .MuiDataGrid-sortIcon": {
//         color: "inherit", // Default sort icon color
//         "&:hover": {
//           color: "green", // Sort icon color changes to green on hover
//         },
//       },
//     }}
//   />
// );

declare module "@mui/material/styles" {
  interface Components {
    // This should extend the existing MUI theme types with the new `MuiDataGrid` component.
    MuiDataGrid?: {
      styleOverrides?: {
        // Your style overrides here
        columnHeaders: {
          "& .MuiDataGrid-sortIcon": {
            color: "white"; // Default color
            "&:hover": {
              color: "white"; // Change to white on hover
            };
          };
        };
      };
    };
  }
}

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeaders: {
          "& .MuiDataGrid-sortIcon": {
            color: "white",
            "&:hover": {
              color: "white",
            },
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: "white", // For other icon buttons
          "&:hover": {
            backgroundColor: "rgba(255, 255, 255, 0.08)",
            color: "white",
          },
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        selectIcon: {
          color: "black",
        },
        select: {
          color: "black",
        },
        actions: {
          "& .MuiIconButton-root": {
            color: "black", // Ensures the pagination arrows are always black
            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.04)", // Adds a subtle background on hover
              color: "black", // Keeps the icon black on hover
            },
          },
        },
      },
    },
  },
});

// const theme = createTheme({
//   components: {
//     // Override styles for DataGrid here
//     MuiDataGrid?: {
//       styleOverrides: {
//         columnHeaders: {
//           "& .MuiDataGrid-sortIcon": {
//             color: "inherit", // Default color
//             "&:hover": {
//               color: "white", // Change to white on hover
//             },
//           },
//         },
//       },
//     },
//   },
// });

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells: GridColDef[] = [
  {
    field: "Last_Name",
    headerName: "Last Name",
    width: 140,
    editable: false,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "Status",
    width: 130,
    editable: false,
    headerName: "Status",
    headerClassName: "super-app-theme--header",
  },
  {
    field: "Claim_Number",
    width: 150,
    editable: false,
    headerName: "Claim Number",
    headerClassName: "super-app-theme--header",
  },
  {
    field: "Paid_Amount",
    width: 150,
    editable: false,
    headerName: "Paid Amount",
    headerClassName: "super-app-theme--header",
  },
  {
    field: "Billed_Amount",
    width: 180,
    editable: false,
    headerName: "Billed_Amount",
    headerClassName: "super-app-theme--header",
  },
  {
    field: "Not_Covered_Amount",
    width: 200,
    editable: false,
    headerName: "Not Covered Amount",
    headerClassName: "super-app-theme--header",
  },
  {
    field: "Co_Pay_Amount",
    width: 150,
    editable: false,
    headerName: "Co Pay Amount",
    headerClassName: "super-app-theme--header",
  },
  {
    field: "Paid_Date",
    width: 150,
    editable: false,
    headerName: "Paid Date",
    headerClassName: "super-app-theme--header",
  },
  {
    field: "Date_Of_Service",
    width: 150,
    editable: false,
    headerName: "Date Of Service",
    headerClassName: "super-app-theme--header",
  },
  {
    field: "Provider_Name",
    width: 250,
    editable: false,
    headerName: "Provider Name",
    headerClassName: "super-app-theme--header",
  },
];

export const DataGridDemo = (props: { data: DataType[] }) => {
  const rows = props.data.map((entry) => formatData(entry));
  function getRowIds(row) {
    return row.Ind;
  }
  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          height: 400,
          width: "89%", // Updated to 100%
          backgroundColor: "#f8f8f8",
          boxShadow: "0 4px 8px rgba(0,0,0,0.2)", // Enhanced shadow
          padding: "20px",
          margin: "auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          "& .super-app-theme--header": {
            backgroundColor: "#004e9d",
            color: "#ffffff",
            padding: "10px 20px",
            boxShadow: "0 3px 6px rgba(0,0,0,0.2)", // Enhanced shadow
            fontWeight: "bold",
          },
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "1px solid #e0e0e0",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "rgba(0, 0, 0, 0.04)",
            color: "#333",
          },
        }}
      >
        <DataGrid
          sx={{
            height: "auto", // Allows DataGrid to grow with content up to the Box height
            width: "100%", // Ensures DataGrid uses full width of the Box, considering padding
            "& .MuiDataGrid-virtualScroller": {
              marginTop: "0 !important", // Adjusts the header alignment issue
            },
          }}
          rows={rows}
          columns={headCells}
          getRowId={(row) => row.uid}
          disableColumnSelector
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          pageSizeOptions={[5]}
          disableRowSelectionOnClick
        />
      </Box>
    </ThemeProvider>
  );
};

export default DataGridDemo;
