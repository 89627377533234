import React, { useState, FormEvent, useContext, useRef, useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Navigate } from "react-router-dom";
import {
  signOut,
  createUserWithEmailAndPassword,
  getAuth,
  User,
  signInWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
import { getFirestore, doc, setDoc, Timestamp, getDoc, DocumentData } from "firebase/firestore";
import { auth, app, host } from "../../config";
import "components/signup/SignUp.css";
import { AuthContext } from "components/authProvider/authProvider";
import { Link } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import CustomAlert from "components/alert/CustomAlert";

const db = getFirestore(app);

var auth2 = getAuth();

const SignUp: React.FC = () => {
  const authContext = useContext(AuthContext);
  // Get Auth context
  if (!authContext) {
    throw new Error("AuthContext is undefined");
  }
  const {
    currentUser,
    setCurrentUser,
    setRole,
    setEmail,
    setSuspended,
    setFirstName,
    setLastName,
    setAuthorizedGroupNumber,
    setAuthorizedGroupNumbers,
  } = authContext;

  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        console.log("Signed out");
        //goToHome();
      })
      .catch((error) => {
        console.error("Error signing out", error);
      });
  };

  handleSignOut();

  const [recaptchaValue, setRecaptchaValue] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const [confirmEmail, setConfirmEmail] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const [email, setEmail2] = useState("");
  const [password, setPassword] = useState("");

  const [isAlertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState<string>("");

  const [shouldNavigate, setShouldNavigate] = useState(false);

  const [userRole, setUserRole] = useState("");

  const openAlert = () => {
    setAlertOpen(true);
  };

  const closeAlert = () => {
    setAlertOpen(false);
  };

  const handleNavigation = () => {
    setIsLoading(false);
    setAlertMessage(
      "You will need to veirfy your email now. You will be automatically redirected to the login page in 15 seconds"
    );
    setAlertOpen(true);

    // Wait for a few seconds and then navigate
    setTimeout(() => {
      setShouldNavigate(true);
    }, 15000); // Delay of 3000 milliseconds (3 seconds)
  };

  const captchaRef = useRef<ReCAPTCHA | null>(null);

  useEffect(() => {
    if (currentUser) {
      console.log("yooooooooooooo active");
      setIsLoading(true);
      sendEmailVerification(currentUser)
        .then(() => {
          handleNavigation();
        })
        .catch((error) => {
          console.error("Error sending email verification:", error);
        })
        .finally(() => {
          setIsLoading(false);
          console.log("Processing finished");
        });
    } else {
      console.error("Current user is null");
    }
  }, [currentUser]);

  function onChange(value: any) {
    console.log("Captcha value:", value);
    setRecaptchaValue(value); // Update the reCAPTCHA value
  }

  const handleEmailChange = (e: { target: { value: any } }) => {
    const newEmail = e.target.value;
    setEmail2(newEmail); // Assuming setEmail2 updates the email state

    // Use the new value directly instead of the one from state
    if (confirmEmail !== "") {
      setEmailError(confirmEmail === newEmail ? "" : "Emails do not match");
    }
  };

  const handlePassChange = (e: { target: { value: any } }) => {
    const newPassword = e.target.value;
    setPassword(newPassword); // Assuming setPassword updates the password state

    // Use the new value directly instead of the one from state
    if (confirmPassword !== "") {
      setPasswordError(confirmPassword === newPassword ? "" : "Passwords do not match");
    }
  };

  const handleConEmailChange = (e: { target: { value: any } }) => {
    const value = e.target.value;
    setConfirmEmail(value);
    setEmailError(value === email ? "" : "Emails do not match");
  };

  const handleConPasswordChange = (e: { target: { value: any } }) => {
    const value = e.target.value;
    setConfirmPassword(value);
    setPasswordError(value === password ? "" : "Passwords do not match");
  };

  const handleUserRoleChange = (e) => {
    setUserRole(e.target.value);
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log("checking the current target");
    console.log(e.currentTarget.elements);
    const {
      email,
      password,
      confirmEmail,
      confirmPassword,
      firstname,
      lastname,
      userRoleRequested,
      requestedGroupName,
      requestedAgencyName,
    } = e.currentTarget.elements as any;

    if (password.value.length < 6) {
      alert("password needs to at least be 6 characters");
    }

    if (userRoleRequested.value === "") {
      alert("You need to select a role.");
    }

    if (email.value !== confirmEmail.value) {
      alert("Emails do not match.");
      return;
    }

    if (password.value !== confirmPassword.value) {
      alert("Passwords do not match.");
      return;
    }

    if (emailError || passwordError) {
      alert("Please correct the errors before submitting.");
      return;
    }

    const token = captchaRef.current?.getValue();
    captchaRef.current?.reset();

    if (!recaptchaValue) {
      alert("Please verify you are not a robot.");
      return;
    }
    var data = {
      Email: email.value,
      Password: password.value,
      FirstName: firstname.value,
      LastName: lastname.value,
      UserRoleRequested: userRoleRequested.value,
      RequestedGroupName: requestedGroupName?.value,
      RequestedAgencyName: requestedAgencyName?.value,
    };

    console.log("Data to be sent to the server:", data);

    setIsLoading(true);

    await fetch(`${host}/api/firebase/api/CData2`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then(async (data: any) => {
        //setIsLoading(false);
        if (data.message === "Email used") {
          alert("Email Already in use");
        }

        try {
          await signInWithEmailAndPassword(auth, data.Email, data.Password);

          if (auth.currentUser) {
            const uid = auth.currentUser.uid;
            const userDoc = doc(db, "user-info", uid);
            try {
              // Fetch the document from Firestore
              let docSnap = await getDoc(userDoc);

              // If the document exists, save its data to state
              if (docSnap.exists()) {
                const data: DocumentData = docSnap.data();
                setCurrentUser(auth.currentUser);
                setFirstName(data?.First_Name);
                setLastName(data?.Last_Name);
                setRole(data?.Role);
                setEmail(data?.Email);
                setSuspended(data?.Suspended);
                setAuthorizedGroupNumber(data?.Group_number);
                setAuthorizedGroupNumbers(data?.Group_numbers);
              } else {
                console.log("No such document!");
              }
            } catch (error) {
              console.log("Error getting document:", error);
            }
          }
        } catch (error) {
          setIsLoading(false);
          console.error("Error:", error);
        }
      })
      .catch((error: any) => {
        setIsLoading(false);
        console.error("Error:", error);
      })
      .finally(() => {
        setIsLoading(false);
        console.log("Processing finished");
      });
  };

  // console.log("hey this is checker yo!")

  // if (currentUser) {
  //   console.log("yooooooooooooo active");
  //   setIsLoading(true);
  //   sendEmailVerification(currentUser)
  //     .then(() => {
  //       // Email verification sent!
  //       //alert("Please verify your email");
  //       // setAlertMessage("Please verify your email");
  //       // setAlertOpen(true);
  //       handleNavigation();
  //     })
  //     .catch((error) => {
  //       console.error("Error sending email verification:", error);
  //     })
  //     .finally(() => {
  //       setIsLoading(false);
  //       console.log("Processing finished");
  //     });
  // } else {
  //   console.error("Current user is null");
  // }

  if (shouldNavigate) {
    return <Navigate to="/login" />;
  }

  if (isLoading) {
    return (
      <div className="loader-container">
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      <div>
        <CustomAlert message={alertMessage} isOpen={isAlertOpen} onClose={closeAlert} />
        <h1 className="signup-title">Sign Up</h1>
        <form onSubmit={handleSubmit} className="signup-form">
          <label htmlFor="email" className="form-label">
            Email
          </label>
          <input
            type="email"
            name="email"
            placeholder="Email"
            className="form-input"
            value={email}
            onChange={handleEmailChange}
          />

          <label htmlFor="confirmEmail" className="form-label">
            Confirm Email
          </label>
          <input
            type="email"
            name="confirmEmail"
            placeholder="Confirm Email"
            className="form-input"
            value={confirmEmail}
            onChange={handleConEmailChange}
          />
          {emailError && <div className="error-message">{emailError}</div>}
          <label htmlFor="password" className="form-label">
            Password
          </label>
          <input
            type="password"
            name="password"
            placeholder="Password"
            className="form-input"
            value={password}
            onChange={handlePassChange}
          />

          <label htmlFor="confirmPassword" className="form-label">
            Confirm Password
          </label>
          <input
            type="password"
            name="confirmPassword"
            placeholder="Confirm Password"
            className="form-input"
            value={confirmPassword}
            onChange={handleConPasswordChange}
          />
          {passwordError && <div className="error-message">{passwordError}</div>}
          <label htmlFor="firstname" className="form-label">
            First Name
          </label>
          <input type="text" name="firstname" placeholder="First Name" className="form-input" />
          <label htmlFor="lastname" className="form-label">
            Last Name
          </label>
          <input type="text" name="lastname" placeholder="Last Name" className="form-input" />

          <label htmlFor="userRole" className="form-label">
            Intended Role
          </label>
          <select name="userRoleRequested" className="form-input" onChange={handleUserRoleChange}>
            <option value="">Select a role...</option>
            <option value="broker">Broker</option>
            <option value="employer">Employer</option>
            <option value="internal_ugp">Internal UGP</option>
          </select>

          <small className="form-noteReg">
            Note: Your role will need to be verified by an admin before it becomes active.
          </small>

          {userRole === "employer" && (
            <div className="wide-input-container">
              <label htmlFor="requestedGroupName" className="form-label">
                Group Name
              </label>
              <input type="text" name="requestedGroupName" placeholder="Group Name" className="form-input" />
            </div>
          )}

          {userRole === "broker" && (
            <div className="wide-input-container">
              <label htmlFor="requestedAgencyName" className="form-label">
                Agency name
              </label>
              <input type="text" name="requestedAgencyName" placeholder="Agency Name" className="form-input" />
            </div>
          )}

          <ReCAPTCHA
            sitekey="6Lftt0EoAAAAAHah4Wv3DZVjYiacRU7xcVqhzm9U" // Use environment variable
            onChange={onChange}
            ref={captchaRef}
          />

          <button type="submit" className="submit-btn">
            Submit
          </button>
        </form>
        <div className="sign-in-box">
          Already have an account? <Link to="/login">Sign in</Link>.
        </div>
      </div>
    </>
  );
};

export default SignUp;
