import { AuthContext } from "components/authProvider/authProvider";
import { FormEvent, useContext, useState } from "react";
import { startEnrollMultiFactor } from "./mfaHelper";
import { useNavigate } from "react-router-dom";
import { FormControl, Input, InputLabel } from "@mui/material";
import styles from "./mfa.module.css";

const MFAEnroll: React.FC = () => {
  const [phoneNumber, setPhoneNumber] = useState<string>("");

  const navigate = useNavigate();

  const authContext = useContext(AuthContext);
  if (!authContext) {
    throw new Error("AuthContext is undefined");
  }
  const { firstName, lastName } = authContext;

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      if (phoneNumber) {
        const formattedPhoneNumber = phoneNumber.replace(/[^0-9]/g, "");
        await startEnrollMultiFactor("+1" + formattedPhoneNumber);
      }
      navigate("/confirmMFAEnroll");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={styles["centered-container"]}>
      {/* <h3>
        Welcome! {firstName} {lastName}
      </h3> */}
      <div className={styles["card"]}>
        {/* <h4>Two Factor Authentication</h4>
        <p>Please input your phone number and click submit to enroll in Two Factor Authentication</p>
        <i>Input your phone number with no spaces, parentheses, or dashes following this format: 1235551234</i> */}
        <div className={styles.boxStyle}>
          <h2>OptiAdmin Two Factor Authentication</h2>

          <h6>Please input your phone number</h6>
          <i>Input your phone number with no spaces, parentheses, or dashes following this format: 1235551234</i>
        </div>
        <form onSubmit={handleSubmit}>
          <label>Phone Number</label>
          <input
            type="tel"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            placeholder="1235551234"
          ></input>
          <button type="submit" id="sign-in-button">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default MFAEnroll;
