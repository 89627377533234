import CustomAlert from "components/alert/CustomAlert";

import React, { useState, useEffect, useContext } from "react";

import { Navigate, useNavigate } from "react-router-dom";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Box,
  TextField,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  Radio,
  Grid,
} from "@mui/material";
import { AuthContext } from "../authProvider/authProvider";
import Tooltip from "@mui/material/Tooltip";
import InputAdornment from "@mui/material/InputAdornment";
import InfoIcon from "@mui/icons-material/Info";
import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment";

import { app, host } from "../../config";
import "./enroll.css";

interface wltFormState {
  doh: string;
  empSSN: string;
  empdob: string;
  firstName: string;
  lastName: string;
  middleI: string;
  gender: string;
  addressOne: string;
  addressTwo: string;
  city: string;
  phyState: string;
  zip: string;
  cardRequest: string;
  email: string;
  planSelect: string;
  dependent: string;
  productFlages: flagOptionType[];
  depdob: string;
  depSSN: string;
  relationship: string;
  changeSelect: string;
  changeEffDate: string;
}

interface planType {
  groupNo: string;
  planNo: string;
  planName: string;
}

interface flagOptionType {
  id: string;
  name: string;
}

interface wltIFetchedData {
  plans: [planType];
  flags: [flagOptionType];
}

interface flagType {
  [id: string]: boolean;
}

interface FormState {
  Sex: string;
  Tier: string;
  Plan_ID: string[];
  EmpOrDep: string;
  Reason: string;
  EmpSSN: string;
  EmpDOB: string;
  Division_ID: string;
  Date_Enrolled: string;
  Birth_Date: string;
  First_Name: string;
  Last_Name: string;
  SSN: string;
  Address: string;
  City: string;
  Zip_Code: string;
  State: string;
  Group_ID: string;
  Relationship: string;
  Card_Request: string;
}

interface IFetchedData {
  Group_ID: string;
  Division_ID: string;
  Coverage_Start: string;
  Coverage_End: string;
  Name: string;
  Benefit_Description: string;
  Description: string;
  Plan_ID: string; // Add this line
  // Add other fields if needed
}

const EmployeeEnrollmentForm: React.FC = () => {
  const [wltForm, setWltForm] = useState<wltFormState>({
    doh: "",
    empSSN: "",
    empdob: "",
    firstName: "",
    lastName: "",
    middleI: "",
    gender: "",
    addressOne: "",
    addressTwo: "",
    city: "",
    phyState: "",
    zip: "",
    cardRequest: "",
    email: "",
    planSelect: "",
    dependent: "Employee",
    productFlages: [],
    depdob: "",
    depSSN: "",
    relationship: "C",
    changeSelect: "",
    changeEffDate: "",
  });

  const [wltPlanOptions, setWltPlanOptions] = useState<planType[]>([]);

  const [wltFlagOptions, setWltFlagOptions] = useState<flagOptionType[]>([]);

  const [wltFlags, setWltFlags] = useState<flagType>({});

  const [wltShowFormDetails, setWltShowFormDetails] = useState(false);

  const [form, setForm] = useState<FormState>({
    Sex: "",
    Tier: "EM",
    Plan_ID: [],
    Division_ID: "",
    EmpOrDep: "",
    Reason: "",
    EmpSSN: "",
    EmpDOB: "",
    Date_Enrolled: "",
    Birth_Date: "",
    First_Name: "",
    Last_Name: "",
    SSN: "",
    Address: "",
    City: "",
    Zip_Code: "",
    State: "",
    Group_ID: "",
    Relationship: "",
    Card_Request: "",
  });

  //  EmpOrDep: string;
  // EmpSSN: string;
  // EmpDOB: string;

  const [planOptions, setPlanOptions] = useState<{ Description: string; Plan_ID: string }[]>([]);

  const [divisionOptions, setDivisionOptions] = useState<{ Name: string; Division_ID: string }[]>([]);

  const [planSelection, showPlanSelection] = useState<boolean>(false);

  const [divSelection, showDivSelection] = useState<boolean>(false);

  const [loading, setLoading] = useState(false);

  const [showFormDetails, setShowFormDetails] = useState(false);

  const [currentGroup, setCurrentGroup] = useState("");

  const [selectedPlans, setSelectedPlans] = useState<string[]>([]);

  const [isAlertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState<string>("");

  const openAlert = () => {
    setAlertOpen(true);
  };

  const closeAlert = () => {
    setAlertOpen(false);
  };

  const authContext = useContext(AuthContext);
  if (!authContext) {
    throw new Error("AuthContext is undefined");
  }
  const { currentUser, role, selectedGroup } = authContext;

  const navigate = useNavigate();

  var tk: string;
  currentUser
    ?.getIdToken(false)
    .then((idToken: string) => {
      tk = idToken;
    })
    .catch((error: any) => {
      console.log(error);
    });

  useEffect(() => {
    if (role == "broker" || role == "employer")
      currentUser
        ?.getIdToken(false)
        .then((idToken: string) => {
          tk = idToken;
          fetchData(selectedGroup);
        })
        .catch((error: any) => {
          console.log(error);
        });
  }, [selectedGroup]);

  const goToEmployeeMaintenance = () => {
    navigate("/employeeMaintenance");
  };

  function cleanString(str: string) {
    //If we need to add more protections, we can.

    const newStr = str.replace(/,/g, "");

    return newStr;
  }

  const handleChange = (event: SelectChangeEvent<{ value: unknown; name: unknown }>) => {
    const updatedForm = {
      ...form,
      [event.target.name as string]: event.target.value as string,
    };

    setForm(updatedForm);
  };

  const handleChange2 = (event: React.ChangeEvent<{ value: unknown; name: unknown }>) => {
    setForm({
      ...form,
      [event.target.name as string]: event.target.value as string,
    });
  };

  const handleNumericInput = (event) => {
    // Regex to allow only numeric input
    const value = event.target.value.replace(/[^0-9]/g, "");
    setForm({
      ...form,
      [event.target.name]: value,
    });
  };

  const handleChange5 = (event: SelectChangeEvent<string[]>) => {
    // Handle multiple selections
    const value = event.target.value;
    const newSelectedPlans = typeof value === "string" ? value.split(",") : value;
    setSelectedPlans(newSelectedPlans);
    console.log("this is value");
    console.log(value);
    console.log("this is selected plans");
    console.log(selectedPlans);
    const updatedForm = {
      ...form,
      [event.target.name as string]: newSelectedPlans,
    };
    setForm(updatedForm);
    console.log(form);
  };

  // Function to handle API call
  const fetchData = async (groupNo: string) => {
    setLoading(true);

    try {
      const response = await fetch(`${host}/api/enrollments/groupPlans?group_number=${groupNo}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${tk}`,
        },
      })
        .then((response: Response) => {
          if (response.status === 200) {
            response.json().then((resp) => {
              if (resp.vba == true) {
                const uniqueGroups = resp.data.reduce((acc: any[], item: IFetchedData) => {
                  const isExist = acc.some((elem: any) => elem.Description === item.Description);
                  if (!isExist) {
                    acc.push({
                      Description: item.Description,
                      Plan_ID: item.Plan_ID,
                    });
                  }
                  return acc;
                }, []);
                const uniqueDivisions = resp.data.reduce((acc: any[], item: IFetchedData) => {
                  const isExist = acc.some((elem: any) => elem.Name === item.Name);

                  if (!isExist) {
                    acc.push({
                      Name: item.Name,
                      Division_ID: item.Division_ID,
                    });
                  }
                  return acc;
                }, []);
                setPlanOptions(uniqueGroups);
                setDivisionOptions(uniqueDivisions);
                showPlanSelection(true);
                showDivSelection(true);
                setShowFormDetails(true);
                setWltShowFormDetails(false);
              } else {
                const plans = resp.data.plans as planType[];

                const flagOptions = resp.data.flags as flagOptionType[];

                const flags = {};
                flagOptions.map((x) => {
                  Object.assign(flags, { [x.id]: true });
                });

                setWltPlanOptions(plans);
                setWltFlagOptions(flagOptions);
                setWltFlags(flags);
                setWltShowFormDetails(true);
                setShowFormDetails(false);
              }
            });
          } else {
            setLoading(false);

            setAlertMessage(`Error retrieving data. Please try again later. ${response.status}`);
            setAlertOpen(true);
          }
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          // Code to execute in the finally block
          // This will run regardless of whether an error occurred or not
          setLoading(false);
        });
      // Do something with the data
      // For example, update state or perform additional logic
    } catch (error) {
      console.error("Error:", error);

      setAlertMessage(
        `An error occurred: ${error.message}. Please verify all fields have been filled. Contact support if the issue persists.`
      );
      setAlertOpen(true);
    }
    setCurrentGroup(groupNo);
    setLoading(false);
    setForm({
      Sex: "",
      Tier: "EM",
      Plan_ID: [],
      Division_ID: "",
      EmpOrDep: "",
      Reason: "",
      EmpSSN: "",
      EmpDOB: "",
      Date_Enrolled: "",
      Birth_Date: "",
      First_Name: "",
      Last_Name: "",
      SSN: "",
      Address: "",
      City: "",
      Zip_Code: "",
      State: "",
      Group_ID: groupNo,
      Relationship: "",
      Card_Request: "",
    });
    setWltForm({
      doh: "",
      empSSN: "",
      empdob: "",
      firstName: "",
      lastName: "",
      middleI: "",
      gender: "",
      addressOne: "",
      addressTwo: "",
      city: "",
      phyState: "",
      zip: "",
      cardRequest: "",
      email: "",
      planSelect: "",
      dependent: "Employee",
      productFlages: [],
      depdob: "",
      depSSN: "",
      relationship: "C",
      changeSelect: "",
      changeEffDate: "",
    });
  };

  const handleButtonClick = () => {
    // Your logic for button click, perhaps using some values from state to call `fetchData`
    if (form && form.Group_ID) {
      fetchData(form.Group_ID);
    }
  };

  async function post(data: any) {
    const newData = { data: { ...data }, groupNo: currentGroup };

    await fetch(`${host}/api/enrollments/wlt/enrollMember`, {
      method: "POST",
      headers: {
        authorization: `Bearer ${tk}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newData),
    })
      .then((response: Response) => {
        if (response.ok) {
          response.text().then((res) => {
            console.log("Success:", res);
            setAlertMessage(`Submission was a success`);
            setAlertOpen(true);
          });
        } else {
          setAlertMessage(
            `An error occurred while trying to submit. Please verify all fields have been filled. Contact support if the issue persists.`
          );
          setAlertOpen(true);
        }
      })
      .catch((error: any) => {
        console.error("Error:", error);
        setAlertMessage(
          `An error occurred while trying to submit: ${error.message}. Please verify all fields have been filled. Contact support if the issue persists.`
        );
        setAlertOpen(true);
        throw error;
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const handleWLTSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    function bool2String(card: string) {
      if (card === "true") return "Yes";
      else return "No";
    }

    const benefits = {};
    function benefitsPayload() {
      wltFlagOptions.map((x) => {
        const obj = { [x.id]: wltFlags[x.id] };
        return Object.assign(benefits, obj);
      });
    }

    benefitsPayload();

    const data = {
      payload: {
        city: cleanString(wltForm.city),
        zip: wltForm.zip,
        state: wltForm.phyState,
        addr1: cleanString(wltForm.addressOne),
        addr2: cleanString(wltForm.addressTwo),
        person: wltForm.dependent,
        plan: parseInt(wltForm.planSelect),
        gender: wltForm.gender,
        email: currentUser?.email,
        edob: moment(wltForm.empdob).format("MM/DD/YYYY"),
        hiredate: moment(wltForm.doh).format("MM/DD/YYYY"),
        essn: wltForm.empSSN,
        fname: cleanString(wltForm.firstName),
        lname: cleanString(wltForm.lastName),
        idcard: bool2String(wltForm.cardRequest),
        action: "1",
        benefits: benefits,
      },
    };

    function checkRequiredFields() {
      const requiredFields = {
        city: "City",
        zip: "Zip Code",
        state: "State",
        addr1: "Address 1",
        person: "Relationship",
        plan: "Plan",
        gender: "Gender",
        email: "Email",
        edob: "Employee Date of Birth",
        essn: "Employee SSN",
        fname: "First Name",
        lname: "Last Name",
      };

      const emptyFields = Object.keys(requiredFields).filter(
        (field) => !data.payload[field] || (data.payload[field] && data.payload[field]?.toString().trim() === "")
      ).map((key) => requiredFields[key])
      if (emptyFields.length > 0) {
        throw new Error(`Missing required fields: ${emptyFields.join(", ")}.`);
      }
    }

    try {
      checkRequiredFields();

      //here we will set the design on the payload. Before this is done, we will need to validate the fields to make sure we only allow good date through
      if (wltForm.dependent === "Dependent") {
        if (wltForm.changeSelect === "2" || wltForm.changeSelect === "4" || wltForm.changeSelect === "5") {
          if (wltForm.changeSelect === "4") {
            const payload = {
              payload: {
                ...data.payload,
                relationship: "P",
                dssn: wltForm.depSSN,
                ddob: moment(wltForm.depdob).format("MM/DD/YYYY"),
                effective: moment(wltForm.changeEffDate).format("MM/DD/YYYY"),
                hiredate: moment().format("MM/DD/YYYY"),
              },
            };
            post(payload);
          } else {
            const payload = {
              payload: {
                ...data.payload,
                dssn: wltForm.depSSN,
                ddob: moment(wltForm.depdob).format("MM/DD/YYYY"),
                relationship: wltForm.relationship,
                effective: moment(wltForm.changeEffDate).format("MM/DD/YYYY"),
                hiredate: moment().format("MM/DD/YYYY"),
              },
            };
            post(payload);
          }
        } else if (wltForm.changeSelect === "3") {
          const payload = {
            payload: {
              ...data.payload,
              relationship: "C",
              dssn: wltForm.depSSN,
              ddob: moment(wltForm.depdob).format("MM/DD/YYYY"),
              effective: moment(wltForm.depdob).format("MM/DD/YYYY"),
              hiredate: moment().format("MM/DD/YYYY"),
            },
          };
          post(payload);
        } else {
          const payload = {
            payload: {
              ...data.payload,
              relationship: wltForm.relationship,
              dssn: wltForm.depSSN,
              ddob: moment(wltForm.depdob).format("MM/DD/YYYY"),
            },
          };
          post(payload);
        }
      } else if (wltForm.changeSelect === "2" || wltForm.changeSelect === "4" || wltForm.changeSelect === "5") {
        const payload = {
          payload: {
            ...data.payload,
            effective: moment(wltForm.changeEffDate).format("MM/DD/YYYY"),
            hiredate: moment().format("MM/DD/YYYY"),
          },
        };
        post(payload);
      } else {
        const payload = {
          payload: {
            ...data.payload,
          },
        };
        if (Object.values(benefits).includes(true)) {
          post(payload);
        }
      }
      resetForms();
    } catch (error) {
      console.error("Error:", error);
      // alert(
      //   `An error occurred: ${error.message}. Please verify all fields have been filled. Contact support if the issue persists.`
      // );

      setAlertMessage(
        `An error occurred: ${error.message}. Please verify all fields have been filled. Contact support if the issue persists.`
      );
      setAlertOpen(true);
    }
  };

  function resetForms() {
    setForm({
      Sex: "",
      Tier: "EM",
      Plan_ID: [],
      Division_ID: "",
      EmpOrDep: "",
      Reason: "",
      EmpSSN: "",
      EmpDOB: "",
      Date_Enrolled: "",
      Birth_Date: "",
      First_Name: "",
      Last_Name: "",
      SSN: "",
      Address: "",
      City: "",
      Zip_Code: "",
      State: "",
      Group_ID: "",
      Relationship: "",
      Card_Request: "",
    });
    setWltForm({
      doh: "",
      empSSN: "",
      empdob: "",
      firstName: "",
      lastName: "",
      middleI: "",
      gender: "",
      addressOne: "",
      addressTwo: "",
      city: "",
      phyState: "",
      zip: "",
      cardRequest: "",
      email: "",
      planSelect: "",
      dependent: "Employee",
      productFlages: [],
      depdob: "",
      depSSN: "",
      relationship: "C",
      changeSelect: "",
      changeEffDate: "",
    });
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Start loading animation
    setLoading(true);

    console.log("Form data:", form);

    const checkRequiredFields = () => {
      const requiredFields = {
        "Sex": "Gender",
        "Tier": "Tier",
        "EmpOrDep": "Employee or Dependent",
        "Reason": "Reason For Enrollment",
        "Division_ID": "Division",
        "Date_Enrolled": "Date Enrolled",
        "Birth_Date": "Date of Birth",
        "First_Name": "First Name",
        "Last_Name": "Last Name",
        "City": "City",
        "Zip_Code": "Zip Code",
        "State": "State",
      }
      if (form.EmpOrDep === "Employee") {
        requiredFields["SSN"] = "SSN"
      }
      if (form.EmpOrDep === "Dependent") {
        requiredFields["Relationship"] = "Relationship"
        requiredFields["EmpDOB"] = "Employee Date of Birth"
        requiredFields["EmpSSN"] = "Employee SSN"
      }
      const emptyFields = Object.keys(requiredFields).filter(
        (field) => !form[field] || (form[field] && form[field]?.toString().trim() === "")
      ).map((key) => requiredFields[key]);
      if (emptyFields.length > 0) {
        throw new Error(`Please fill all required fields. Missing fields: ${emptyFields.join(", ")}.`);
      }
    };

    try {
      // Check required fields before submitting
      checkRequiredFields();

      // Prepare data for upload
      const upload = form;
      console.log(upload);
      if (role === "broker" || role === "employer") {
        upload.Group_ID = selectedGroup;
      }
      if (upload.EmpOrDep === "Dependent") {
        fetch(`${host}/api/enrollments/vba/subscriber/addDependents`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${tk}`,
          },
          body: JSON.stringify({ member: upload }),
        })
          .then((response: Response) => {
            if (response.ok) {
              response.json().then((res) => {
                setAlertMessage(`Please allow 24 hours for this enrollment to be processed.`);
                setAlertOpen(true);

                // Reset the form to initial state
                showPlanSelection(false);
                showDivSelection(false);

                setForm({
                  Sex: "",
                  Tier: "EM",
                  Plan_ID: [],
                  EmpOrDep: "",
                  Reason: "",
                  EmpSSN: "",
                  EmpDOB: "",
                  Division_ID: "",
                  Date_Enrolled: "",
                  Birth_Date: "",
                  First_Name: "",
                  Last_Name: "",
                  SSN: "",
                  Address: "",
                  City: "",
                  Zip_Code: "",
                  State: "",
                  Group_ID: "",
                  Relationship: "",
                  Card_Request: "",
                });
              });
            } else {
              setAlertMessage(
                `An error occurred while trying to submit. Please verify all fields have been filled. Contact support if the issue persists.`
              );
              setAlertOpen(true);
            }
          })
          .catch((error: any) => {
            console.error("Error:", error);
            setAlertMessage(
              `An error occurred while trying to submit: ${error.message}. Please verify all fields have been filled. Contact support if the issue persists.`
            );
            setAlertOpen(true);
            throw error;
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        const subUpload = { ...upload, Relationship: "01" };
        fetch(`${host}/api/enrollments/vba/subscriber`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${tk}`,
          },
          body: JSON.stringify(subUpload),
        })
          .then((response: Response) => {
            if (response.ok) {
              response.json().then((res) => {
                setAlertMessage(`Please allow 24 hours for this enrollment to be processed.`);
                setAlertOpen(true);

                // Reset the form to initial state
                showPlanSelection(false);
                showDivSelection(false);

                setForm({
                  Sex: "",
                  Tier: "EM",
                  Plan_ID: [],
                  EmpOrDep: "",
                  Reason: "",
                  EmpSSN: "",
                  EmpDOB: "",
                  Division_ID: "",
                  Date_Enrolled: "",
                  Birth_Date: "",
                  First_Name: "",
                  Last_Name: "",
                  SSN: "",
                  Address: "",
                  City: "",
                  Zip_Code: "",
                  State: "",
                  Group_ID: "",
                  Relationship: "",
                  Card_Request: "",
                });
              });
            } else {
              setAlertMessage(
                `An error occurred while trying to submit. Please verify all fields have been filled. Contact support if the issue persists.`
              );
              setAlertOpen(true);
            }
          })
          .catch((error: any) => {
            console.error("Error:", error);
            setAlertMessage(
              `An error occurred while trying to submit: ${error.message}. Please verify all fields have been filled. Contact support if the issue persists.`
            );
            setAlertOpen(true);
            throw error;
          })
          .finally(() => {
            setLoading(false);
          });
      }
    } catch (error) {
      console.error("Error:", error);
      // alert(
      //   `An error occurred while processing the request: ${error.message} Please verify all fields have been filled. Contact support if the issue persists.`
      // );

      setAlertMessage(
        `An error occurred while processing the request: ${error.message} Please verify all fields have been filled. Contact support if the issue persists.`
      );
      setAlertOpen(true);
    } finally {
      // Stop loading animation
      setLoading(false);
    }
  };

  const handleCancel = () => {
    const isConfirmed = window.confirm("Are you sure, Record will not be saved if you cancel");
    if (isConfirmed) {
      // Redirect to another page or perform a cancel action
      showPlanSelection(false);
      showDivSelection(false);
      setWltShowFormDetails(false);
      setShowFormDetails(false);

      setForm({
        Sex: "",
        Tier: "EM",
        Plan_ID: [],
        Division_ID: "",
        Date_Enrolled: "",
        EmpOrDep: "",
        Reason: "",
        EmpSSN: "",
        EmpDOB: "",
        Birth_Date: "",
        First_Name: "",
        Last_Name: "",
        SSN: "",
        Address: "",
        City: "",
        Zip_Code: "",
        State: "",
        Group_ID: "",
        Relationship: "",
        Card_Request: "",
      });
      setWltForm({
        doh: "",
        empSSN: "",
        empdob: "",
        firstName: "",
        lastName: "",
        middleI: "",
        gender: "",
        addressOne: "",
        addressTwo: "",
        city: "",
        phyState: "",
        zip: "",
        cardRequest: "",
        email: "",
        planSelect: "",
        dependent: "Employee",
        productFlages: [],
        depdob: "",
        depSSN: "",
        relationship: "C",
        changeSelect: "",
        changeEffDate: "",
      });
      console.log("User cancelled the form.");
    }
  };

  if (loading) {
    return (
      <div className="loader-container">
        <CircularProgress />
      </div>
    );
  }
  return (
    <div className="form-container">
      <Button className="navigate-button" variant="contained" onClick={goToEmployeeMaintenance}>
        Employee Maintenance
      </Button>
      <CustomAlert message={alertMessage} isOpen={isAlertOpen} onClose={closeAlert} />
      <Paper
        style={{
          width: "60%", // This will make the form take up 80% of the parent container's width.
          margin: "10px auto",
          padding: "20px",
          backgroundColor: "#f5f5f5",
        }}
      >
        <Typography variant="h4" style={{ textAlign: "center", marginBottom: "30px", color: "black" }}>
          Employee Enrollment Details
        </Typography>

        <form
          onSubmit={showFormDetails ? handleSubmit : wltShowFormDetails ? handleWLTSubmit : () => {}}
          style={{ backgroundColor: "#ededed", padding: "20px" }}
        >
          {role === "admin" || role === "internal_ugp" ? (
            <>
              <Tooltip title="Enter the unique group identifier">
                <TextField
                  fullWidth
                  margin="normal"
                  type="text"
                  name="Group_ID"
                  onChange={handleChange2}
                  value={form.Group_ID}
                  label="Group Number"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <InfoIcon style={{ color: "rgba(0, 0, 0, 0.54)" }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Tooltip>
              {/* Add your button here */}
              {
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    handleButtonClick();
                  }}
                >
                  Update Selections with Group ID
                </Button>
              }
            </>
          ) : (
            <>
              <Typography>Selected Group: {selectedGroup}</Typography>
            </>
          )}

          {showFormDetails && (
            <>
              {!planSelection ? (
                <Tooltip title="Enter the unique plan identifier">
                  <TextField
                    fullWidth
                    margin="normal"
                    type="text"
                    name="Plan_ID"
                    onChange={handleChange2}
                    value={form.Plan_ID}
                    label="Plan ID"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <InfoIcon style={{ color: "rgba(0, 0, 0, 0.54)" }} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Tooltip>
              ) : null}
              {planSelection ? (
                <Box display="flex" alignItems="center">
                  <Tooltip title="More information about Plan Selection">
                    <Box display="flex" alignItems="center" style={{ width: "100%" }}>
                      <FormControl fullWidth margin="normal" style={{ flex: 1 }}>
                        <InputLabel>Plan Selection</InputLabel>
                        <Select
                          multiple
                          name="Plan_ID"
                          value={form.Plan_ID}
                          onChange={handleChange5}
                          label="Plan Selection"
                          renderValue={(selected) =>
                            selected
                              .map((planId) => {
                                const selectedPlan = planOptions.find((group) => group.Plan_ID === planId);
                                return selectedPlan ? `${selectedPlan.Description} (${selectedPlan.Plan_ID})` : planId;
                              })
                              .join(", ")
                          }
                        >
                          {planOptions.map((group) => (
                            <MenuItem key={group.Plan_ID} value={group.Plan_ID}>
                              {group.Description}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <InfoIcon style={{ marginLeft: 10, color: "rgba(0, 0, 0, 0.54)" }} />
                    </Box>
                  </Tooltip>
                </Box>
              ) : null}

              {!divSelection ? (
                <Tooltip title="Enter the division ID or location name">
                  <TextField
                    fullWidth
                    margin="normal"
                    type="text"
                    name="Division_ID"
                    onChange={handleChange2}
                    value={form.Division_ID}
                    label="Division ID/Location Name"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <InfoIcon style={{ color: "rgba(0, 0, 0, 0.54)" }} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Tooltip>
              ) : null}

              {divSelection ? (
                <Box display="flex" alignItems="center">
                  <Tooltip title="More information about Division ID/Location Name">
                    <Box display="flex" alignItems="center" style={{ width: "100%" }}>
                      <FormControl fullWidth margin="normal" style={{ flex: 1 }}>
                        <InputLabel>Division ID/Location Name</InputLabel>
                        <Select name="Division_ID" onChange={handleChange} label="Division ID/Location Name">
                          {divisionOptions.map((division, index) => (
                            <MenuItem key={index} value={division.Division_ID}>
                              {division.Name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <InfoIcon style={{ marginLeft: 10, color: "rgba(0, 0, 0, 0.54)" }} />
                    </Box>
                  </Tooltip>
                </Box>
              ) : null}

              {/* <InputLabel>Employee or Dependent:</InputLabel>
              <Select
                name="EmpOrDep"
                onChange={handleChange}
                label="Employee or Dependent"
                value={form.EmpOrDep as any}
              >
                <MenuItem key="employee" value="Employee">
                  Employee
                </MenuItem>
                <MenuItem key="dependent" value="Dependent">
                  Dependent
                </MenuItem>
              </Select> */}

              <Box display="flex" alignItems="center">
                <Tooltip title="Are you enrolling an Employee or Dependent">
                  <Box display="flex" alignItems="center" style={{ width: "100%" }}>
                    <FormControl fullWidth margin="normal" style={{ flex: 1 }}>
                      <InputLabel>Employee or Dependent:</InputLabel>
                      <Select
                        name="EmpOrDep"
                        onChange={handleChange}
                        label="Employee or Dependent"
                        value={form.EmpOrDep as any}
                      >
                        <MenuItem key="employee" value="Employee">
                          Employee
                        </MenuItem>
                        <MenuItem key="dependent" value="Dependent">
                          Dependent
                        </MenuItem>
                      </Select>
                    </FormControl>
                    <InfoIcon style={{ marginLeft: 10, color: "rgba(0, 0, 0, 0.54)" }} />
                  </Box>
                </Tooltip>
              </Box>

              {/* <InputLabel>Reason For Enrollment</InputLabel>

              <Select name="Reason" onChange={handleChange} label="Employee or Dependent" value={form.Reason as any}>
                <MenuItem key="none" value="None">
                  None
                </MenuItem>
                <MenuItem key="openEnrollment" value="OpenEnrollment">
                  Open Enrollment
                </MenuItem>
                <MenuItem key="marriage" value="Marriage">
                  Marriage
                </MenuItem>
                <MenuItem key="lossOfCoverage" value="LossOfCoverage">
                  Loss Of Coverage
                </MenuItem>
              </Select> */}

              <Box display="flex" alignItems="center">
                <Box display="flex" alignItems="center" style={{ width: "100%" }}>
                  <FormControl fullWidth margin="normal" style={{ flex: 1 }}>
                    <InputLabel>Reason For Enrollment:</InputLabel>
                    <Select
                      name="Reason"
                      onChange={handleChange}
                      label="Reason For Enrollment"
                      value={form.Reason as any}
                    >
                      <MenuItem key="newHire" value="NewHire">
                        New Hire
                      </MenuItem>
                      <MenuItem key="openEnrollment" value="OpenEnrollment">
                        Open Enrollment
                      </MenuItem>
                      <MenuItem key="marriage" value="Marriage">
                        Marriage
                      </MenuItem>
                      <MenuItem key="lossOfCoverage" value="LossOfCoverage">
                        Loss Of Coverage
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Box>

              <FormControl fullWidth margin="normal">
                <InputLabel>Sex</InputLabel>
                <Select
                  name="Sex"
                  onChange={handleChange}
                  label="Sex"
                  value={form.Sex as unknown as any}
                  defaultValue={"" as any}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="male">Male</MenuItem>
                  <MenuItem value="female">Female</MenuItem>
                </Select>
              </FormControl>

              {form.EmpOrDep === "Dependent" && (
                <>
                  {/* <InputLabel>Emp SSN:</InputLabel> */}
                  <TextField
                    fullWidth
                    margin="normal"
                    type="text"
                    name="EmpSSN"
                    onChange={handleNumericInput}
                    value={form.EmpSSN}
                    label="Emp SSN"
                  />

                  <Typography variant="subtitle1">Emp Date of Birth</Typography>
                  <TextField
                    fullWidth
                    margin="normal"
                    type="date"
                    name="EmpDOB"
                    onChange={handleChange2}
                    value={form.EmpDOB as string}
                  />
                  <FormControl fullWidth margin="normal">
                    <InputLabel>Relationship</InputLabel>
                    <Select
                      name="Relationship"
                      onChange={handleChange}
                      value={form.Relationship as any}
                      defaultValue={"" as any}
                      label="Relationship"
                    >
                      <MenuItem value="19">Child</MenuItem>
                      <MenuItem value="02">Spouse</MenuItem>
                      <MenuItem value="28">Other</MenuItem>
                      <MenuItem value="28">Domestic Partner</MenuItem>
                    </Select>
                  </FormControl>
                </>
              )}

              <FormControl>
                <Typography variant="subtitle1">Effective Date of Benefits</Typography>
                <Tooltip title="">
                  <TextField
                    fullWidth
                    margin="normal"
                    type="date"
                    name="Date_Enrolled"
                    onChange={handleChange2}
                    value={form.Date_Enrolled}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <InfoIcon style={{ color: "rgba(0, 0, 0, 0.54)" }} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Tooltip>
              </FormControl>

              <Typography variant="subtitle1">Date of Birth</Typography>
              <TextField
                fullWidth
                margin="normal"
                type="date"
                name="Birth_Date"
                onChange={handleChange2}
                value={form.Birth_Date}
              />

              <TextField
                fullWidth
                margin="normal"
                type="text"
                name="First_Name"
                onChange={handleChange2}
                label="First Name"
                value={form.First_Name}
              />

              <TextField
                type="text"
                name="Last_Name"
                onChange={handleChange2}
                label="Last Name"
                value={form.Last_Name}
              />

              <TextField
                fullWidth
                margin="normal"
                type="text"
                name="SSN"
                onChange={handleNumericInput}
                value={form.SSN}
                label="SSN"
              />

              <TextField
                fullWidth
                margin="normal"
                type="text"
                name="Address"
                onChange={handleChange2}
                value={form.Address}
                label="Address"
              />

              <TextField
                fullWidth
                margin="normal"
                type="text"
                name="City"
                onChange={handleChange2}
                value={form.City}
                label="City"
              />

              <TextField
                fullWidth
                margin="normal"
                type="text"
                name="Zip_Code"
                onChange={handleChange2}
                value={form.Zip_Code}
                label="Zipcode"
              />

              <FormControl fullWidth margin="normal">
                <InputLabel>State</InputLabel>
                <Select
                  name="State"
                  onChange={handleChange}
                  value={form.State as any}
                  defaultValue={"" as any}
                  label="State"
                >
                  <MenuItem value="">...Select</MenuItem>
                  <MenuItem value="AK">Alaska</MenuItem>
                  <MenuItem value="AL">Alabama</MenuItem>
                  <MenuItem value="AR">Arkansas</MenuItem>
                  <MenuItem value="AZ">Arizona</MenuItem>
                  <MenuItem value="CA">California</MenuItem>
                  <MenuItem value="CO">Colorado</MenuItem>
                  <MenuItem value="CT">Connecticut</MenuItem>
                  <MenuItem value="DE">Delaware</MenuItem>
                  <MenuItem value="FL">Florida</MenuItem>
                  <MenuItem value="GA">Georgia</MenuItem>
                  <MenuItem value="HI">Hawaii</MenuItem>
                  <MenuItem value="ID">Idaho</MenuItem>
                  <MenuItem value="IL">Illinois</MenuItem>
                  <MenuItem value="IN">Indiana</MenuItem>
                  <MenuItem value="IA">Iowa</MenuItem>
                  <MenuItem value="KS">Kansas</MenuItem>
                  <MenuItem value="KY">Kentucky</MenuItem>
                  <MenuItem value="LA">Louisiana</MenuItem>
                  <MenuItem value="ME">Maine</MenuItem>
                  <MenuItem value="MD">Maryland</MenuItem>
                  <MenuItem value="MA">Massachusetts</MenuItem>
                  <MenuItem value="MI">Michigan</MenuItem>
                  <MenuItem value="MN">Minnesota</MenuItem>
                  <MenuItem value="MS">Mississippi</MenuItem>
                  <MenuItem value="MO">Missouri</MenuItem>
                  <MenuItem value="MT">Montana</MenuItem>
                  <MenuItem value="NE">Nebraska</MenuItem>
                  <MenuItem value="NV">Nevada</MenuItem>
                  <MenuItem value="NH">New Hampshire</MenuItem>
                  <MenuItem value="NJ">New Jersey</MenuItem>
                  <MenuItem value="NM">New Mexico</MenuItem>
                  <MenuItem value="NY">New York</MenuItem>
                  <MenuItem value="NC">North Carolina</MenuItem>
                  <MenuItem value="ND">North Dakota</MenuItem>
                  <MenuItem value="OH">Ohio</MenuItem>
                  <MenuItem value="OK">Oklahoma</MenuItem>
                  <MenuItem value="OR">Oregon</MenuItem>
                  <MenuItem value="PA">Pennsylvania</MenuItem>
                  <MenuItem value="RI">Rhode Island</MenuItem>
                  <MenuItem value="SC">South Carolina</MenuItem>
                  <MenuItem value="SD">South Dakota</MenuItem>
                  <MenuItem value="TN">Tennessee</MenuItem>
                  <MenuItem value="TX">Texas</MenuItem>
                  <MenuItem value="UT">Utah</MenuItem>
                  <MenuItem value="VT">Vermont</MenuItem>
                  <MenuItem value="VA">Virginia</MenuItem>
                  <MenuItem value="WA">Washington</MenuItem>
                  <MenuItem value="WV">West Virginia</MenuItem>
                  <MenuItem value="WI">Wisconsin</MenuItem>
                  <MenuItem value="WY">Wyoming</MenuItem>
                </Select>
              </FormControl>
              <Grid container direction="column" alignItems={"center"} alignContent={"center"}>
                <Typography>Order Cards?</Typography>
                <RadioGroup
                  aria-label="position"
                  name="Card_Request"
                  value={form.Card_Request}
                  onChange={handleChange}
                  row
                >
                  <FormControlLabel value="true" control={<Radio color="primary" />} label="Yes" labelPlacement="end" />
                  <FormControlLabel value="false" control={<Radio color="primary" />} label="No" labelPlacement="end" />
                </RadioGroup>
              </Grid>

              <Button type="submit" variant="contained" className="blue-button" disabled={loading}>
                Submit
              </Button>
              <Button type="button" variant="contained" className="cancel-button" onClick={handleCancel}>
                Cancel
              </Button>
              {loading && <CircularProgress />}
            </>
          )}
          {wltShowFormDetails && (
            <>
              <Box display="flex" alignItems="center">
                <Box display="flex" alignItems="center" style={{ width: "100%" }}>
                  <FormControl fullWidth margin="normal" style={{ flex: 1 }}>
                    <InputLabel>Select a Plan (required)</InputLabel>
                    <Select
                      name="planSelect"
                      onChange={(e) => {
                        setWltForm({ ...wltForm, planSelect: e.target.value.toString() });
                      }}
                      label="planSelect"
                      defaultValue={""}
                    >
                      {wltPlanOptions.map((option) => {
                        return (
                          <MenuItem key={option.planNo} value={option.planNo}>
                            {option.planName}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <InfoIcon style={{ marginLeft: 10, color: "rgba(0, 0, 0, 0.54)" }} />
                </Box>
              </Box>

              <FormGroup row style={{ alignItems: "center", justifyContent: "center" }}>
                {Object.keys(wltFlags).map((product) => {
                  return (
                    <FormControlLabel
                      key={product}
                      control={
                        <Checkbox
                          checked={wltFlags[product]}
                          name={product}
                          value={wltFlags[product]}
                          onChange={(e) => {
                            setWltFlags({ ...wltFlags, [product]: e.target.checked });
                          }}
                        />
                      }
                      label={wltFlagOptions.find((option) => option.id === product)?.name}
                    />
                  );
                })}
              </FormGroup>

              <Box display="flex" alignItems="center" style={{ width: "100%" }}>
                <FormControl fullWidth margin="normal" style={{ flex: 1 }}>
                  <InputLabel>Employee or Dependent:</InputLabel>
                  <Select
                    name="dependent"
                    onChange={(event: SelectChangeEvent) => {
                      setWltForm({ ...wltForm, dependent: event.target.value });
                    }}
                    label="Employee or Dependent"
                    value={wltForm.dependent as string}
                    displayEmpty
                  >
                    <MenuItem key="employee" value="Employee">
                      Employee
                    </MenuItem>
                    <MenuItem key="dependent" value="Dependent">
                      Dependent
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>

              <Box display="flex" alignItems="center" style={{ width: "100%" }}>
                <FormControl fullWidth margin="normal" style={{ flex: 1 }}>
                  <InputLabel>Reason for Enrollment:</InputLabel>
                  <Select
                    name="changeSelect"
                    onChange={(event: SelectChangeEvent) => {
                      setWltForm({ ...wltForm, changeSelect: event.target.value });
                    }}
                    label="Reason for Enrollment:"
                    value={wltForm.changeSelect as string}
                    defaultValue={""}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value="1">New Hire</MenuItem>
                    <MenuItem value="2">Open Enrollment</MenuItem>
                    {wltForm.dependent === "Dependent" && <MenuItem value="3">Birth</MenuItem>}
                    <MenuItem value="4">Marriage</MenuItem>
                    <MenuItem value="5">Loss of Coverage</MenuItem>
                  </Select>
                </FormControl>
              </Box>

              {wltForm.dependent === "Dependent" && (
                <>
                  {(wltForm.changeSelect === "0" ||
                    wltForm.changeSelect === "1" ||
                    wltForm.changeSelect === "2" ||
                    wltForm.changeSelect === "5") && (
                    <>
                      <Box display="flex" alignItems="center" style={{ width: "100%" }}>
                        <FormControl fullWidth margin="normal" style={{ flex: 1 }}>
                          <InputLabel>Relationship:</InputLabel>
                          <Select
                            name="relationship"
                            onChange={(event: SelectChangeEvent) => {
                              setWltForm({ ...wltForm, relationship: event.target.value });
                            }}
                            label="Relationship"
                            value={wltForm.relationship as string}
                          >
                            <MenuItem key="spouse" value={"P"}>
                              Spouse
                            </MenuItem>
                            <MenuItem key="child" value={"C"}>
                              Child
                            </MenuItem>
                            <MenuItem key="other" value={"O"}>
                              Other
                            </MenuItem>
                            <MenuItem key="domesticPartner" value={"D"}>
                              Domestic Partner
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Box>
                    </>
                  )}
                  <InputLabel>Dependent Date of Birth:</InputLabel>
                  <TextField
                    fullWidth
                    margin="normal"
                    type="date"
                    name="depdob"
                    onChange={(e) => {
                      setWltForm({ ...wltForm, depdob: e.target.value });
                    }}
                    value={wltForm.depdob}
                  />
                  {/* <InputLabel>Dependent SSN:</InputLabel>
                  <TextField
                    fullWidth
                    margin="normal"
                    type="text"
                    name="depssn"
                    onChange={(e) => {
                      const cleanedValue = e.target.value.replace(/[^0-9]/g, '');
                      setWltForm({ ...wltForm, depSSN: cleanedValue });
                    }}
                    value={wltForm.depSSN}
                  /> */}
                  <Box display="flex" alignItems="center" style={{ width: "100%" }}>
                    <FormControl fullWidth margin="normal" style={{ flex: 1 }}>
                      {/* <InputLabel>Dependent SSN:</InputLabel> */}
                      <TextField
                        fullWidth
                        margin="normal"
                        label="Dependent SSN"
                        type="text"
                        name="depssn"
                        onChange={(e) => {
                          setWltForm({ ...wltForm, depSSN: e.target.value });
                        }}
                        value={wltForm.depSSN}
                      />
                    </FormControl>
                  </Box>
                </>
              )}

              {["1"].includes(wltForm.changeSelect) ? (
                <>
                  <InputLabel>Date Of Hire:</InputLabel>
                  <FormControl fullWidth margin="normal">
                    <TextField
                      type="date"
                      name="doh"
                      onChange={(e) => {
                        setWltForm({ ...wltForm, doh: e.target.value });
                      }}
                      value={wltForm.doh}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <InfoIcon style={{ color: "rgba(0, 0, 0, 0.54)" }} />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                </>
              ) : null}

              {["2", "4", "5"].includes(wltForm.changeSelect) ? (
                <>
                  <InputLabel>Effective Date:</InputLabel>
                  <FormControl>
                    <TextField
                      fullWidth
                      margin="normal"
                      type="date"
                      name="Date_Enrolled"
                      onChange={(e) => {
                        setWltForm({ ...wltForm, changeEffDate: e.target.value });
                      }}
                      value={wltForm.changeEffDate}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <InfoIcon style={{ color: "rgba(0, 0, 0, 0.54)" }} />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                </>
              ) : null}

              <Box display="flex" alignItems="center" style={{ width: "100%" }}>
                <FormControl fullWidth margin="normal" style={{ flex: 1 }}>
                  <InputLabel>Gender</InputLabel>
                  <Select
                    name="gender"
                    onChange={(e) => {
                      setWltForm({ ...wltForm, gender: e.target.value });
                    }}
                    label="gender"
                    value={wltForm.gender}
                    defaultValue={""}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value="Male">Male</MenuItem>
                    <MenuItem value="Female">Female</MenuItem>
                  </Select>
                </FormControl>
              </Box>

              <Box display="flex" alignItems="center" style={{ width: "100%" }}>
                <FormControl fullWidth margin="normal" style={{ flex: 1 }}>
                  <TextField
                    fullWidth
                    margin="normal"
                    type="text"
                    name="empSSN"
                    onChange={(e) => {
                      setWltForm({ ...wltForm, empSSN: e.target.value });
                    }}
                    value={wltForm.empSSN}
                    label="SSN"
                  />
                </FormControl>
              </Box>

              <Typography variant="subtitle1">Date of Birth</Typography>
              <TextField
                fullWidth
                margin="normal"
                type="date"
                name="empDOB"
                onChange={(e) => {
                  setWltForm({ ...wltForm, empdob: e.target.value });
                }}
                value={wltForm.empdob}
              />

              <TextField
                fullWidth
                margin="normal"
                type="text"
                name="firstName"
                onChange={(e) => {
                  setWltForm({ ...wltForm, firstName: e.target.value });
                }}
                label="First Name"
                value={wltForm.firstName}
              />

              <TextField
                type="text"
                name="lastName"
                onChange={(e) => {
                  setWltForm({ ...wltForm, lastName: e.target.value });
                }}
                label="Last Name"
                value={wltForm.lastName}
              />
              <TextField
                fullWidth
                margin="normal"
                type="text"
                name="AddressOne"
                onChange={(e) => {
                  setWltForm({ ...wltForm, addressOne: e.target.value });
                }}
                value={wltForm.addressOne}
                label="Address One"
              />
              <TextField
                fullWidth
                margin="normal"
                type="text"
                name="AddressTwo"
                onChange={(e) => {
                  setWltForm({ ...wltForm, addressTwo: e.target.value });
                }}
                value={wltForm.addressTwo}
                label="Address Two"
              />

              <TextField
                fullWidth
                margin="normal"
                type="text"
                name="city"
                onChange={(e) => {
                  setWltForm({ ...wltForm, city: e.target.value });
                }}
                value={wltForm.city}
                label="City"
              />

              <TextField
                fullWidth
                margin="normal"
                type="text"
                name="zip"
                onChange={(e) => {
                  setWltForm({ ...wltForm, zip: e.target.value });
                }}
                value={wltForm.zip}
                label="Zip Code"
              />
              <FormControl fullWidth margin="normal">
                <InputLabel>State</InputLabel>
                <Select
                  name="State"
                  onChange={(e) => {
                    setWltForm({ ...wltForm, phyState: e.target.value });
                  }}
                  value={wltForm.phyState}
                  label="State"
                >
                  <MenuItem value="">...Select</MenuItem>
                  <MenuItem value="AK">Alaska</MenuItem>
                  <MenuItem value="AL">Alabama</MenuItem>
                  <MenuItem value="AR">Arkansas</MenuItem>
                  <MenuItem value="AZ">Arizona</MenuItem>
                  <MenuItem value="CA">California</MenuItem>
                  <MenuItem value="CO">Colorado</MenuItem>
                  <MenuItem value="CT">Connecticut</MenuItem>
                  <MenuItem value="DE">Delaware</MenuItem>
                  <MenuItem value="FL">Florida</MenuItem>
                  <MenuItem value="GA">Georgia</MenuItem>
                  <MenuItem value="HI">Hawaii</MenuItem>
                  <MenuItem value="ID">Idaho</MenuItem>
                  <MenuItem value="IL">Illinois</MenuItem>
                  <MenuItem value="IN">Indiana</MenuItem>
                  <MenuItem value="IA">Iowa</MenuItem>
                  <MenuItem value="KS">Kansas</MenuItem>
                  <MenuItem value="KY">Kentucky</MenuItem>
                  <MenuItem value="LA">Louisiana</MenuItem>
                  <MenuItem value="ME">Maine</MenuItem>
                  <MenuItem value="MD">Maryland</MenuItem>
                  <MenuItem value="MA">Massachusetts</MenuItem>
                  <MenuItem value="MI">Michigan</MenuItem>
                  <MenuItem value="MN">Minnesota</MenuItem>
                  <MenuItem value="MS">Mississippi</MenuItem>
                  <MenuItem value="MO">Missouri</MenuItem>
                  <MenuItem value="MT">Montana</MenuItem>
                  <MenuItem value="NE">Nebraska</MenuItem>
                  <MenuItem value="NV">Nevada</MenuItem>
                  <MenuItem value="NH">New Hampshire</MenuItem>
                  <MenuItem value="NJ">New Jersey</MenuItem>
                  <MenuItem value="NM">New Mexico</MenuItem>
                  <MenuItem value="NY">New York</MenuItem>
                  <MenuItem value="NC">North Carolina</MenuItem>
                  <MenuItem value="ND">North Dakota</MenuItem>
                  <MenuItem value="OH">Ohio</MenuItem>
                  <MenuItem value="OK">Oklahoma</MenuItem>
                  <MenuItem value="OR">Oregon</MenuItem>
                  <MenuItem value="PA">Pennsylvania</MenuItem>
                  <MenuItem value="RI">Rhode Island</MenuItem>
                  <MenuItem value="SC">South Carolina</MenuItem>
                  <MenuItem value="SD">South Dakota</MenuItem>
                  <MenuItem value="TN">Tennessee</MenuItem>
                  <MenuItem value="TX">Texas</MenuItem>
                  <MenuItem value="UT">Utah</MenuItem>
                  <MenuItem value="VT">Vermont</MenuItem>
                  <MenuItem value="VA">Virginia</MenuItem>
                  <MenuItem value="WA">Washington</MenuItem>
                  <MenuItem value="WV">West Virginia</MenuItem>
                  <MenuItem value="WI">Wisconsin</MenuItem>
                  <MenuItem value="WY">Wyoming</MenuItem>
                </Select>
              </FormControl>
              <Grid container direction="column" alignItems={"center"} alignContent={"center"}>
                <Typography>Order Cards?</Typography>
                <RadioGroup
                  aria-label="position"
                  name="position"
                  value={wltForm.cardRequest}
                  onChange={(e) => setWltForm({ ...wltForm, cardRequest: e.target.value })}
                  row
                >
                  <FormControlLabel value="true" control={<Radio color="primary" />} label="Yes" labelPlacement="end" />
                  <FormControlLabel value="false" control={<Radio color="primary" />} label="No" labelPlacement="end" />
                </RadioGroup>
              </Grid>

              <Button type="submit" variant="contained" className="blue-button" disabled={loading}>
                Submit
              </Button>
              <Button type="button" variant="contained" className="cancel-button" onClick={handleCancel}>
                Cancel
              </Button>
              {loading && <CircularProgress />}
            </>
          )}
        </form>
      </Paper>
    </div>
  );
};

export default EmployeeEnrollmentForm;
