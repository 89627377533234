import React, { useState, useEffect, ChangeEvent, useContext } from "react";
import { getFirestore, doc, setDoc, onSnapshot, collection, DocumentData } from "firebase/firestore";
import { AuthContext } from "../authProvider/authProvider";
import { app, host } from "../../config";
import "./RoleClassifier.css";
import CircularProgress from "@mui/material/CircularProgress";

interface User {
  id: string;
  data: DocumentData;
}

const RoleClassifier: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const db = getFirestore(app);

  const authContext = useContext(AuthContext); // Get Auth context
  if (!authContext) {
    throw new Error("AuthContext is undefined");
  }
  const { currentUser } = authContext;

  var tk: string;
  currentUser
    ?.getIdToken(false)
    .then((idToken: string) => {
      tk = idToken;
    })
    .catch((error: any) => {
      console.log(error);
    });

  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, "user-info"), (snapshot) => {
      const fetchedUsers = snapshot.docs.map((doc) => ({
        id: doc.id,
        data: doc.data(),
      }));
      setUsers(fetchedUsers);
    });

    return () => unsubscribe();
  }, [db]);

  const handleRoleChange = async (userId: string, newRole: string) => {
    console.log(newRole);

    let data = {
      userId: userId,
      role: newRole,
    };
    setIsLoading(true);
    fetch(`${host}/api/firebase/api/CData3`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${tk}`,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then(async (data: any) => {
        // console.log(data)
        //console.log("trigger")
        setIsLoading(false);
        try {
          const unsubscribe = onSnapshot(collection(db, "user-info"), (snapshot) => {
            const fetchedUsers = snapshot.docs.map((doc) => ({
              id: doc.id,
              data: doc.data(),
            }));
            setUsers(fetchedUsers);
          });

          return () => unsubscribe();
        } catch (error) {
          console.error("Error:", error);
          setIsLoading(false);
        }
      })
      .catch((error: any) => console.error("Error:", error))
      .finally(() => {
        setIsLoading(false);
        console.log("Processing finished");
      });
  };

  if (isLoading) {
    return (
      <div className="loader-container">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="role-classifier-container">
      <ul className="role-classifier-list">
        {users.map(({ id, data: { Role, Email } }) => (
          <li key={id} className="role-classifier-list-item">
            <span>
              {Email} - current role: {Role}
            </span>
            <select value={Role} onChange={(e: ChangeEvent<HTMLSelectElement>) => handleRoleChange(id, e.target.value)}>
              <option value="employee">Employee</option>
              <option value="admin">Admin</option>
              <option value="broker">Broker</option>
              <option value="employer">Employer</option>
              <option value="internal_ugp">Internal UGP</option>
            </select>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default RoleClassifier;
