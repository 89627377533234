import React, { ChangeEvent, FC, useState, useRef, useContext, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { app, host } from "../../config";
import { AuthContext } from "../authProvider/authProvider";
import { getAuth, signOut } from "firebase/auth";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Box,
  TextField,
  Typography,
} from "@mui/material";
import "./claims.css";

// Initialize Firebase Auth and Firestore
const auth = getAuth();

const FileUpload: FC = () => {
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const auth2 = getAuth();

  const navigate = useNavigate(); // Hook to navigate between routes

  const authContext = useContext(AuthContext); // Get Auth context
  if (!authContext) {
    throw new Error("AuthContext is undefined");
  }
  const { currentUser, suspended, email } = authContext; // Get current user from Auth context

  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  var tk: string;
  currentUser
    ?.getIdToken(false)
    .then((idToken: string) => {
      tk = idToken;
    })
    .catch((error: any) => {
      console.log(error);
    });

  // If the user is suspended, sign them out and navigate to the login page
  if (suspended == true) {
    signOut(auth)
      .then(() => {
        console.log("Signed out");
        return <Navigate to="/login" />;
      })
      .catch((error) => {
        console.error("Error signing out", error);
      });
  }

  // console.log(docData?.Email);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newFiles = event.target.files ? Array.from(event.target.files) : [];
    setSelectedFiles((prev) => [...prev, ...newFiles]);
  };

  const handleFileSubmit = () => {
    // handle uploaded files here, e.g. send them to a server
    //email set
    const formData = new FormData();
    formData.append("email", email as string);
    Object.entries(selectedFiles).forEach(([name, file]) => {
      formData.append("files", file);
    });
    // console.log(formData.keys);

    if (!formData.get("files")) {
      alert("Please upload at least one file");
    } else {
      fetch(`${host}/api/claims/upload`, {
        method: "POST",
        headers: {
          authorization: `Bearer ${tk}`,
        },
        body: formData,
      })
        .then((response) => {
          // Handle server response here
          setSelectedFiles([]);
        })
        .catch((error) => {
          // Handle error here
          console.log(error);
        })
        .finally(() => {
          // This will be executed after the fetch regardless of its outcome
          // Reset state variables to clear the form
        });
    }

    // for (let file of selectedFiles) {
    //   console.log(file.name);
    // }
  };

  const handleClearFiles = () => {
    setSelectedFiles([]);
  };

  const handleAddFilesClick = () => {
    fileInputRef.current?.click();
  };

  const fileInputLabel = () => {
    if (selectedFiles.length > 1) {
      return `${selectedFiles.length} files selected`;
    } else if (selectedFiles.length === 1) {
      return `${selectedFiles[0].name}`;
    }
    return "No files selected";
  };

  const goToClaimsLookup = () => {
    navigate("/claimsLookup");
  };

  return (
    <div className="container">
      <h1>Claim Submission</h1>
      <Typography variant="body2" color="textSecondary">
        Please select the appropriate claim type to submit. Follow the instructions and requirements for submitting your
        claim(s) online.
      </Typography>
      <input
        type="file"
        id="fileUpload"
        multiple
        onChange={handleFileChange}
        accept=".csv"
        style={{ display: "none" }}
        ref={fileInputRef}
      />
      <div>
        <button onClick={handleAddFilesClick} className="Mainbutton">
          Add Files
        </button>
        <button onClick={handleFileSubmit} className="Mainbutton">
          Submit
        </button>
        <button onClick={handleClearFiles} className="Mainbutton">
          Clear Files
        </button>
        <button onClick={goToClaimsLookup} className="Mainbutton">
          Claims Lookup
        </button>
      </div>
      {/* New button */}
      <p>{fileInputLabel()}</p>
      <div>
        {selectedFiles.length > 0 && (
          <ul className="fileList">
            {selectedFiles.map((file, index) => (
              <li key={index} className="fileItem">
                {file.name}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default FileUpload;
