import React, { useState, ChangeEvent, MouseEvent, useContext, useEffect } from "react";

import { Navigate, useNavigate } from "react-router-dom";
import { app } from "../../config";
import { AuthContext } from "../authProvider/authProvider";
import { getAuth, signOut } from "firebase/auth";
import { doc, getDoc, getFirestore, DocumentData } from "firebase/firestore";
import "components/upload/upload.css";
import { host } from "../../config";
import CustomAlert from "components/alert/CustomAlert";
import CircularProgress from "@mui/material/CircularProgress";

interface Files {
  [key: string]: File;
}

const auth = getAuth();

const FileUploadComponent: React.FC = () => {
  const [groupNumber, setGroupNumber] = useState<string>("");
  const [files, setFiles] = useState<Files>({});
  const [resetKey, setResetKey] = useState<number>(0);
  const [selectedSections, setSelectedSections] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const authContext = useContext(AuthContext); // Get Auth context
  if (!authContext) {
    throw new Error("AuthContext is undefined");
  }
  const { currentUser, suspended } = authContext; // Get current user from Auth context

  const [isAlertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState<string>("");

  const openAlert = () => {
    setAlertOpen(true);
  };

  const closeAlert = () => {
    setAlertOpen(false);
  };

  const navigate = useNavigate(); // Hook to navigate between routes

  // If there's no current user, navigate to the login page
  // if (!currentUser) {
  //   return <Navigate to="/login" />;
  // }

  // var tk: string;
  // currentUser
  //   ?.getIdToken(false)
  //   .then((idToken: string) => {
  //     tk = idToken;
  //   })
  //   .catch((error: any) => {
  //     console.log(error);
  //   });

  // if (suspended == true) {
  //   signOut(auth)
  //     .then(() => {
  //       console.log("Signed out");
  //       return <Navigate to="/login" />;
  //     })
  //     .catch((error) => {
  //       console.error("Error signing out", error);
  //     });
  // }

  const handleGroupNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
    setGroupNumber(event.target.value);
  };

  const handleFileChange = (name: string) => (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : new File([], "");
    setFiles((prevFiles) => ({
      ...prevFiles,
      [name]: file,
    }));
  };

  const handleSubmit = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    console.log(files);

    const formData = new FormData();
    Object.entries(files).forEach(([name, file]) => {
      formData.append("files", file, `${file.name.split(".").slice(0, -1)}_${name}.${file.name.split(".").slice(-1)}`);
    });

    var counter = 0;
    for (let file of formData.getAll("files")) {
      counter++;
    }

    if (groupNumber.length < 1) {
      //alert("Please enter a group number");
      setAlertMessage("Please enter a group number");
      setAlertOpen(true);
    } else if (counter == 0) {
      //alert("Please upload at least one file");
      setAlertMessage("Please upload at least one file");
      setAlertOpen(true);
    } else {
      setIsLoading(true);
      fetch(`${host}/api/upload/uploadOpportunityDocument?groupNum=${groupNumber}`, {
        method: "POST",
        // headers: {
        //   authorization: `Bearer ${tk}`,
        // },
        body: formData,
      })
        .then((response) => {
          setIsLoading(false);

          if (!response.ok) {
            setIsLoading(false);
            throw new Error(`HTTP error! status: ${response.status}`);
          } else {
            setIsLoading(false);
            return response.json();
          }
        })
        .then((data: any) => {
          setIsLoading(false);
          if (data.status === 200) {
            // alert(
            //   "Documents have been successfully uploaded. If you are completing a group implementation form, please return to the form to finish your group submission."
            // );
            setAlertMessage(
              "Documents have been successfully uploaded. If you are completing a group implementation form, please return to the form to finish your group submission."
            );
            setAlertOpen(true);
          } else {
            // alert(
            //   `An error occurred while uploading the documents. Status: ${data.status}`
            // );
            setIsLoading(false);

            setAlertMessage(`An error occurred while uploading the documents. Status: ${data.status}`);
            setAlertOpen(true);
          }
          setGroupNumber("");
          setFiles({});
          setResetKey(resetKey + 1);
          setSelectedSections([]);
        })
        .catch((error) => {
          setIsLoading(false);
          // alert("An error occurred: " + error.message);
          setAlertMessage("An error occurred: " + error.message);
          setAlertOpen(true);
          console.log(error);
        })
        .finally(() => {
          setIsLoading(false);
          console.log("Processing finished");
        });
    }
  };

  const handleMouseOver = (e: React.MouseEvent<any, globalThis.MouseEvent>, desc: string) => {
    //label = "cash"
    // console.log("cash money")
    // console.log(label)
    const tooltip = document.getElementById(`tooltip_${desc}`);
    if (tooltip) {
      tooltip.style.left = e.clientX + "px";
      tooltip.style.top = e.clientY + "px";
      tooltip.innerHTML = `Upload a file for ${desc}`;
      tooltip.style.visibility = "visible";
      tooltip.style.opacity = "1";
    }
  };

  const handleMouseLeave = (desc: string) => {
    const tooltip = document.getElementById(`tooltip_${desc}`);
    if (tooltip) {
      tooltip.style.visibility = "hidden";
      tooltip.style.opacity = "0";
    }
  };

  const formFileLabels: [string, { label: string; desc: string }[]][] = [
    [
      "Gap",
      [
        {
          label: "Employee Enrollment Census (GAP)",
          desc: "Description for this item",
        },
        {
          label: "State & Wage Tax Summary Report Or Enrollment Attestation Statement (GAP)",
          desc: "Description for this item",
        },
        {
          label: "Major Medical Plan Summary",
          desc: "Description for this item",
        },
        { label: "ER Application", desc: "Description for this item" },
        { label: "Location Uploads (Gap)", desc: "Description for this item" },
      ],
    ],
    [
      "Limited Medical",
      [
        {
          label: "Employee Enrollment Census (LM)",
          desc: "Description for this item",
        },
        {
          label: "State & Wage Tax Summary Report Or Enrollment Attestation Statement (LM)",
          desc: "Description for this item",
        },
        {
          label: "Hospital Indemnity Application",
          desc: "Description for this item",
        },
        { label: "AD & D Application", desc: "Description for this item" },
        { label: "Location Uploads (LM)", desc: "Description for this item" },
      ],
    ],
    [
      "MEC – Minimum Essential Coverage and MEC – MVP",
      [
        {
          label: "Employee Enrollment Census (MEC)",
          desc: "Description for this item",
        },
        {
          label: "Signed Client Profile Form (MEC)",
          desc: "Description for this item",
        },
        { label: "W-9 Form (MEC)", desc: "Description for this item" },
        { label: "ASO Agreement", desc: "Description for this item" },
        { label: "BAA Agreement", desc: "Description for this item" },
        { label: "SPD Agreement", desc: "Description for this item" },
        {
          label: "Stop Loss Policy Agreement",
          desc: "Description for this item",
        },
        { label: "Client Profile (IHP)", desc: "Description for this item" },
        {
          label: "Program Application (IHP)",
          desc: "Description for this item",
        },
        { label: "Location Uploads (MEC)", desc: "Description for this item" },
      ],
    ],
    [
      "Other Products & Documents",
      [
        {
          label: "Employee Enrollment Census (Misc)",
          desc: "Description for this item",
        },
        { label: "Other_1", desc: "Description for this item" },
        { label: "Other_2", desc: "Description for this item" },
        { label: "ACH Form", desc: "Description for this item" },
        { label: "Voided Check", desc: "Description for this item" },
        {
          label: "Member Cards File (internal use only)",
          desc: "Description for this item",
        },
        {
          label: "Administration Fee Agent Commission",
          desc: "Description for this item",
        },
        {
          label: "Signed Renewal Letter",
          desc: "Description for this item",
        },
      ],
    ],
  ];

  const handleSectionSelection = (section: string) => {
    setSelectedSections((prevSections) =>
      prevSections.includes(section) ? prevSections.filter((sec) => sec !== section) : [...prevSections, section]
    );
  };
  const sectionButtons = [
    "gap",
    "limited medical",
    "mec – minimum essential coverage and mec – mvp",
    "other products & documents",
  ];
  if (isLoading) {
    return (
      <div className="loader-container">
        <CircularProgress />
      </div>
    );
  }
  return (
    <div className="forms-container">
      <div className="group-number">
        <div className="form-field">
          <label>Group Number:</label>
          <input type="text" value={groupNumber} onChange={handleGroupNumberChange} required />
        </div>
        {/* <button onClick={openAlert}>Show Alert</button> */}
        <CustomAlert message={alertMessage} isOpen={isAlertOpen} onClose={closeAlert} />
        <div>
          <div className="form-field">
            <label>Please select the product (s) in order to upload your documents:</label>
            <div className="form-field section-buttons">
              {sectionButtons.map((button) => (
                <button
                  key={button}
                  onClick={() => handleSectionSelection(button)}
                  style={{
                    backgroundColor: selectedSections.includes(button) ? "#FF8C00" : "#004e9d",
                    color: "white",
                    margin: "5px",
                  }}
                >
                  {button.toUpperCase()}
                </button>
              ))}
            </div>
          </div>
        </div>
        <div className="upload-components">
          {formFileLabels.map(([formTitle, fileLabels]) => {
            if (selectedSections.includes(formTitle.toLowerCase())) {
              return (
                <div className="upload-form" key={formTitle}>
                  <h2>{formTitle}</h2>
                  {fileLabels.map(({ label, desc }) => (
                    <div className="form-field" key={label}>
                      <div className="label-info-wrapper">
                        <label className="yo">{label}</label>
                        <div
                          className="info-bubble"
                          onMouseOver={(e) => handleMouseOver(e, desc)}
                          onMouseLeave={() => handleMouseLeave(desc)}
                        >
                          i
                        </div>
                      </div>
                      <input type="file" key={`fileInput_${resetKey}`} onChange={handleFileChange(label)} />
                      <div className="tooltip-text" id={`tooltip_${desc}`}></div>
                    </div>
                  ))}
                </div>
              );
            } else {
              return null;
            }
          })}
        </div>
      </div>
      <button className="submit-button" onClick={handleSubmit}>
        Submit
      </button>
    </div>
  );
};

export default FileUploadComponent;
