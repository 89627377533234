import { AuthContext } from "components/authProvider/authProvider";
import { FormEvent, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { finishMFASignIn } from "./mfaHelper";

import styles from "./mfa.module.css";

const SignInMFA: React.FC = () => {
  const [mfaCode, setMFACode] = useState("");
  const navigate = useNavigate();

  const authContext = useContext(AuthContext);
  if (!authContext) {
    throw new Error("AuthContext is undefined");
  }
  const { firstName, lastName } = authContext;

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      await finishMFASignIn(mfaCode);
      setMFACode("");
      navigate("/basePage");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {/* <h3>
        Welcome! {firstName} {lastName}
      </h3> */}
      <div className={styles["centered-container"]}>
        <div className={styles["card"]}>
          <>
            {/* <h4>Two Factor Authentication</h4>
            <p>Please input the code that you received in the text message sent to you.</p> */}
            <div className={styles.boxStyle}>
              <h2>OptiAdmin Two Factor Authentication</h2>

              <h6>Please input the 6 digit code that you received via text message</h6>
            </div>
            <form onSubmit={handleSubmit}>
              <label>Two Factor Authentication Required</label>
              <input
                type="text"
                value={mfaCode}
                onChange={(e) => setMFACode(e.target.value)}
                placeholder="MFA Verification Code"
              ></input>
              <button type="submit">Submit</button>
            </form>
          </>
        </div>
      </div>
    </>
  );
};

export default SignInMFA;
