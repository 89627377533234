import React, { useContext, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { AuthContext } from "../authProvider/authProvider";
import { getAuth, signOut } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import Papa from "papaparse";
import "./uploadPage.css"; // adjust the path if your CSS file has a different name or location
import { app, host } from "../../config";
import { Alert } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

// Initialize Firebase Auth and Firestore
const auth = getAuth();
const db = getFirestore(app);

interface errorResponse {
  error: string;
  Member_ID: string | null;
  Family_ID: string | null;
  Insured: string | null;
  Relationship: string | null;
  First_Name: string | null;
  Last_Name: string | null;
  Middle_Name: string | null;
  Name_Prefix: string | null;
  Name_Suffix: string | null;
  Birth_Date: string | null;
  SSN: string | null;
  Member_Seq: string | null;
  Alternate_ID: string | null;
  Unique_ID: string | null;
  Address: string | null;
  Address2: string | null;
  City: string | null;
  State: string | null;
  Zip_Code: string | null;
  Home_Phone: string | null;
  Work_Phone: string | null;
  Fax: string | null;
  Email: string | null;
  Ethnicity_Code: string | null;
  Death_Date: string | null;
  Sex: string | null;
  Marital_Status: string | null;
  Handicap: string | null;
  Date_Employed: string | null;
  Termination_Date: string | null;
  Date_Enrolled: string | null;
  Disenroll_Date: string | null;
  Group_ID: string | null;
  Division_ID: string | null;
  Plan_ID: string | null;
  Tier: string | null;
  Change_Date: string | null;
  Effective_Date: string | null;
  Term_Date: string | null;
  Coverage_Start: string | null;
  state: string | null;
}

const UploadPage: React.FC = () => {
  const [groupId, setGroupId] = useState<string>("");
  const [file, setFile] = useState<File | null>(null);
  const [errorResponse, setErrorResponse] = useState<errorResponse | null>(null);
  const [showDownloadErrors, setShowDownloadErrors] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);

  const authContext = useContext(AuthContext);
  if (!authContext) {
    throw new Error("AuthContext is undefined");
  }
  const { currentUser, suspended } = authContext;

  const navigate = useNavigate();

  var tk: string;
  currentUser
    ?.getIdToken(false)
    .then((idToken: string) => {
      tk = idToken;
    })
    .catch((error: any) => {
      console.log(error);
    });
  // If the user is suspended, sign them out and navigate to the login page
  if (suspended == true) {
    signOut(auth)
      .then(() => {
        console.log("Signed out");
        return <Navigate to="/login" />;
      })
      .catch((error) => {
        console.error("Error signing out", error);
      });
  }

  const handleGroupIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGroupId(e.target.value);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      setFile(files[0]);
      setShowDownloadErrors(false);
    }
  };

  const downloadCSV = (data: any) => {
    let dataToDownload: any[] = data;
    const timeStamp = new Date()
      .toISOString()
      .replace(/[^0-9]/g, "")
      .slice(0, -3);

    const csv = Papa.unparse(dataToDownload);
    const csvData = new Blob([csv], { type: "text/csv" });
    const csvUrl = URL.createObjectURL(csvData);
    let hiddenElement = document.createElement("a");
    hiddenElement.href = csvUrl;
    hiddenElement.download = groupId + "_error_report_" + timeStamp + ".csv";
    hiddenElement.click();
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData();

    if (groupId && file) {
      setIsLoading(true);
      formData.append("file", file);
      const reader = new FileReader();

      const response = await fetch(`${host}/api/enrollments/vba/uploadCensus`, {
        method: "POST",
        headers: {
          authorization: `Bearer ${tk}`,
        },
        body: formData,
      })
        .then(async (response: Response) => {
          if (response.ok) {
            alert(
              "Documents have been successfully uploaded. If you are completing a group implementation form, please return to the form to finish your group submission."
            );
          } else if (response.status == 400) {
            response.json().then((res) => {
              setErrorResponse(res);
              setShowDownloadErrors(true);
            });
          } else {
            throw new Error(`HTTP error! status: ${response.status} message: ${await response.text()}`);
          }
        })
        .catch((error) => {
          alert("An error occurred: " + error.message);
          console.log(error);
        })
        .finally(() => {
          setIsLoading(false);
          console.log("Processing finished");
        });
    } else {
      console.log("Please enter a group ID and select a file");
      alert("Please enter a group ID and select a file");
    }
  };

  const handleDownloadErrorsCheck = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    downloadCSV(errorResponse);
  };

  if (isLoading) {
    return (
      <div className="loader-container">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div>
      <h1 className="centered-header-censusUpload">Upload Census</h1>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="groupId">Group ID: </label>
          <input type="text" id="groupId" value={groupId} onChange={handleGroupIdChange} />
        </div>
        <div>
          <label htmlFor="file">CSV or XLSX File: </label>
          <input type="file" id="file" accept=".csv, .xlsx" onChange={handleFileChange} />
        </div>
        <button type="submit">Upload</button>
        {showDownloadErrors && (
          <div>
            <Alert severity="error">
              There were errors in the file you have uploaded please download and try again.
            </Alert>
            <button type="button" onClick={(e) => handleDownloadErrorsCheck(e)}>
              Download Errors
            </button>
          </div>
        )}
      </form>
    </div>
  );
};

export default UploadPage;

export {};
