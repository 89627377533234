import * as firebase from "firebase/app";
import { getAuth, Auth, setPersistence, browserSessionPersistence } from "firebase/auth";
//import FirebaseApp = firebase.app.App;

//import * as admin from 'firebase-admin';

//const { initializeApp } = require('firebase-admin/app');

const firebaseConfig = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG as string);

const app = firebase.initializeApp(firebaseConfig);

//var serviceAccount = require("path/to/your/serviceAccountKey.json");

//admin.initializeApp({
//credential: admin.credential.cert(serviceAccount),
//databaseURL: "https://<YOUR-PROJECT-ID>.firebaseio.com"
//});

const auth: Auth = getAuth(app);
setPersistence(auth, browserSessionPersistence);

const host = process.env.NODE_ENV == "development" ? "http://localhost:8080" : "";

export { app, auth, host };
