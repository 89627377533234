import React, { useContext, useEffect, useState } from "react";
import {
  TextField,
  Paper,
  Divider,
  Button,
  Box,
  InputLabel,
  FormHelperText,
  Container,
  Grid,
  Alert,
} from "@mui/material";
import "./main.css";
import { host, app, auth } from "../../config";
import { AuthContext } from "../authProvider/authProvider";
import { getAuth, signOut } from "firebase/auth";
import { Navigate, useNavigate } from "react-router-dom";
import { doc, getDoc, getFirestore, DocumentData } from "firebase/firestore";
import { userInfo } from "os";

const Main: React.FC = () => {
  const [form, setForm] = useState<Object>({
    email: "",
    groupNumber: "",
  });

  const [getForm, setGetForm] = useState<any>({
    email: "",
  });

  const [existingGroups, setExistingGroups] = useState<any[]>([]);

  const [buttonState, setButtonState] = useState(true);

  const [removeState, setRemoveState] = useState(false);

  const [existingRemoveState, setExistingRemoveState] = useState(false);

  const [newGroup, setNewGroup] = useState<any[] | {}[]>([]);

  const [searchRole, setSearchRole] = useState<string>();

  const [noUser, setNoUser] = useState(false);

  const [groupNumber, setGroupNumber] = useState<string>();

  const db = getFirestore(app);

  const authContext = useContext(AuthContext); // Get Auth context

  useEffect(() => {
    setNewGroup(newGroup);
  }, [newGroup]);

  if (!authContext) {
    throw new Error("AuthContext is undefined");
  }
  const { currentUser, suspended } = authContext; // Get current user from Auth context

  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  var tk: string;
  currentUser
    ?.getIdToken(false)
    .then((idToken: string) => {
      tk = idToken;
    })
    .catch((error: any) => {
      console.log(error);
    });

  // If the user is suspended, sign them out and navigate to the login page
  if (suspended == true) {
    signOut(auth)
      .then(() => {
        console.log("Signed out");
        return <Navigate to="/login" />;
      })
      .catch((error) => {
        console.error("Error signing out", error);
      });
  }

  const addGroup = () => {
    setNewGroup([...newGroup, {}]);
    console.log(newGroup);
    if (newGroup.length >= 0) {
      setRemoveState(true);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });

    setGetForm({
      ...getForm,
      [e.target.name]: e.target.value,
    });
  };

  const handleGroupNumberChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setGroupNumber(e.target.value);
  };

  const handleNewGroupChange = (i: number, e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let newGroups = [...newGroup];
    newGroups[i][e.target.name] = e.target.value;
    console.log(newGroups);
    setNewGroup(newGroups);
  };

  const removeNewGroup = (i: number) => {
    console.log(i);
    let newGroups = [...newGroup];
    console.log(newGroups);
    newGroups.splice(i, 1);
    console.log(newGroups);
    setNewGroup(newGroups);
  };

  // const removeNewGroup = () => {
  //   setNewGroup((newGroup) => {
  //     const newGroups = [...newGroup];
  //     newGroups.splice(newGroups.length-1, 1);
  //     return newGroups;
  //   })}

  const removeOldGroup = (i: number) => {
    let oldGroups = [...existingGroups];
    oldGroups.splice(i, 1);
    setExistingGroups(oldGroups);
  };

  const handlePostSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    let newGroupNumbers = newGroup.map((item) => item.NewGroup);
    let allGroups = { newGroups: existingGroups.concat(newGroupNumbers) };

    console.log(allGroups);

    if (allGroups) {
      let params = new URLSearchParams(getForm);
      fetch(`${host}/api/firebase/group-manager/addUserToGroup?${params}`, {
        method: "POST",
        headers: {
          authorization: `Bearer ${tk}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(allGroups),
      })
        .catch((error) => {
          // Handle error here
          console.log(error);
        })
        .finally(() => {
          // This will be executed after the fetch regardless of its outcome
          // Reset state variables to clear the form
          setForm({
            email: "",
            groupNumber: "",
          });
          setGetForm({ email: "" });
          setExistingGroups([]);
          setButtonState(true);
          setRemoveState(false);
          setExistingRemoveState(false);
          setNewGroup([]);
          setNoUser(false);
          setSearchRole(undefined);
          setGroupNumber(undefined);
        });
    }
  };
  const handleGroupNumberPostSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    let params = new URLSearchParams();
    params.append("groupNumber", groupNumber as string);
    fetch(`${host}/api/firebase/api/users/${getForm.email}/groupNumber?` + params, {
      method: "POST",
      headers: { authorization: `Bearer ${tk}` },
    })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setForm({
          email: "",
          groupNumber: "",
        });
        setGetForm({ email: "" });
        setExistingGroups([]);
        setButtonState(true);
        setRemoveState(false);
        setExistingRemoveState(false);
        setNewGroup([]);
        setNoUser(false);
        setSearchRole(undefined);
        setGroupNumber(undefined);
      });
  };

  const handleGetSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (getForm) {
      let roleRequest = await fetch(`${host}/api/firebase/api/users/${getForm.email}/role`, {
        method: "GET",
        headers: {
          authorization: `Bearer ${tk}`,
        },
      });
      const data = await roleRequest.json();
      const role = data.data?.Role;
      setSearchRole(role);
      if (role != "broker" && role != "employer") {
        setNoUser(true);
        return;
      }

      if (role == "broker") {
        let params = new URLSearchParams(getForm);
        let groupList = fetch(`${host}/api/firebase/group-manager/user?${params}`, {
          method: "GET",
          headers: {
            authorization: `Bearer ${tk}`,
          },
        })
          .then((response) => response.json())
          .then(
            (user) => {
              console.log(user);
              return user.groups;
            },
            (error) => {
              if (error) {
                console.log(error);
                return "";
              }
            }
          );
        if (await groupList) {
          const getExistingGroups = async () => {
            const a = await groupList;
            setExistingGroups(a);
          };
          getExistingGroups();
          setButtonState(false);
          setExistingRemoveState(true);
          setNoUser(false);
        }
      } else if (role == "employer") {
        let groupNumberRequest = await fetch(`${host}/api/firebase/api/users/${getForm.email}/groupNumber`, {
          method: "GET",
          headers: {
            authorization: `Bearer ${tk}`,
          },
        });
        const json = await groupNumberRequest.json();
        const tempGroupNumber = json.data.Group_number;
        if (tempGroupNumber) {
          setGroupNumber(tempGroupNumber);
          setButtonState(false);
          setNoUser(false);
        }
      }
    }
  };

  return (
    <Container>
      <form>
        <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
          <Grid>
            <Grid item>
              <TextField
                fullWidth
                margin="normal"
                type="text"
                name="email"
                value={getForm.email}
                onChange={handleChange}
                label="Email"
              />
            </Grid>
            <Grid item xs={2.5}>
              <Button
                onClick={handleGetSubmit}
                type="submit"
                size="medium"
                variant="contained"
                color="primary"
                sx={{ mt: 3 }}
              >
                Get User
              </Button>
            </Grid>
          </Grid>

          {noUser ? (
            <Grid container>
              <Grid item xs={12} justifyContent="center">
                <Alert severity="error">Invalid User</Alert>
              </Grid>
            </Grid>
          ) : null}
          {searchRole === "broker" && (
            <>
              <Grid>
                <Divider>Existing Groups</Divider>
                {existingGroups.map((element, index) => {
                  return (
                    <Grid container alignItems="center" spacing={2}>
                      <Grid item xs={4} sx={{ mt: 2 }}>
                        <TextField
                          type="text"
                          name="name"
                          key={element}
                          defaultValue={element}
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={8}>
                        {existingRemoveState ? (
                          <Box display="flex" alignItems="center" justifyContent="flex-end">
                            <Button
                              size="small"
                              variant="contained"
                              color="primary"
                              onClick={() => removeOldGroup(index)}
                            >
                              {" "}
                              Remove
                            </Button>
                          </Box>
                        ) : null}
                      </Grid>
                    </Grid>
                  );
                })}

                {newGroup.length >= 1 ? <Divider sx={{ mt: 3, mb: -2 }}>New Groups</Divider> : null}
                {newGroup.map((element, index) => {
                  return (
                    <>
                      <Grid container alignItems="center" spacing={1}>
                        <Grid item xs={12}></Grid>
                        <Grid item xs={4}>
                          <TextField
                            margin="normal"
                            type="text"
                            name="NewGroup"
                            key={element}
                            value={element.NewGroup}
                            onChange={(e) => handleNewGroupChange(index, e)}
                            label="NewGroup"
                          />
                        </Grid>
                        <Grid item xs={8}>
                          {removeState ? (
                            <Box display="flex" alignItems="center" justifyContent="flex-end">
                              <Button
                                size="small"
                                variant="contained"
                                color="primary"
                                onClick={() => removeNewGroup(index)}
                              >
                                {" "}
                                Remove
                              </Button>
                            </Box>
                          ) : null}
                        </Grid>
                      </Grid>
                    </>
                  );
                })}
              </Grid>
              <Grid container>
                <Grid item xs={3}>
                  <Button
                    disabled={buttonState}
                    onClick={() => addGroup()}
                    variant="contained"
                    size="medium"
                    color="primary"
                    sx={{ mt: 3 }}
                  >
                    Add Group
                  </Button>
                </Grid>
              </Grid>
              <Grid container sx={{ display: "flex", justifyContent: "center" }}>
                <Grid item xs={6}>
                  <Button
                    onClick={handlePostSubmit}
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{ mt: 3, ml: 1 }}
                  >
                    Submit Changes
                  </Button>
                </Grid>
              </Grid>
            </>
          )}
          {searchRole === "employer" && (
            <>
              <Grid>
                <Grid container>
                  <Grid item xs={12} justifyContent="center">
                    <Alert severity="info">Please enter the group number that you would like to assign.</Alert>
                  </Grid>
                </Grid>
                <Grid item>
                  <TextField
                    fullWidth
                    margin="normal"
                    type="text"
                    name="groupNumber"
                    value={groupNumber}
                    defaultValue={groupNumber}
                    onChange={handleGroupNumberChange}
                    label="Group Number"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={2.5}>
                  <Button
                    onClick={handleGroupNumberPostSubmit}
                    type="submit"
                    size="medium"
                    variant="contained"
                    color="primary"
                    sx={{ mt: 3 }}
                  >
                    Submit Group Number
                  </Button>
                </Grid>
              </Grid>
            </>
          )}
        </Paper>
      </form>
    </Container>
  );
};

export default Main;

// if (!response.ok) {
//   let result;
//   try {
//     result = await response.json();
//   } catch {
//     // Couldn't parse the JSON
//     throw new Error(response.status);
//     const result = undefined;
//   }
//   const { message: message_1 } = result;
//   // Got valid JSON with error response, use it
//   throw new Error(message_1 || response.status);
// }
