import React, { useState, useEffect, useContext, useRef } from "react";
// import "./dataComponentEmp.css";
import "./vbatable.css";
import { AuthContext } from "../authProvider/authProvider";
import { Navigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretRight } from "@fortawesome/free-solid-svg-icons";

import { faEdit, faIdCard, faUserTimes, faEye, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { getAuth, signOut } from "firebase/auth";
import { doc, getDoc, getFirestore, DocumentData } from "firebase/firestore";
import { app, host } from "../../config";
import Modal from "react-modal";
import { Alert, Pagination } from "@mui/material";
import { Checkbox, TextField } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { jsPDF } from "jspdf";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CustomAlert from "components/alert/CustomAlert";

const auth = getAuth();
const db = getFirestore(app);

export type SortedVBADataType = {
  [key: string]: VBADataType[];
};

export type VBADataType = {
  [key: string]: string | boolean;
  Subscriber_ID: string; //dependent and primary have the same subscriber id
  Member_Seq: string;
  Alternate_ID: string;
  First_Name: string;
  Last_Name: string;
  Name_Suffix: string;
  Las_4_Of_SSN: string;
  City: string;
  State: string;
  Zip_Code: string;
  Birth_Date: string;
  Disenroll_Date: string;
  Group_ID: string;
  Premium_Proration: boolean;
  Division_ID: string;
  Plan_ID: string;
  Subscriber_Deductible: string;
  Member_Deductible: string;
  OOP_Max_Net_Sub: string;
  OOP_Max_Net_Mem: string;
  Plan_Name: string;
  Start_Date: string;
  End_Date: string;
  Address: string;
  Address2: string;
  VIP_Flag: string;
  Subscriber_First_Name: string; //also have same sub first name and last name
  Subscriber_Last_Name: string;
  Date_Enrolled: string;
  Group_Coverage_Start: string;
  Group_Coverage_Start_Date: string;
  Plan_Start: string;
  Benefit_Description: string;
  Plan_Start_Date: string;
  Plan_End_Date: string;
  Member_Annual_Max: string;
  Annual_Max: string;
};
//member deduductible and subscriber deductioneb
//member max and annual max
type ApiDataComponentProps = {
  data: VBADataType[];
  handleChange: any;
  handleTerminationChange: Function;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setAlertMessage: React.Dispatch<React.SetStateAction<string>>;
  showTerminated: Boolean;
  search: string | null;
  setSearch: Function;
  totalPages: number;
  handlePagination: (event: React.ChangeEvent<unknown>, page: number) => void;
};

declare module "jspdf" {
  interface jsPDF {
    setLineDash(pattern: number[], phase?: number): void;
  }
}

Modal.setAppElement("#root");

const VBAApiDataComponent: React.FC<ApiDataComponentProps> = ({
  data,
  handleChange,
  handleTerminationChange,
  setIsLoading,
  setAlertMessage,
  showTerminated,
  search,
  setSearch,
  totalPages,
  handlePagination,
}) => {
  const [showEditForm, setShowEditForm] = useState(false);
  const [selectedMember, setSelectedMember] = useState<VBADataType | null>(null);
  const [editedMember, setEditedMember] = useState<VBADataType | null>(null);

  const terminateDateRef = useRef(null);

  const authContext = useContext(AuthContext);
  if (!authContext) {
    throw new Error("AuthContext is undefined");
  }
  const { currentUser } = authContext;

  var tk: string;
  currentUser
    ?.getIdToken(false)
    .then((idToken: string) => {
      tk = idToken;
    })
    .catch((error: any) => {
      console.log(error);
    });

  const [docData, setDocData] = useState<DocumentData | null>(null);

  // Fields for the form
  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [selectedMemberDOB, setSelectedMemberDOB] = useState("");
  const [selectedMemberSubscriberID, setSelectedMemberSubscriberID] = useState("");
  const [selectedMemberSeq, setSelectedMemberSeq] = useState("");
  const [selectedGroupID, setSelectedGroupID] = useState("");
  const [addressOne, setAddressOne] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");

  const [showOrderCardForm, setShowOrderCardForm] = useState(false);
  const [tempCardNeeded, setTempCardNeeded] = useState(false);

  const [showTerminateForm, setShowTerminateForm] = useState(false);
  const [terminateDate2, setTerminateDate2] = useState("");
  const [terminateDate, setTerminateDate] = useState("");
  const [termAnyDay, setTermAnyDay] = useState(true);

  const [showCoverageModal, setShowCoverageModal] = useState(false);

  const [addDependentModal, setAddDependentModal] = useState(false);
  const [enrollmentReason, setEnrollmentReason] = useState("openEnrollment");
  const [gender, setGender] = useState("M");
  const [relationship, setRelationship] = useState("02");
  const [coverageDate, setCoverageDate] = useState("");
  const [dependentSSN, setDependentSSN] = useState("");
  const [dependentDOB, setDependentDOB] = useState("");
  const [dependentFirstName, setDependentFirstName] = useState("");
  const [dependentLastName, setDependentLastName] = useState("");

  const [errorMessage, setErrorMessage] = useState<string>("");
  const [expandedRows, setExpandedRows] = useState<Set<number>>(new Set());

  const [data2, setData2] = useState<VBADataType[]>([]);
  const [dataNew, setDataNew] = useState<VBADataType[]>(data);

  const [currentTab, setCurrentTab] = useState("active");

  const [expandAll, setExpandAll] = useState(false);

  const [email, setEmail] = useState("");
  const [selectedMemberDependents, setSelectedMemberDependents] = useState<VBADataType[]>([]);
  // State for tracking members selected for termination
  const [selectedForTermination, setSelectedForTermination] = useState<Map<string, VBADataType>>(new Map());

  const [errorAlert, setErrorAlert] = useState<boolean>(false);
  const [errorText, setErrorText] = useState<string>("");

  // Handler for selecting/deselecting members for termination
  const handleSelectForTermination = (memberId: string, member: VBADataType) => {
    setErrorAlert(false)
    setErrorText("")
    setSelectedForTermination((prevSelected) => {
      const newSelection = new Map(prevSelected);
      if (newSelection.has(memberId)) {
        newSelection.delete(memberId);
      } else {
        newSelection.set(memberId, member);
      }

      return newSelection;
    });
  };

  const [isInputInteractive, setIsInputInteractive] = useState(false);

  const toggleExpandAll = () => {
    setExpandAll(!expandAll);
  };

  useEffect(() => {
    if (expandAll) {
      const allRows = new Set(dataNew.map((_, index) => index));
      setExpandedRows(allRows);
    } else {
      setExpandedRows(new Set());
    }
  }, [expandAll, dataNew]);

  useEffect(() => {
    if (showTerminated) {
      setCurrentTab("terminated");
    } else {
      setCurrentTab("active");
    }
  }, [showTerminated]);

  useEffect(() => {
    const filteredData = data.filter((item) => item.Member_Seq !== "01");
    const updatedData = data.filter((item) => item.Member_Seq === "01");
    setDataNew(updatedData);
    setData2(filteredData);
  }, [data]);

  const toggleExpandRow = (index: number) => {
    const newExpandedRows = new Set(expandedRows);
    if (expandedRows.has(index)) {
      newExpandedRows.delete(index);
    } else {
      newExpandedRows.add(index);
    }
    setExpandedRows(newExpandedRows);
  };

  useEffect(() => {
    async function getDocument() {
      if (currentUser == null) {
        return <Navigate to="/login" />;
      }

      let docRef = doc(db, "user-info", currentUser.uid);

      try {
        let docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setDocData(docSnap.data());
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.log("Error getting document:", error);
      }
    }

    getDocument();
  }, []);

  useEffect(() => {
    clearMapAndTriggerUpdate();
  }, [showTerminateForm]);

  function clearMapAndTriggerUpdate() {
    // Clear the existing map
    selectedForTermination.clear();

    setSelectedForTermination(new Map(selectedForTermination));

    // Alternatively, force an update by other means, such as using a separate state to force updates.
  }

  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  if (docData?.Suspended == true) {
    signOut(auth)
      .then(() => {
        console.log("Signed out");
        return <Navigate to="/login" />;
      })
      .catch((error) => {
        console.error("Error signing out", error);
      });
  }

  const handleChangeTabs = (event: any, newValue: string) => {
    setIsLoading(true);
    setCurrentTab(newValue);
    handleTerminationChange(newValue === "terminated");
  };

  const handleSSNChange = (event) => {
    const value = event.target.value;
    // Remove any non-numeric characters
    const numericValue = value.replace(/\D/g, "");
    setDependentSSN(numericValue);
  };

  const fieldsToDisplay = {
    // "Subscriber_Last_Name",
    First_Name: "First_Name",
    Last_Name: "Last_Name",
    Last_4_Of_SSN: "Last_4_Of_SSN",
    Member_Seq: "Member_Seq",
    Plan_ID: "Plan_ID",
    Plan_Name: "Plan_Name",
    Group_Coverage_Start_Date: "Display_Group_Coverage_Start_Date",
    End_Date: "Display_End_Date",
    Plan_Start_Date: "Display_Plan_Start_Date",
    Plan_End_Date: "Display_Plan_End_Date",
  };

  const openModal = () => {
    setShowEditForm(true);
  };

  const closeModal = () => {
    setShowEditForm(false);
  };

  const modalStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "40%", // This line controls the width of the modal
      height: "65%", // This line controls the height of the modal
    },
  };

  const modalStyles3 = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "70%", // This line controls the width of the modal
      height: "90%", // This line controls the height of the modal
    },
  };

  const modalStyles2 = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "40%", // This line controls the width of the modal
      height: "85%", // This line controls the height of the modal
    },
  };

  const handleEdit = (item: VBADataType) => {
    // Open the form and set the member to be edited
    setSelectedMember(item);
    // Initialize the form fields
    setLastName(item.Last_Name);
    setAddressOne(item.Address);
    setCity(item.City);
    setState(item.State);
    setZipCode(item.Zip_Code);

    openModal();
  };

  const handleSaveEdit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Implement save changes here
    let edited_member = {
      ...selectedMember,
      Last_Name: lastName,
      Address: addressOne,
      City: city,
      State: state,
      Zip_Code: zipCode,
    };

    let memID = edited_member?.Subscriber_ID + (edited_member?.Member_Seq as string);

    console.log(memID);
    setIsLoading(true);

    //let params = new URLSearchParams({ groupNo: groupNumber }).toString();

    fetch(`${host}/api/enrollments/vba/members/${memID}/update`, {
      method: "PUT",
      headers: {
        authorization: `Bearer ${tk}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(edited_member),
    })
      .then((response: Response) => {
        response.json().then(() => {
          console.log("edit submitted");
          setIsLoading(false);
          setAlertMessage(`Member Has Been Successfully Updated!`);
        });
      })
      .catch((error: any) => {
        console.log(error);
        setIsLoading(false);
        setAlertMessage(
          `There Was An Error Editing The Member. Please Try Again Later And Contact Your Account Manager If The Issue Persists.`
        );
      })
      .finally(() => handleChange());

    // Close the form
    //setIsLoading(false);
    closeModal();
  };

  const handleOrderCard = (item: VBADataType) => {
    // Implement order card function here
    setShowOrderCardForm(true);
    setSelectedMember(item);
    console.log("Order card for item:", item);
  };

  const handleOrderCardSubmit = () => {
    const subscriberId = selectedMember?.Subscriber_ID;
    // Implement order card function here
    console.log("Order card for item:", selectedMember, "Temp card needed:", tempCardNeeded);
    const body = {
      ...selectedMember,
      tempCardNeeded: tempCardNeeded,
      email: email, // include email in the body
    };
    setIsLoading(true);
    fetch(`${host}/api/enrollments/vba/IdCard/order/${subscriberId}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${tk}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response: Response) => {
        response.json().then(() => {
          setIsLoading(false);
          setAlertMessage(`A New ID Card Has Been Requested!`);
        });
      })
      .catch((error: any) => {
        console.log(error);
        setIsLoading(false);
        setAlertMessage(
          `There Was An Error Ordering Cards. Please Try Again Later And Contact Your Account Manager If The Issue Persists.`
        );
      })
      .finally(() => handleChange());

    // Close the form
    setShowOrderCardForm(false);
  };

  const handleTempCardChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTempCardNeeded(e.target.checked);
  };

  const handleMakeInteractive = () => {
    setIsInputInteractive(true);
  };

  // Make input readOnly again when it loses focus or mouse out
  const handleMakeReadOnly = () => {
    setIsInputInteractive(false);
  };

  const handleKeyDown = (e) => {
    // List of allowed keys, add any keys you want to allow, like arrow keys for navigation
    const allowedKeys = ["ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight", "Tab", "Enter"];

    if (!allowedKeys.includes(e.key)) {
      console.log("Prevented default");
      // Preventing the default action for keys not in the allowedKeys list

      console.log(e.key);
      return false;
    }
  };

  const handleInputFocus = (event) => {
    // This function will be triggered when the input field is focused.
    // It checks if the focus event is due to a mouse click (which opens the date picker)
    // and temporarily disables the keyboard interaction.
    const input = event.target;
    input.setAttribute("readonly", "true"); // Make input readonly to block keyboard on focus
    input.style.pointerEvents = "none"; // Disable pointer events to avoid immediate blur

    // Re-enable pointer events shortly after to allow date picker usage
    setTimeout(() => {
      input.style.pointerEvents = "auto";
    }, 50);
  };

  const handleInputBlur = (event) => {
    // This function re-enables the input field when it loses focus,
    // ensuring that the field is not left in a permanently disabled state.
    const input = event.target;
    input.removeAttribute("readonly"); // Remove readonly attribute when input blurs
  };

  const handleTerminate2 = (item: VBADataType) => {
    // Implement terminate function here
    setShowTerminateForm(true);
    setSelectedMember(item);
    setTermAnyDay(item.Premium_Proration);
    setLastName(item.Last_Name);
    setFirstName(item.First_Name);
    setSelectedMemberDOB(item.Birth_Date.slice(0, 10));
    setSelectedMemberSubscriberID(item.Subscriber_ID);
    setSelectedMemberSeq(item.Member_Seq);
    setSelectedGroupID(item.Group_ID);
  };

  const handleTerminateSubmit = () => {
    // Implement terminate function here
    if (errorMessage !== "" && errorMessage !== null) {
      alert("Please correct the error message before submitting");
      console.log("Error message:", errorMessage);
      return;
    }

    var membersDeleted = Array.from(selectedForTermination.values());
    //console.log("Terminate item:", selectedMember, "Termination date:", terminateDate);

    let subID = selectedMember?.Subscriber_ID;

    var termBody = {
      members: membersDeleted,
      termDate: terminateDate,
      //disenrollMember: disenrollMemberCheckBox,
    };
    console.log(termBody.members.length)
    if (termBody.members.length < 1) {
      setErrorAlert(true)
      setErrorText('Please select a member using the check box on the left of the member details in the table above.')
    } else {
      setIsLoading(true);
      fetch(`${host}/api/enrollments/vba/term/member/${subID}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${tk}`,
        },
        body: JSON.stringify(termBody),
      })
        .then((response: Response) => {
          response.json().then((res) => {
            setIsLoading(false);
            setAlertMessage(`The Members Have Been Terminated!`);
          });
        })
        .catch((error: any) => {
          console.log(error);
          setIsLoading(false);
          setAlertMessage(
            `There Was An Error Terminating. Please Try Again Later And Contact Your Account Manager If The Issue Persists.`
          );
        })
        .finally(() => {
          handleChange();
          setIsLoading(false);
        });
  
      // Close the form
      clearMapAndTriggerUpdate();
      setShowTerminateForm(false);
    }

  };

  const getSixtyDaysPrior = () => {
    const today = new Date();
    const sixtyDaysPrior = new Date(today);
    sixtyDaysPrior.setDate(today.getDate() - 60);
    console.log("this is sixty days prior");
    console.log(sixtyDaysPrior);
    return sixtyDaysPrior;
  };

  const sixtyDaysPrior = getSixtyDaysPrior();

  function formatDateToYYYYMMDD(date: any) {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  const handleTerminateDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedDateStr = e.target.value;

    // Regex to check if date is in YYYY-MM-DD format
    const dateFormatRegex = /^\d{4}-\d{2}-\d{2}$/;
    const selectedDate = new Date(selectedDateStr);
    const minDate = getSixtyDaysPrior();

    if (!dateFormatRegex.test(selectedDateStr)) {
      setErrorMessage("Date format is incorrect. Please use YYYY-MM-DD.");
    } else if (isNaN(selectedDate.getTime())) {
      setErrorMessage("Invalid date. Please enter a valid date in YYYY-MM-DD format.");
    } else if (selectedDate <= minDate) {
      console.log("WRONG");
      setErrorMessage("Termination date cannot be more than 60 days in the past.");
    } else {
      setErrorMessage("");
    }

    //setErrorMessage("");
    setTerminateDate(e.target.value);

    if (false) {
      if (termAnyDay === false) {
        let lastDay = 31;
        switch (selectedDate.getMonth()) {
          case 3: // April
          case 5: // June
          case 8: // September
          case 10: // November
            lastDay = 30;
            break;
          case 1: // February
            lastDay = 28;
            break;
        }

        // Correct way to get the last day of the current month
        const lastDayOfMonth = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), lastDay);

        // Check if selected date is the last day of the month
        if (selectedDate.getDate() !== lastDayOfMonth.getDate()) {
          setErrorMessage("Only the last day of the month is allowed.");
          // Adjust the selectedDate to the last day of the current month
          selectedDate.setDate(lastDayOfMonth.getDate());

          function formatDateToYYYYMMDD(date: any) {
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, "0");
            const day = date.getDate().toString().padStart(2, "0");

            return `${year}-${month}-${day}`;
          }
          // Now update the state with the adjusted date
          const formattedDate = formatDateToYYYYMMDD(selectedDate);
          setTerminateDate(formattedDate);
        } else {
          setErrorMessage("");
          setTerminateDate(e.target.value);
        }
      } else {
        setTerminateDate(e.target.value);
      }
    }
  };

  const handleClearDate = () => {
    setTerminateDate("");
    setErrorMessage("");
  };

  const handleShowCoverage = (item: VBADataType) => {
    // Implement show coverage function here
    setSelectedMember(item);
    setShowCoverageModal(true);
  };

  const handleShowCoverageClose = () => {
    // Close the modal
    setShowCoverageModal(false);
  };

  const handleAddDependent = (item: VBADataType) => {
    setSelectedMember(item);
    setAddDependentModal(true);
  };

  const handleAddDependentClose = () => {
    setAddDependentModal(false);
  };

  const handleSaveDependent = () => {
    // Implement save dependent logic here.
    //e.preventDefault();
    let dependentSave = {
      Sex: gender,
      Plan_ID: selectedMember?.Plan_ID,
      Division_ID: selectedMember?.Division_ID,
      Reason: enrollmentReason,
      Date_Enrolled: coverageDate,
      Birth_Date: dependentDOB,
      First_Name: dependentFirstName,
      Last_Name: dependentLastName,
      SSN: dependentSSN,
      Address: selectedMember?.Address,
      Address2: selectedMember?.Address2,
      City: selectedMember?.City,
      Zip_Code: selectedMember?.Zip_Code,
      State: selectedMember?.State,
      Relationship: relationship,
      Group_Coverage_Start: selectedMember?.Group_Coverage_Start,
      Start_Date: selectedMember?.Start_Date,
      Plan_Start: selectedMember?.Plan_Start,
      Group_ID: selectedMember?.Group_ID,
      Subscriber_ID: selectedMember?.Subscriber_ID,
    };

    setIsLoading(true);

    fetch(`${host}/api/enrollments/vba/subscriber/addDependents`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${tk}`,
      },
      body: JSON.stringify({ member: dependentSave, fromEmpMaint: true }),
    })
      .then((response: Response) => {
        response.json().then(() => {
          setIsLoading(false);
          setAlertMessage(`Please allow 24 hours for this enrollment to be processed.`);
        });
      })
      .catch((error: any) => {
        console.log(error);
        setIsLoading(false);
        setAlertMessage(
          `There Was An Error Adding The Dependent. Please Try Again Later And Contact Your Account Manager If The Issue Persists.`
        );
      })
      .finally(() => handleChange());

    // Close the form
    resetForm();

    handleAddDependentClose();
  };

  //  const [enrollmentReason, setEnrollmentReason] = useState("openEnrollment");
  //  const [gender, setGender] = useState("M");
  //  const [relationship, setRelationship] = useState("02");

  const resetForm = () => {
    setEnrollmentReason("openEnrollment");
    setGender("M");
    setRelationship("02");
    setCoverageDate("");
    setDependentSSN("");
    setDependentDOB("");
    setDependentFirstName("");
    setDependentLastName("");
  };

  /* Custom Modal Styles */
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "50%", // Adjust the width to your preference
      border: "1px solid #ccc", // Optional border
      borderRadius: "10px", // Optional rounded corners
      padding: "20px", // Adjust padding to your preference
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Optional box shadow for aesthetics
    },
    // More styling can be applied to overlay or other parts as needed
  };

  function formatCurrency(value?: string) {
    if (!value) return value;
    const number = parseFloat(value);
    return number.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }

  function formatDate(dateString: string) {
    if (!dateString) return ""; // Return empty string if date is undefined or null
    const [year, month, day] = dateString.split("-");
    return `${month}/${day}/${year}`;
  }

  const drawDottedLine = (
    doc: jsPDF,
    xFrom: number,
    yFrom: number,
    xTo: number,
    yTo: number,
    isVertical: boolean = false
  ) => {
    doc.setLineDash([3, 3], 0); // Set the "dotted" pattern
    if (isVertical) {
      // Draw a vertical dotted line by drawing many small lines
      let y = yFrom;
      while (y < yTo) {
        doc.line(xFrom, y, xFrom, y + 2); // Draw a small line segment
        y += 6; // The gap between segments
      }
    } else {
      // Draw a horizontal line
      doc.line(xFrom, yFrom, xTo, yTo); // Draw the line
    }
    doc.setLineDash([]); // Reset to a solid line, passing an empty array
  };

  const downloadPDF = () => {
    if (!selectedMember) return;

    const doc = new jsPDF();

    // Example data, replace with your data as needed
    const {
      First_Name,
      Last_Name,
      Subscriber_ID,
      Group_ID,
      Member_Seq,
      Plan_Start_Date,
      Member_Deductible,
      Subscriber_Deductible,
      Member_Annual_Max,
      Annual_Max,
    } = selectedMember;
    // Assuming Birth_Date is the date you want to include and formatted correctly
    const datePrinted = `Date Printed: ${new Date().toLocaleDateString("en-US")}`;

    // console.log("this is benefti description");
    // console.log(Benefit_Description);
    doc.setFontSize(10);
    // Include Member Name, Member ID, Member Group Number, Member Plan information, Member Effective date, BIN Number (If Rx) Payor ID 96277
    // Date Printed
    drawDottedLine(doc, 10, 2, doc.internal.pageSize.width - 60, 2); // Draw line across the page
    doc.text(datePrinted, 10, 10);
    drawDottedLine(doc, 10, 14, doc.internal.pageSize.width - 60, 14); // Draw line across the page

    const leftMargin = 5;
    const topMargin = 5;
    const bottomMargin = doc.internal.pageSize.height - 10;
    drawDottedLine(doc, leftMargin, topMargin, leftMargin, bottomMargin, true);
    //drawDottedLine(doc, rightMargin, topMargin, rightMargin, bottomMargin, true);

    // Name
    doc.setFontSize(12);
    // drawDottedLine(doc, 10, 19, doc.internal.pageSize.width - 10, 19);
    doc.text(`Name: ${First_Name} ${Last_Name}`, 10, 22);
    drawDottedLine(doc, 10, 24, doc.internal.pageSize.width - 60, 24); // Draw line across the page

    // ID
    doc.text(`Insured ID: ${Subscriber_ID}`, 10, 34);
    doc.text(`Member ID: ${Member_Seq}`, 10, 38);
    doc.text(`Group #: ${Group_ID}`, 10, 42);
    drawDottedLine(doc, 10, 46, doc.internal.pageSize.width - 60, 46); // Draw line across the page

    //  Member_Deductible: string;
    //  OOP_Max_Net_Sub: string;
    //  OOP_Max_Net_Mem: string;
    // Description
    doc.setFontSize(10);
    doc.text(`Member Deductible: ${formatCurrency(Member_Deductible)}`, 10, 50);
    doc.text(`Subscriber Deductible: ${formatCurrency(Subscriber_Deductible)}`, 10, 54);
    doc.text(`Member Annual Max: ${formatCurrency(Member_Annual_Max)}`, 10, 58);
    doc.text(`Annual Max: ${formatCurrency(Annual_Max)}`, 10, 62);
    doc.text("This is a Temporary ID Card, good for 30 days, and is not", 10, 66);
    doc.text("intended for permanent usage. Any detailed PPO Network and", 10, 71);
    doc.text("Prescription information is NOT listed on this card and may", 10, 76);
    doc.text("require direct contact with your plan administrator if these services", 10, 81);
    doc.text("are needed.", 10, 86);

    const dateLocal = new Date(Plan_Start_Date);

    // Extract components using getUTC* methods to prevent timezone issues
    const year = dateLocal.getUTCFullYear(); // 2024
    const month = dateLocal.getUTCMonth(); // 0, as months are 0-indexed
    const day = dateLocal.getUTCDate(); // 1

    // Create an array of month names to convert month number to month name
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    // Format the date
    const formattedDate = `${monthNames[month]} ${day}, ${year}`;

    // Eligibility and Benefits
    doc.setFontSize(12);
    drawDottedLine(doc, 10, 94, doc.internal.pageSize.width - 60, 94); // Draw line above the section
    doc.text("Eligibility and Benefits:", 10, 104);
    doc.setFontSize(10);
    doc.text(`Effective: ${formattedDate}`, 10, 112);
    doc.text("Please contact OptiMed Health Plans at: 800-482-8770.", 10, 117);

    // Submitting Claims
    doc.setFontSize(12);
    drawDottedLine(doc, 10, 125, doc.internal.pageSize.width - 60, 125); // Draw line above the section
    doc.text("Submitting Claims:", 10, 130);
    doc.setFontSize(10);
    doc.text("Medical OptiMed Health Plans Claims:", 10, 135);
    doc.text("3145 Avalon Ridge Place, Suite 300", 10, 140);
    doc.text("Peachtree Corners, GA 30071", 10, 145);

    drawDottedLine(doc, 10, 150, doc.internal.pageSize.width - 60, 150); // Draw line above the section

    // drawDottedLine(doc, 50, 10, 50, 140, true); // Vertical line at x=50 from y=10 to y=140
    drawDottedLine(doc, 150, 10, 150, 140, true); // Vert

    doc.save("member-details.pdf");
  };

  // Helper function to draw a dotted line

  function ActiveEmployeesTable() {
    // ... Your current table code here ...

    if (dataNew.length === 0) {
      return (
        <div className="table-wrapper">
          <table>
            <thead>
              <tr>
                <th>Dependents</th>
                {Object.keys(fieldsToDisplay).map((key) => (
                  <th key={key}>{key.replace(/_/g, " ")}</th>
                ))}
                <th>Actions</th>
              </tr>
            </thead>
          </table>
          <div className="no-results-box">No search results found</div>
        </div>
      );
    }

    return (
      <div className="table-wrapper">
        <table>
          <thead>
            <tr>
              <th>Dependents</th>
              {Object.keys(fieldsToDisplay).map((key) => (
                <th key={key}>{key.replace(/_/g, " ")}</th>
              ))}
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {dataNew.map((item, index) => {
              item.Display_Group_Coverage_Start_Date = formatDate(item.Group_Coverage_Start_Date?.split("T")[0]);
              item.Display_End_Date = formatDate(item.End_Date?.split("T")[0]);
              item.Display_Plan_Start_Date = formatDate(item.Plan_Start_Date?.split("T")[0]);
              item.Display_Plan_End_Date = formatDate(item.Plan_End_Date?.split("T")[0]);

              return (
                <>
                  <tr key={index} className="table-row">
                    <td>
                      {/* Show the dropdown button only if Member_Seq is "01" */}
                      {item.Member_Seq === "01" &&
                        data2.some(
                          (filteredItem) =>
                            filteredItem.Subscriber_ID === item.Subscriber_ID &&
                            filteredItem.Plan_ID === item.Plan_ID &&
                            filteredItem.Start_Date === item.Start_Date
                        ) && (
                          <span onClick={() => toggleExpandRow(index)} className="large-iconData">
                            {expandedRows.has(index) ? (
                              <FontAwesomeIcon icon={faCaretDown} />
                            ) : (
                              <FontAwesomeIcon icon={faCaretRight} />
                            )}
                          </span>
                        )}
                    </td>
                    {Object.keys(fieldsToDisplay).map((key) => (
                      <td key={key} className="table-cell">
                        {item[fieldsToDisplay[key as keyof typeof fieldsToDisplay]]}
                      </td>
                    ))}
                    <td>
                      <Tooltip title="Edit this item">
                        <button className="hover-buttonDataComp" onClick={() => handleEdit(item)}>
                          <FontAwesomeIcon icon={faEdit} />
                        </button>
                      </Tooltip>
                      <Tooltip title="Order an ID card for this item">
                        <button className="hover-buttonDataComp" onClick={() => handleOrderCard(item)}>
                          <FontAwesomeIcon icon={faIdCard} />
                        </button>
                      </Tooltip>
                      <Tooltip title="Terminate this item">
                        <button className="hover-buttonDataComp" onClick={() => handleTerminate2(item)}>
                          <FontAwesomeIcon icon={faUserTimes} />
                        </button>
                      </Tooltip>
                      <Tooltip title="Show coverage details for this item">
                        <button className="hover-buttonDataComp" onClick={() => handleShowCoverage(item)}>
                          <FontAwesomeIcon icon={faEye} />
                        </button>
                      </Tooltip>
                      <Tooltip title="Add a dependent for this item">
                        <button className="hover-buttonDataComp" onClick={() => handleAddDependent(item)}>
                          <FontAwesomeIcon icon={faUserPlus} />
                        </button>
                      </Tooltip>
                    </td>
                  </tr>
                  {expandedRows.has(index) && (
                    <>
                      {data2
                        .filter(
                          (filteredItem) =>
                            filteredItem.Subscriber_ID === item.Subscriber_ID &&
                            filteredItem.Plan_ID === item.Plan_ID &&
                            filteredItem.Start_Date === item.Start_Date
                        )
                        .map((filteredItem, filteredIndex) => {
                          filteredItem.Display_Group_Coverage_Start_Date = formatDate(
                            filteredItem.Group_Coverage_Start_Date?.split("T")[0]
                          );
                          filteredItem.Display_End_Date = formatDate(filteredItem.End_Date?.split("T")[0]);
                          filteredItem.Display_Plan_Start_Date = formatDate(
                            filteredItem.Plan_Start_Date?.split("T")[0]
                          );
                          filteredItem.Display_Plan_End_Date = formatDate(filteredItem.Plan_End_Date?.split("T")[0]);
                          return (
                            <tr key={"expanded-" + filteredIndex}>
                              <td>Dependent</td> {/* This could be left empty or contain some icon/label */}
                              {Object.keys(fieldsToDisplay).map((key) => (
                                <td key={"expanded-" + key}>
                                  {filteredItem[fieldsToDisplay[key as keyof typeof fieldsToDisplay]]}
                                </td>
                              ))}
                              <td>
                                {/* This could be left empty or contain some other action buttons */}
                                <Tooltip title="Edit this item">
                                  <button className="hover-buttonDataComp" onClick={() => handleEdit(filteredItem)}>
                                    <FontAwesomeIcon icon={faEdit} />
                                  </button>
                                </Tooltip>
                                <Tooltip title="Order an ID card for this item">
                                  <button
                                    className="hover-buttonDataComp"
                                    onClick={() => handleOrderCard(filteredItem)}
                                  >
                                    <FontAwesomeIcon icon={faIdCard} />
                                  </button>
                                </Tooltip>
                                {/* <Tooltip title="Terminate this item">
                                  <button
                                    className="hover-buttonDataComp"
                                    onClick={() => handleTerminate2(filteredItem)}
                                  >
                                    <FontAwesomeIcon icon={faUserTimes} />
                                  </button>
                                </Tooltip> */}
                                <Tooltip title="Show coverage details for this item">
                                  <button
                                    className="hover-buttonDataComp"
                                    onClick={() => handleShowCoverage(filteredItem)}
                                  >
                                    <FontAwesomeIcon icon={faEye} />
                                  </button>
                                </Tooltip>
                              </td>
                            </tr>
                          );
                        })}
                    </>
                  )}
                </>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }

  function TerminatedEmployeesTable() {
    if (dataNew.length === 0) {
      return (
        <div className="table-wrapper">
          <table>
            <thead>
              <tr>
                <th>Dependents</th>
                {Object.keys(fieldsToDisplay).map((key) => (
                  <th key={key}>{key.replace(/_/g, " ")}</th>
                ))}
                <th>Actions</th>
              </tr>
            </thead>
          </table>
          <div className="no-results-box">No search results found</div>
        </div>
      );
    }
    return (
      <div className="table-wrapper">
        <table>
          <thead>
            <tr>
              <th>Dependents</th>
              {Object.keys(fieldsToDisplay).map((key) => (
                <th key={key}>{key.replace(/_/g, " ")}</th>
              ))}
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {dataNew.map((item, index) => {
              item.Display_Group_Coverage_Start_Date = formatDate(item.Group_Coverage_Start_Date?.split("T")[0]);
              item.Display_End_Date = formatDate(item.End_Date?.split("T")[0]);
              item.Display_Plan_Start_Date = formatDate(item.Plan_Start_Date?.split("T")[0]);
              item.Display_Plan_End_Date = formatDate(item.Plan_End_Date?.split("T")[0]);
              return (
                <>
                  <tr key={index} className="table-row">
                    <td>
                      {/* Show the dropdown button only if Member_Seq is "01" */}
                      {item.Member_Seq === "01" &&
                        data2.some(
                          (filteredItem) =>
                            filteredItem.Subscriber_ID === item.Subscriber_ID &&
                            filteredItem.Plan_ID === item.Plan_ID &&
                            filteredItem.Start_Date === item.Start_Date
                        ) && (
                          <span onClick={() => toggleExpandRow(index)} className="large-iconData">
                            {expandedRows.has(index) ? (
                              <FontAwesomeIcon icon={faCaretDown} />
                            ) : (
                              <FontAwesomeIcon icon={faCaretRight} />
                            )}
                          </span>
                        )}
                    </td>
                    {Object.keys(fieldsToDisplay).map((key) => (
                      <td key={key} className="table-cell">
                        {item[fieldsToDisplay[key as keyof typeof fieldsToDisplay]]}
                      </td>
                    ))}
                    <td>
                      <Tooltip title="Edit this item">
                        <button className="hover-buttonDataComp" onClick={() => handleEdit(item)}>
                          <FontAwesomeIcon icon={faEdit} />
                        </button>
                      </Tooltip>
                      <Tooltip title="Order an ID card for this item">
                        <button className="hover-buttonDataComp" onClick={() => handleOrderCard(item)}>
                          <FontAwesomeIcon icon={faIdCard} />
                        </button>
                      </Tooltip>
                      <Tooltip title="Terminate this item">
                        <button className="hover-buttonDataComp" onClick={() => handleTerminate2(item)}>
                          <FontAwesomeIcon icon={faUserTimes} />
                        </button>
                      </Tooltip>
                      <Tooltip title="Show coverage details for this item">
                        <button className="hover-buttonDataComp" onClick={() => handleShowCoverage(item)}>
                          <FontAwesomeIcon icon={faEye} />
                        </button>
                      </Tooltip>
                      <Tooltip title="Add a dependent for this item">
                        <button className="hover-buttonDataComp" onClick={() => handleAddDependent(item)}>
                          <FontAwesomeIcon icon={faUserPlus} />
                        </button>
                      </Tooltip>
                    </td>
                  </tr>
                  {expandedRows.has(index) && (
                    <>
                      {data2
                        .filter(
                          (filteredItem) =>
                            filteredItem.Subscriber_ID === item.Subscriber_ID &&
                            filteredItem.Subscriber_Last_Name === item.Subscriber_Last_Name
                        )
                        .map((filteredItem, filteredIndex) => {
                          filteredItem.Display_Group_Coverage_Start_Date = formatDate(
                            filteredItem.Group_Coverage_Start_Date?.split("T")[0]
                          );
                          filteredItem.Display_End_Date = formatDate(filteredItem.End_Date?.split("T")[0]);
                          filteredItem.Display_Plan_Start_Date = formatDate(
                            filteredItem.Plan_Start_Date?.split("T")[0]
                          );
                          filteredItem.Display_Plan_End_Date = formatDate(filteredItem.Plan_End_Date?.split("T")[0]);
                          return (
                            <tr key={"expanded-" + filteredIndex}>
                              <td>Dependent</td> {/* This could be left empty or contain some icon/label */}
                              {Object.keys(fieldsToDisplay).map((key) => (
                                <td key={"expanded-" + key}>
                                  {filteredItem[fieldsToDisplay[key as keyof typeof fieldsToDisplay]]}
                                </td>
                              ))}
                              <td>
                                {/* This could be left empty or contain some other action buttons */}
                                <Tooltip title="Edit this item">
                                  <button className="hover-buttonDataComp" onClick={() => handleEdit(filteredItem)}>
                                    <FontAwesomeIcon icon={faEdit} />
                                  </button>
                                </Tooltip>
                                <Tooltip title="Order an ID card for this item">
                                  <button
                                    className="hover-buttonDataComp"
                                    onClick={() => handleOrderCard(filteredItem)}
                                  >
                                    <FontAwesomeIcon icon={faIdCard} />
                                  </button>
                                </Tooltip>
                                {/* <Tooltip title="Terminate this item">
                                  <button
                                    className="hover-buttonDataComp"
                                    onClick={() => handleTerminate2(filteredItem)}
                                  >
                                    <FontAwesomeIcon icon={faUserTimes} />
                                  </button>
                                </Tooltip> */}
                                <Tooltip title="Show coverage details for this item">
                                  <button
                                    className="hover-buttonDataComp"
                                    onClick={() => handleShowCoverage(filteredItem)}
                                  >
                                    <FontAwesomeIcon icon={faEye} />
                                  </button>
                                </Tooltip>
                              </td>
                            </tr>
                          );
                        })}
                    </>
                  )}
                </>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }

  return (
    <div>
      <div className="header">
        <h1>Employee Maintenance</h1>
        <p>NOTE: Your changes will reflect 24 hours after submission.</p>
      </div>

      <div className="tabsContainer">
        <div className="tabGroup">
          <Box className="tabsContainer2">
            {/* Replace this part with Material UI Tabs */}
            <Tabs value={currentTab} onChange={handleChangeTabs} aria-label="employee status tabs" className="myTabs">
              <Tab value="active" label="Active Employees" />
              <Tab value="terminated" label="Terminated Employees" />
            </Tabs>

            {/* ... rest of your code for the TextField, Expand all Rows, and Pagination */}
          </Box>
        </div>

        <div className="tabs">
          <div>
            <TextField
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
                if (e.target.value && e.target.value != "") {
                  setExpandAll(true);
                } else {
                  setExpandAll(false);
                }
              }}
              placeholder="Search employees"
              sx={{ input: { background: "white" } }}
            />
          </div>
          <div className={`tab ${expandAll ? "active" : ""}`} onClick={toggleExpandAll}>
            Expand all Rows
          </div>

          <div>
            <Pagination count={totalPages} onChange={handlePagination} />
          </div>
        </div>
      </div>

      {currentTab === "active" && <ActiveEmployeesTable />}
      {currentTab === "terminated" && <TerminatedEmployeesTable />}

      {addDependentModal && (
        <Modal isOpen={addDependentModal} onRequestClose={handleAddDependentClose} style={modalStyles2}>
          <h2>Add Dependent</h2>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSaveDependent();
            }}
          >
            <label>
              Reason for Enrollment:
              <select value={enrollmentReason} onChange={(e) => setEnrollmentReason(e.target.value)}>
                <option value="openEnrollment">Open Enrollment</option>
                <option value="birth">Birth</option>
                <option value="marriage">Marriage</option>
                <option value="lossOfCoverage">Loss of Coverage</option>
              </select>
            </label>

            <label>
              Gender:
              <select value={gender} onChange={(e) => setGender(e.target.value)}>
                <option value="M">Male</option>
                <option value="F">Female</option>
              </select>
            </label>

            <label>
              Relationship:
              <select value={relationship} onChange={(e) => setRelationship(e.target.value)}>
                <option value="02">Spouse</option>
                <option value="19">Child</option>
                <option value="28">Other</option>
                <option value="28">Domestic Partner</option>
              </select>
            </label>

            <label>
              Coverage Date:
              <input type="date" value={coverageDate} onChange={(e) => setCoverageDate(e.target.value)} />
            </label>

            <label>
              SSN:
              <input type="text" value={dependentSSN} onChange={handleSSNChange} placeholder="Enter SSN" />
            </label>

            <label>
              Date of Birth:
              <input type="date" value={dependentDOB} onChange={(e) => setDependentDOB(e.target.value)} />
            </label>

            <label>
              First Name:
              <input type="text" value={dependentFirstName} onChange={(e) => setDependentFirstName(e.target.value)} />
            </label>

            <label>
              Last Name:
              <input type="text" value={dependentLastName} onChange={(e) => setDependentLastName(e.target.value)} />
            </label>

            <button type="submit">Add Dependent</button>
            <button type="button" onClick={handleAddDependentClose}>
              Cancel
            </button>
          </form>
        </Modal>
      )}
      {showCoverageModal && (
        <Modal isOpen={showCoverageModal} onRequestClose={handleShowCoverageClose} style={modalStyles}>
          <div>
            <h2>Plan Coverage</h2>
            <p>Plan Name: {selectedMember?.Plan_Name || "N/A"}</p>
            <ul>
              Subscriber Deductible:{" "}
              {selectedMember?.Subscriber_Deductible ? formatCurrency(selectedMember.Subscriber_Deductible) : "N/A"}
            </ul>
            <ul>
              Member Deductible:{" "}
              {selectedMember?.Member_Deductible ? formatCurrency(selectedMember.Member_Deductible) : "N/A"}
            </ul>
            <ul>
              OOP Max Net Subscriber:{" "}
              {selectedMember?.OOP_Max_Net_Sub ? formatCurrency(selectedMember.OOP_Max_Net_Sub) : "N/A"}
            </ul>
            <ul>
              OOP Max Net Member:{" "}
              {selectedMember?.OOP_Max_Net_Mem ? formatCurrency(selectedMember.OOP_Max_Net_Mem) : "N/A"}
            </ul>
            <div>
              <h3>Plan Benefit Summary:</h3>
              <ul>
                {selectedMember?.Benefit_Description ? (
                  selectedMember.Benefit_Description.split(";").map((benefit, index) => (
                    <li key={index}>{benefit || "N/A"}</li>
                  ))
                ) : (
                  <li>N/A</li>
                )}
              </ul>
            </div>
            <button type="button" onClick={handleShowCoverageClose}>
              Close
            </button>
          </div>
        </Modal>
      )}

      {showTerminateForm && (
        <Modal isOpen={showTerminateForm} onRequestClose={() => setShowTerminateForm(false)} style={modalStyles3}>
          <h2>Terminate Membership</h2>

          <div>
            <h3>Selected Member and Dependents</h3>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Terminate</TableCell>
                    <TableCell>Member ID</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Relationship</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* Display the selected member */}
                  {selectedMember &&
                    selectedMember.Member_Seq === "01" &&
                    (console.log("selected member in cells", selectedMember),
                    (
                      <TableRow>
                        <TableCell>
                          <Checkbox
                            checked={selectedForTermination.has(selectedMember.Subscriber_ID)}
                            onChange={() => handleSelectForTermination(selectedMember.Subscriber_ID, selectedMember)}
                          />
                        </TableCell>
                        <TableCell>{selectedMember.Subscriber_ID}</TableCell>
                        <TableCell>
                          {selectedMember.First_Name} {selectedMember.Last_Name}
                        </TableCell>
                        <TableCell>Subscriber</TableCell>
                      </TableRow>
                    ))}
                  {/* Map over the dependents of the selected member and display them */}
                  {data2
                    .filter(
                      (dependent) =>
                        dependent.Subscriber_ID === selectedMember?.Subscriber_ID &&
                        dependent.Plan_ID === selectedMember?.Plan_ID &&
                        dependent.Start_Date === selectedMember?.Start_Date
                    )
                    .map((dependent, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <Checkbox
                            checked={selectedForTermination.has(`${dependent.Subscriber_ID}-${dependent.Member_Seq}`)}
                            onChange={() =>
                              handleSelectForTermination(
                                `${dependent.Subscriber_ID}-${dependent.Member_Seq}`,
                                dependent
                              )
                            }
                          />
                        </TableCell>
                        <TableCell>{`${dependent.Subscriber_ID}-${dependent.Member_Seq}`}</TableCell>
                        <TableCell>
                          {dependent.First_Name} {dependent.Last_Name}
                        </TableCell>
                        <TableCell>Dependent</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
              {errorAlert && <Alert severity="error">{errorText}</Alert>}
            </TableContainer>
          </div>

          <div style={{ padding: "20px", maxWidth: "500px", margin: "auto" }}>
            <div style={{ marginBottom: "20px" }}>
              <div style={{ fontWeight: "bold", marginBottom: "10px" }}>Terminate Date:</div>
              <Tooltip title="Please Click On Calender Icon on the right to use Date Picker">
                <input
                  ref={terminateDateRef}
                  type="date"
                  value={terminateDate}
                  min={formatDateToYYYYMMDD(sixtyDaysPrior)}
                  onChange={handleTerminateDateChange}
                  // onFocus={handleInputFocus}
                  // onBlur={handleInputBlur}
                  // readOnly={!isInputInteractive}
                  // onMouseOver={handleMakeInteractive}
                  // onMouseOut={handleMakeReadOnly}
                  // onKeyDown={(e) => e.preventDefault()}
                  style={{ width: "100%", padding: "10px", borderRadius: "5px", border: "1px solid #ccc" }}
                />
              </Tooltip>
              <span id="dateError" style={{ color: "red", display: "block", marginTop: "5px" }}></span>
            </div>

            <div style={{ marginBottom: "20px", fontSize: "14px", color: "#666" }}>
              Earliest possible Termination date is less than 60 days before the current date
            </div>

            {errorMessage && (
              <div
                style={{
                  color: "red",
                  padding: "10px",
                  border: "1px solid red",
                  borderRadius: "5px",
                  marginBottom: "20px",
                }}
              >
                {errorMessage}
              </div>
            )}

            <button
              type="button"
              onClick={handleClearDate}
              style={{
                flexGrow: 1,
                padding: "10px",
                borderRadius: "5px",
                border: "none",
                backgroundColor: "#084c9c",
                color: "white",
                cursor: "pointer",
              }}
            >
              Clear Date
            </button>

            <div style={{ display: "flex", justifyContent: "space-between", gap: "10px" }}>
              <button
                type="button"
                onClick={handleTerminateSubmit}
                style={{
                  flexGrow: 1,
                  padding: "10px",
                  borderRadius: "5px",
                  border: "none",
                  backgroundColor: "#084c9c",
                  color: "white",
                  cursor: "pointer",
                }}
              >
                Confirm Termination
              </button>
              <button
                type="button"
                onClick={() => setShowTerminateForm(false)}
                style={{
                  flexGrow: 1,
                  padding: "10px",
                  borderRadius: "5px",
                  border: "1px solid #ccc",
                  backgroundColor: "white",
                  color: "#333",
                  cursor: "pointer",
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal>
      )}

      {showOrderCardForm && (
        <Modal
          isOpen={showOrderCardForm}
          onRequestClose={() => setShowOrderCardForm(false)}
          style={customStyles} // Add custom styles
        >
          <div className="modal-container">
            {" "}
            {/* Add a container for better styling */}
            <h2>Order Card</h2>
            <form onSubmit={handleOrderCardSubmit} className="order-card-form">
              {" "}
              {/* Add a class for styling */}
              <div className="form-group">
                <label>
                  Member: {selectedMember?.First_Name} {selectedMember?.Last_Name}
                </label>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <label style={{ marginRight: "8px" }}>Need Temporary Card?</label>
                <input
                  type="checkbox"
                  checked={tempCardNeeded}
                  onChange={handleTempCardChange}
                  style={{ marginTop: "-1px" }}
                />
              </div>
              {tempCardNeeded && (
                <div>
                  <div className="form-actions">
                    <button type="button" className="btn-confirm" onClick={downloadPDF}>
                      Download Temp Card
                    </button>
                  </div>
                </div>
              )}
              <div className="form-actions">
                <button type="submit" className="btn-confirm">
                  Confirm Order
                </button>
                <button type="button" className="btn-cancel" onClick={() => setShowOrderCardForm(false)}>
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </Modal>
      )}

      <Modal
        isOpen={showEditForm}
        onRequestClose={closeModal}
        contentLabel="Edit Member Modal"
        className="modal-content3" // Add this line
      >
        <h2>Edit Member</h2>
        <form onSubmit={handleSaveEdit}>
          <label>
            Last Name:
            <input type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} />
          </label>
          <label>
            Address One:
            <input type="text" value={addressOne} onChange={(e) => setAddressOne(e.target.value)} />
          </label>
          <label>
            City:
            <input type="text" value={city} onChange={(e) => setCity(e.target.value)} />
          </label>
          <label>
            State:
            <input type="text" value={state} onChange={(e) => setState(e.target.value)} />
          </label>
          <label>
            Zip Code:
            <input type="text" value={zipCode} onChange={(e) => setZipCode(e.target.value)} />
          </label>
          <br />
          <button type="submit">Save Changes</button>
          <button onClick={closeModal}>Close</button>
        </form>
      </Modal>
    </div>
  );
};

export default VBAApiDataComponent;
