import React, { useState, useEffect, useContext } from "react";
import "./component.css";
import { AuthContext } from "../../authProvider/authProvider";
import { Navigate } from "react-router-dom";

import { getAuth, signOut } from "firebase/auth";
import { doc, getDoc, getFirestore, DocumentData } from "firebase/firestore";
import { app } from "../../../config";
import Modal from "react-modal";
import { Pagination } from "@mui/material";
import { TextField } from "@mui/material";
import Box from "@mui/material/Box";

const auth = getAuth();
const db = getFirestore(app);

export type SortedVBADataType = {
  [key: string]: VBADataType[];
};

export type VBADataType = {
  group_name: string;
  group_number: string;
  first_name: string;
  last_name: string;
  dob: string; // Date of Birth
  subscriber_id: string;
};

type ApiDataComponentProps = {
  data: VBADataType[];
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  search: string | null;
  setSearch: Function;
  totalPages: number;
  handlePagination: (event: React.ChangeEvent<unknown>, page: number) => void;
};

declare module "jspdf" {
  interface jsPDF {
    setLineDash(pattern: number[], phase?: number): void;
  }
}

Modal.setAppElement("#root");

const OptiEnhanceTable: React.FC<ApiDataComponentProps> = ({
  data,
  setIsLoading,
  search,
  setSearch,
  totalPages,
  handlePagination,
}) => {
  const authContext = useContext(AuthContext);
  if (!authContext) {
    throw new Error("AuthContext is undefined");
  }
  const { currentUser } = authContext;

  var tk: string;
  currentUser
    ?.getIdToken(false)
    .then((idToken: string) => {
      tk = idToken;
    })
    .catch((error: any) => {
      console.log(error);
    });

  const [docData, setDocData] = useState<DocumentData | null>(null);

  // Fields for the form
  const fieldsToDisplay = {
    First_Name: "firstName",
    Last_Name: "lastName",
    Group_Game: "GroupName",
    Group_Number: "groupId",
    Member_ID: "memberId",
  };

  useEffect(() => {
    async function getDocument() {
      if (currentUser == null) {
        return <Navigate to="/login" />;
      }

      let docRef = doc(db, "user-info", currentUser.uid);

      try {
        let docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setDocData(docSnap.data());
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.log("Error getting document:", error);
      }
    }

    getDocument();
  }, []);

  if (docData?.Suspended == true) {
    signOut(auth)
      .then(() => {
        console.log("Signed out");
        return <Navigate to="/login" />;
      })
      .catch((error) => {
        console.error("Error signing out", error);
      });
  }

  // Helper function to draw a dotted line

  function ActiveEmployeesTable() {
    // ... Your current table code here ...

    if (data.length === 0) {
      return (
        <div>
          <table>
            <thead>
              <tr>
                <th>Dependents</th>
                {Object.keys(fieldsToDisplay).map((key) => (
                  <th key={key}>{key.replace(/_/g, " ")}</th>
                ))}
                <th>Actions</th>
              </tr>
            </thead>
          </table>
          <div className="no-results-box">No search results found</div>
        </div>
      );
    }

    return (
      <table>
        <thead>
          <tr>
            {Object.keys(fieldsToDisplay).map((key) => (
              <th key={key}>{key.replace(/_/g, " ")}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => {
            console.log(item);
            return (
              <>
                <tr key={index} className="table-row">
                  {Object.keys(fieldsToDisplay).map((key) => (
                    <td key={key} className="table-cell">
                      {item[fieldsToDisplay[key as keyof typeof fieldsToDisplay]]}
                    </td>
                  ))}
                </tr>
              </>
            );
          })}
        </tbody>
      </table>
    );
  }

  return (
    <div>
      <div className="header">
        <h1>OptiEnhance Registration</h1>
        {/* <p>NOTE: Your changes will reflect 24 hours after submission.</p> */}
      </div>

      <div className="tabsContainer">
        <div className="tabGroup">
          <Box className="tabsContainer2">
            {/* ... rest of your code for the TextField, Expand all Rows, and Pagination */}
          </Box>
        </div>

        <div className="tabs">
          <div>
            <TextField
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              placeholder="Search employees"
              sx={{ input: { background: "white" } }}
            />
          </div>

          <div>
            <Pagination count={totalPages} onChange={handlePagination} />
          </div>
        </div>
      </div>
      <ActiveEmployeesTable />
    </div>
  );
};

export default OptiEnhanceTable;
