import React, { useState, useEffect, useContext } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { AuthContext } from "../authProvider/authProvider";
import { getAuth, signOut } from "firebase/auth";
import { doc, getDoc, getFirestore, DocumentData } from "firebase/firestore";
import { app, host } from "../../config";
import "./empMaintain.css";
import VBAApiDataComponent, { SortedVBADataType, VBADataType } from "./VBAApiDataComponent";
import Papa from "papaparse";
import { Pagination } from "@mui/material";
import WLTApiDataComponent, { SortedWLTDataType, WLTDataType } from "./WLTApiDataComponent";
import CircularProgress from "@mui/material/CircularProgress";
import CustomAlert from "components/alert/CustomAlert";
import { Typography } from "@mui/material";

const auth = getAuth();
const db = getFirestore(app);

const EmpMaintenance: React.FC = () => {
  const [groupNumber, setGroupNumber] = useState("");
  const [docData, setDocData] = useState<DocumentData | null>(null);
  const [VBAApiData, setVBAApiData] = useState<VBADataType[]>([]);
  const [WLTApiData, setWLTApiData] = useState<WLTDataType[]>([]);
  const [flattenedVBAApiData, setFlattenedVBAApiData] = useState<VBADataType[]>([]);
  const [flattenedWLTApiData, setFlattenedWLTApiData] = useState<WLTDataType[]>([]);
  const [showVBAApiData, setShowVBAApiData] = useState(false);
  const [showWLTApiData, setShowWLTApiData] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [search, setSearch] = useState<string | null>(null);

  const [showTerminated, setShowTerminated] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [isAlertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState<string>("");

  const openAlert = () => {
    setAlertOpen(true);
  };

  const closeAlert = () => {
    setAlertOpen(false);
  };

  const authContext = useContext(AuthContext);
  if (!authContext) {
    throw new Error("AuthContext is undefined");
  }
  const { currentUser, selectedGroup, role } = authContext;

  const navigate = useNavigate();

  useEffect(() => {
    if (WLTApiData?.length > 0) {
      const searchTerms = (search || "").trim().toLowerCase().split(/\s+/); // Split on one or more spaces and ensure each term is treated as a string
      const sorted = {};
      let filteredApiData =
        searchTerms.length > 0
          ? WLTApiData.filter(
              (obj) =>
                searchTerms.every(
                  (term) => obj.firstname.toLowerCase().includes(term) || obj.lastname.toLowerCase().includes(term)
                ) ||
                (obj.depno === "0" &&
                  WLTApiData.some(
                    (nested_obj) =>
                      obj.empno === nested_obj.empno &&
                      searchTerms.some(
                        (term) =>
                          nested_obj.firstname.toLowerCase().includes(term) ||
                          nested_obj.lastname.toLowerCase().includes(term)
                      )
                  ))
            )
          : WLTApiData;

      // Find empnos for objects that are considered primary (depno == 0).
      const primaryEmpnos = new Set(filteredApiData.filter((obj) => obj.depno === "0").map((obj) => obj.empno));

      // Use the primaryEmpnos to filter WLTApiData to find objects with matching empno.
      const objectsWithMatchingEmpnos = WLTApiData.filter((obj) => primaryEmpnos.has(obj.empno));

      // Function to check if an object is a duplicate based on specific properties
      const isDuplicate = (object, collection) =>
        collection.some((item) => item.empno === object.empno && item.depno === object.depno);

      // Combine filteredApiData with objectsWithMatchingEmpnos without duplicates
      const combinedData = [...filteredApiData];
      objectsWithMatchingEmpnos.forEach((object) => {
        if (!isDuplicate(object, combinedData)) {
          combinedData.push(object);
        }
      });

      // Group data by empno
      combinedData.reduce((acc, currentValue) => {
        let groupKey = currentValue["empno"];
        if (!acc[groupKey]) {
          acc[groupKey] = [];
        }
        acc[groupKey].push(currentValue);
        return acc;
      }, sorted);

      let pageCount = Math.ceil(Object.keys(sorted).length / pageSize);
      setTotalPages(pageCount);
      let arr = [];
      Object.keys(sorted)
        .slice((page - 1) * pageSize, page * pageSize)
        .map((key) => {
          arr = arr.concat(sorted[key]);
        });

      setFlattenedWLTApiData(arr);
    } else {
      setFlattenedWLTApiData(WLTApiData);
    }
  }, [WLTApiData, search, page]);

  useEffect(() => {
    console.log("VBAApiData");
    console.log(VBAApiData);
    const searchTerms = (search || "").trim().toLowerCase().split(/\s+/); // Split on one or more spaces and ensure each term is treated as a string

    if (VBAApiData?.length > 0) {
      let filteredApiData =
        searchTerms.length > 0
          ? VBAApiData.filter(
              (obj) =>
                searchTerms.every(
                  (term) => obj.First_Name?.toLowerCase().includes(term) || obj.Last_Name?.toLowerCase().includes(term)
                ) ||
                (obj.Member_Seq == "01" &&
                  VBAApiData.some(
                    (nested_obj) =>
                      obj.Subscriber_ID == nested_obj.Subscriber_ID &&
                      searchTerms.some(
                        (term) =>
                          nested_obj.First_Name?.toLowerCase().includes(term) ||
                          nested_obj.Last_Name?.toLowerCase().includes(term)
                      )
                  ))
            )
          : VBAApiData;

      const subscriberIdsWithMemberSeq01 = new Set(
        filteredApiData.filter((obj) => obj.Member_Seq === "01").map((obj) => obj.Subscriber_ID)
      );

      const objectsWithMatchingSubscriberIDs = VBAApiData.filter((obj) =>
        subscriberIdsWithMemberSeq01.has(obj.Subscriber_ID)
      );

      console.log("Objects with matching Subscriber_IDs:", objectsWithMatchingSubscriberIDs);

      console.log("this is filtered api data");
      console.log(filteredApiData);

      const isDuplicate = (object, collection) =>
        collection.some((item) => item.Subscriber_ID === object.Subscriber_ID && item.Member_Seq === object.Member_Seq);

      const combinedData = [...filteredApiData];
      objectsWithMatchingSubscriberIDs.forEach((object) => {
        if (!isDuplicate(object, combinedData)) {
          combinedData.push(object);
        }
      });

      console.log("Combined data without duplicates:", combinedData);

      let sorted = {}; // Define 'sorted' as an empty object before using it
      combinedData.reduce((acc, currentValue) => {
        let groupKey =
          currentValue["Subscriber_ID"] +
          currentValue["Plan_ID"] +
          currentValue["Start_Date"] +
          currentValue["Plan_End_Date"];
        if (!acc[groupKey]) {
          acc[groupKey] = [];
        }
        acc[groupKey].push(currentValue);
        return acc;
      }, sorted);

      let arr = [];
      let pageCount = Math.ceil(Object.keys(sorted).length / pageSize);
      setTotalPages(pageCount);
      Object.keys(sorted)
        .slice((page - 1) * pageSize, page * pageSize)
        .map((key) => {
          arr = arr.concat(sorted[key]);
        });

      console.log("arr");
      console.log(arr);
      setFlattenedVBAApiData(arr);
    } else {
      setFlattenedVBAApiData(VBAApiData);
    }
  }, [VBAApiData, search, page]);

  function getData(params: URLSearchParams, refresh:boolean) {
    setIsLoading(true);
    console.log("get data hit");

    console.log(params.toString());

    fetch(`${host}/api/enrollments/census?${params}`, {
      method: "GET",
      headers: {
        authorization: `Bearer ${tk}`,
      },
    })
      .then((response: Response) => {
        setIsLoading(false);
        if (response.status === 200) {
          response.json().then((res) => {
            if (res.vba == true) {
              if (res.data.length == 0) {
                setAlertMessage("No data found for this group number.");
                setAlertOpen(true);
                setVBAApiData(res.data);
              } else {
                if (refresh) {
                  setAlertOpen(true)
                }
                setVBAApiData(res.data);
                setShowVBAApiData(true);
              }
            } else {
              if (res.data.length == 0) {
                setAlertMessage("No data found for this group number.");
                setAlertOpen(true);
                setWLTApiData(res.data);
              } else {
                if (refresh) {
                  setAlertOpen(true)
                }
                setWLTApiData(res.data);
                setShowWLTApiData(true);
              }
            }
            setIsLoading(false);
          });
        } else {
          setIsLoading(false);
          setAlertMessage(`Error retrieving data. Please try again later. ${response.status}`);
          setAlertOpen(true);
          clearParameters();
          setShowWLTApiData(false);
          setShowVBAApiData(false);
        }
      })
      .catch((error: any) => {
        console.log(error);
        console.log("check error in emp maintenance");
        setIsLoading(false);
        setAlertMessage("Error retrieving data. Please try again later.");
        setAlertOpen(true);
        clearParameters();
        setShowWLTApiData(false);
        setShowVBAApiData(false);
        console.log("checking state of alert");
        console.log(isAlertOpen);
      })
      .finally(() => {
        // Code to execute in the finally block
        // This will run regardless of whether an error occurred or not
        setIsLoading(false);
      });
  }

  const downloadCSV = () => {
    let params = new URLSearchParams();
    params.append("groupNo", groupNumber);
    fetch(`${host}/api/enrollments/censusDownload?` + params, {
      method: "GET",
      headers: {
        authorization: `Bearer ${tk}`,
      },
    })
      .then(async (response: Response) => {
        if (response.status === 200) {
          setIsLoading(false);
          response.json().then((res) => {
            let dataToDownload: any[] = res.data;

            const csv = Papa.unparse(dataToDownload);
            const csvData = new Blob([csv], { type: "text/csv" });
            const csvUrl = URL.createObjectURL(csvData);
            let hiddenElement = document.createElement("a");
            hiddenElement.href = csvUrl;
            hiddenElement.download = "employee_census.csv";
            hiddenElement.click();
          });
        } else {
          // alert(
          //   `An error occurred while uploading the documents. Status: ${data.status}`
          // );
          setIsLoading(false);

          setAlertMessage(`An error occurred while processing your request. Please try again.`);
          setAlertOpen(true);
        }
      })
      .catch((error: any) => {
        console.log(error);
        setIsLoading(false);
        setAlertMessage("An error occurred while processing your request. Please try again.");
        setAlertOpen(true);
      })
      .finally(() => {
        setIsLoading(false);
        console.log("Processing finished");
      });
  };

  useEffect(() => {
    async function getDocument() {
      if (currentUser == null) {
        return <Navigate to="/login" />;
      }

      let docRef = doc(db, "user-info", currentUser.uid);

      try {
        let docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setDocData(docSnap.data());
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.log("Error getting document:", error);
      }
    }

    getDocument();
  }, []);

  useEffect(() => {
    console.log("Effect ran due to change in isAlertOpen or alertMessage", isAlertOpen, alertMessage);
    // rest of your effect logic
  }, [isAlertOpen, alertMessage]);

  var tk: string;
  useEffect(() => {
    if (role === "broker" || role === "employer") {
      setGroupNumber(selectedGroup);
      currentUser
        ?.getIdToken(false)
        .then((idToken: string) => {
          tk = idToken;
          let params = new URLSearchParams();
          params.append("groupNo", selectedGroup);
          params.append("pageSize", pageSize.toString());
          params.append("terminated", showTerminated.toString());
          getData(params, false);
          console.log("submit hit");
        })
        .catch((error: any) => {
          console.log(error);
        });
    }
  }, [selectedGroup]);

  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  currentUser
    ?.getIdToken(false)
    .then((idToken: string) => {
      tk = idToken;
    })
    .catch((error: any) => {
      console.log(error);
    });

  if (docData?.Suspended == true) {
    signOut(auth)
      .then(() => {
        console.log("Signed out");
        return <Navigate to="/login" />;
      })
      .catch((error) => {
        console.error("Error signing out", error);
      });
  }

  const goToEmploymentEnrollment = () => {
    navigate("/enroll");
  };

  const handleGroupNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGroupNumber(e.target.value);
  };

  // const sampleData2: VBADataType = {
  //   Subscriber_ID: "123456789",
  //   Member_Seq: "01",
  //   Alternate_ID: "ALT12345",
  //   First_Name: "John",
  //   Last_Name: "Doe",
  //   Name_Suffix: "Jr",
  //   Las_4_Of_SSN: "6789",
  //   City: "Anytown",
  //   State: "NY",
  //   Zip_Code: "12345",
  //   Birth_Date: "1980-05-15",
  //   Disenroll_Date: "2023-12-31",
  //   Group_ID: "GRP123",
  //   Premium_Proration: false,
  //   Division_ID: "DIV678",
  //   Plan_ID: "P123456",
  //   Subscriber_Deductible: "",
  //   Member_Deductible: "150.00",
  //   OOP_Max_Net_Sub: "3000.00",
  //   OOP_Max_Net_Mem: "2000.00",
  //   Plan_Name: "Basic Health Plan",
  //   Start_Date: "2023-01-01",
  //   End_Date: "2023-12-31",
  //   Address: "123 Main St",
  //   Address2: "Apt 4B",
  //   VIP_Flag: "N",
  //   Subscriber_First_Name: "John",
  //   Subscriber_Last_Name: "Doe",
  //   Date_Enrolled: "2022-01-01",
  //   Group_Coverage_Start: "Yes",
  //   Group_Coverage_Start_Date: "2022-01-01",
  //   Plan_Start: "Yes",
  //   Benefit_Description: "Full medical coverage including dental and vision",
  //   Plan_Start_Date: "2023-01-01",
  //   Plan_End_Date: "2023-12-31",
  //   extra_key_1: "Extra value 1",
  //   extra_key_2: true,
  // };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (groupNumber === "") {
      alert("Please fill the Group Number field before submitting!");
      return;
    }

    let params = new URLSearchParams();
    params.append("groupNo", groupNumber);
    params.append("pageSize", pageSize.toString());
    params.append("terminated", showTerminated.toString());
    console.log("submit hit");

    setIsLoading(true);
    console.log("loading checker");

    getData(params, false);
  };

  const handlePagination = (e: any, p: number) => {
    // e.preventDefault();

    setPage(p);
  };

  const clearParameters = () => {
    setGroupNumber(""); // Reset to initial state or any other default you want
    setPageSize(10); // Reset to default
    setShowTerminated(false); // Reset to default
    // Note: No need to clear isLoading here since it will be handled by fetchData
  };

  const handleSetSearch = (str: string) => {
    setSearch(str);
    setPage(1);
  };

  const refreshPage = () => {
    setIsLoading(true);
    let params = new URLSearchParams();
    params.append("groupNo", groupNumber);
    params.append("pageSize", pageSize.toString());
    params.append("page", page.toString());
    params.append("terminated", showTerminated.toString());
    getData(params, true);
  };

  const fetchTerminatedEmployees = (newState: boolean) => {
    setShowTerminated(newState);

    let params = new URLSearchParams();
    params.append("groupNo", groupNumber);
    params.append("pageSize", pageSize.toString());
    params.append("page", "1");
    params.append("terminated", newState.toString());

    setIsLoading(true);
    setPage(1);

    getData(params, false);
  };

  const goBack = () => {
    if (role == "admin" || role == "internal_ugp") {
      setShowVBAApiData(false);
      setShowWLTApiData(false);
    } else {
      navigate("/basePage");
    }
    clearParameters();
  };

  if (isLoading) {
    return (
      <div className="loader-container">
        <CircularProgress />
      </div>
    );
  }
  if (showVBAApiData) {
    return (
      <div className="App">
        <CustomAlert message={alertMessage} isOpen={isAlertOpen} onClose={closeAlert} />
        <button className="back-button" onClick={goBack}>
          Back
        </button>
        <div className="centerDiv3">
          <button onClick={downloadCSV} className="navigateButton2">
            Download Employee Census
          </button>
          <button onClick={goToEmploymentEnrollment} className="navigateButton">
            Add an Employee
          </button>
        </div>
        {/* <Pagination count={totalPages} onChange={handlePagination} /> */}
        <VBAApiDataComponent
          data={flattenedVBAApiData}
          handleChange={refreshPage}
          handleTerminationChange={fetchTerminatedEmployees}
          setAlertMessage={setAlertMessage}
          setIsLoading={setIsLoading}
          showTerminated={showTerminated}
          search={search}
          setSearch={handleSetSearch}
          totalPages={totalPages}
          handlePagination={handlePagination}
        />
        <div className="paginationHolder">
          <Pagination count={totalPages} onChange={handlePagination} />
        </div>
        <div className="centerDiv2">
          <button onClick={downloadCSV} className="navigateButton2">
            Download Employee Census
          </button>
          <button onClick={goToEmploymentEnrollment} className="navigateButton">
            Add an Employee
          </button>
        </div>
      </div>
    );
  } else if (showWLTApiData) {
    if (isLoading) {
      return (
        <div className="loader-container">
          <CircularProgress />
        </div>
      );
    }
    return (
      <div className="App">
        <CustomAlert message={alertMessage} isOpen={isAlertOpen} onClose={closeAlert} />
        <button className="back-button" onClick={goBack}>
          Back
        </button>
        <WLTApiDataComponent
          data={flattenedWLTApiData}
          handleChange={refreshPage}
          handleTerminationChange={fetchTerminatedEmployees}
          setIsLoading={setIsLoading}
          setAlertMessage={setAlertMessage}
          showTerminated={showTerminated}
          search={search}
          setSearch={handleSetSearch}
          totalPages={totalPages}
          handlePagination={handlePagination}
        />
        <Pagination count={totalPages} onChange={handlePagination} />
        <div className="centerDiv2">
          <button onClick={downloadCSV} className="navigateButton2">
            Download Employee Census
          </button>
          <button onClick={goToEmploymentEnrollment} className="navigateButton">
            Add an Employee
          </button>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <div className="headerEmpMain">
          <h1>Employee Maintenance</h1>
          <p>NOTE: Your changes will reflect 24 hours after submission.</p>
        </div>

        <CustomAlert message={alertMessage} isOpen={isAlertOpen} onClose={closeAlert} />

        <form onSubmit={handleSubmit}>
          <label>
            Group Number:
            <input
              type="text"
              value={groupNumber}
              onChange={handleGroupNumberChange}
              placeholder="Enter Group Number"
            />
          </label>
          <Typography variant="body1" color={"textSecondary"} align="center">
            Selected Group: {selectedGroup || groupNumber}
          </Typography>
          <button type="submit">Look up</button>
        </form>
        <div className="centerDiv">
          <button onClick={goToEmploymentEnrollment} className="navigateButton">
            Add an Employee
          </button>
        </div>
      </div>
    );
  }
};

export default EmpMaintenance;
