import React from "react";
import "./PaymentOptions.css";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Box,
  TextField,
  Typography,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import Copyright2 from "components/footerpt2/Copyright2";
import pic from "components/assets/logo-tran.png";

const PaymentOptions: React.FC = () => {
  const openLink = (url: string) => {
    window.open(url, "_blank");
  };

  return (
    <div className="parent-div">
      <div className="payment-container">
        <h2>Select a Payment Method</h2>
        <div className="payment-buttons-container">
          <Tooltip title="You can establish automated ACH payments at no cost by completing our ACH form. Please note  that you will receive your invoices electronically prior to our monthly ACH draft.">
            <button
              className="payment-button"
              onClick={() => openLink("https://optimedhealth.formstack.com/forms/ach_agreement_form")}
            >
              ACH
            </button>
          </Tooltip>
          <Tooltip title="Electronic Checks are one time payments made to us. The Banking fee to process your Echeck is $25">
            <button
              className="payment-button"
              onClick={() =>
                openLink(
                  "https://legacy-portal.seamlesschex.com/?_ga=2.2483696.1924627391.1692889517-1759484669.1692889517#/checkout/8ff12e50-3ad6-11ee-8b44-2bc8f78932d3/978120e0-a719-11ed-9667-b1d2fdb91077/1/0"
                )
              }
            >
              Electronic Check
            </button>
          </Tooltip>
          <Tooltip title="Credit Card payments are one-time payments made to OptiMedHealth. The credit card processing fee is 4.5% of the total amount paid">
            <button
              className="payment-button"
              onClick={() => openLink("https://optimedhealth.formstack.com/forms/creditcard")}
            >
              Credit Card
            </button>
          </Tooltip>

          <Tooltip title="Please note that if you need to mail a payment to us you can sent Premium payments to 'OptiMedHealth, 3145 Avalon Ridge Place, Suite 300, Peachtree Corners, GA 30071'">
            <button className="payment-button">Mail</button>
          </Tooltip>
        </div>
        <br />
        <Typography variant="body2" color="textSecondary">
          OptiMedHealth offers 4 payment options for groups to pay for their Health Plan Premiums. Hover over each
          option and they click on the requested option to complete the necessary information to process your
          payment(s).
        </Typography>
        {/* <div className="copyright">{<Copyright2 />}</div> */}
      </div>
      {/* Moved the Copyright component to the bottom */}
      <div className="copyright-fixed">
        <Copyright2 />
      </div>
    </div>
  );
};

export default PaymentOptions;
// ... Existing code
export {};
