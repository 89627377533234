import "./census.css"; // adjust the path if your CSS file has a different name or location
import React, { useState, useContext, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { AuthContext } from "../authProvider/authProvider";
import { getAuth } from "firebase/auth";
import { host } from "../../config";
import { CSVLink } from "react-csv";
import Papa from "papaparse";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CircularProgress from "@mui/material/CircularProgress";
import { Button } from "reactstrap";
import { ButtonGroup, Grid, TextField } from "@mui/material";
import { Typography } from "@mui/material";
import CustomAlert from "components/alert/CustomAlert";

interface State {
  subID: string;
  memberSequence: string;
  relationship: string;
  groupNumber: string;
  StartMonth: string;
  data: any[];
  zipCode: number;
}

const initialState: State = {
  subID: "",
  memberSequence: "",
  relationship: "",
  groupNumber: "",
  StartMonth: "",
  data: [],
  zipCode: 0,
};

const auth = getAuth();

const Census: React.FC = () => {
  const [state, setState] = useState<State>(initialState);
  const [activeStartMonth, setActiveStartMonth] = useState<Date | null>(null);
  const [activeEndMonth, setActiveEndMonth] = useState<Date | null>(null);
  const [termStartMonth, setTermStartMonth] = useState<Date | null>(null);
  const [termEndMonth, setTermEndMonth] = useState<Date | null>(null);
  const [currentMonth, setCurrentMonth] = useState<boolean | null>();
  const [addsBool, setAddsBool] = useState<boolean | null>(false);
  const [termsBool, setTermsBool] = useState<boolean | null>(false);
  const [groupNo, setGroupNo] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isAlertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState<string>("");

  const openAlert = () => {
    setAlertOpen(true);
  };

  const closeAlert = () => {
    setAlertOpen(false);
  };

  const authContext = useContext(AuthContext);
  useEffect(() => {
    console.log("Effect ran due to change in isAlertOpen or alertMessage", isAlertOpen, alertMessage);
    // rest of your effect logic
  }, [isAlertOpen, alertMessage]);
  if (!authContext) {
    throw new Error("AuthContext is undefined");
  }
  const { currentUser, selectedGroup, role } = authContext;

  const navigate = useNavigate();

  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  var tk: string;
  currentUser
    ?.getIdToken(false)
    .then((idToken: string) => {
      tk = idToken;
    })
    .catch((error: any) => {
      console.log(error);
    });

  const handleGroupNumberChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setGroupNo(event.target.value);
  };

  const handleStartMonthChange = (date: Date | null) => {
    setActiveStartMonth(date);
    console.log(date);
  };

  const handleEndMonthChange = (date: Date | null) => {
    setActiveEndMonth(date);
    console.log(date);
  };

  const handleTermStartMonthChange = (date: Date | null) => {
    setTermStartMonth(date);
  };

  const handleTermEndMonthChange = (date: Date | null) => {
    setTermEndMonth(date);
  };

  const handleAddsClick = () => {
    if (!addsBool) {
      setAddsBool(true);
      setTermsBool(false);
    } else {
      setActiveStartMonth(null);
      setActiveEndMonth(null);
      setAddsBool(false);
    }
  };
  const handleTermsClick = () => {
    if (!termsBool) {
      setTermsBool(true);
      setAddsBool(false);
    } else {
      setTermStartMonth(null);
      setTermEndMonth(null);
      setTermsBool(false);
    }
  };

  const downloadCSV = (data) => {
    const fieldsToDisplay = [
      "Subscriber_ID",
      "Member_Seq",
      "Alternate_ID",
      "First_Name",
      "Last_Name",
      "Name_Suffix",
      "Las_4_Of_SSN",
      "Address",
      "City",
      "State",
      "Zip_Code",
      "Birth_Date",
      "Disenroll_Date",
      "Group_ID",
      "Division_ID",
      "Plan_ID",
      "Plan_Name",
      "Start_Date",
      "End_Date",
      "Address2",
      "VIP_Flag",
      "Subscriber_First_Name",
      "Subscriber_Last_Name",
      "Date_Enrolled",
      "Group_Coverage_Start",
      "Plan_Start",
      "Coverage_Start",
      "Coverage_End",
      "Name",
    ];

    // Filter and reorder the data
    let dataToDownload = data.map((item) => {
      return fieldsToDisplay.reduce((obj, key) => {
        obj[key] = item[key];
        return obj;
      }, {});
    });

    const csv = Papa.unparse(dataToDownload);
    const csvData = new Blob([csv], { type: "text/csv" });
    const csvUrl = URL.createObjectURL(csvData);
    let hiddenElement = document.createElement("a");
    hiddenElement.href = csvUrl;
    hiddenElement.download = "employee_census.csv";
    hiddenElement.click();
  };

  const downloadWltCSV = (data) => {
    const fieldsToDisplay = [
      "group",
      "locno",
      "plan",
      "empno",
      "depno",
      "lastName",
      "firstName",
      "eclass",
      "altID",
      "dob",
      "Medical",
      "Dental",
      "Vision",
      "RX",
      "MEC",
      "effDate",
      "endDate",
      "lastFourOfSSN",
      "addr1",
      "addr2",
      "city",
      "state",
      "zipcode",
    ];

    // Filter and reorder the data
    let dataToDownload = data.map((item) => {
      console.log(item)
      return fieldsToDisplay.reduce((obj, key) => {
        obj[key] = item[key];
        return obj;
      }, {});
    });
    console.log(dataToDownload)

    const csv = Papa.unparse(dataToDownload);
    const csvData = new Blob([csv], { type: "text/csv" });
    const csvUrl = URL.createObjectURL(csvData);
    let hiddenElement = document.createElement("a");
    hiddenElement.href = csvUrl;
    hiddenElement.download = "employee_census.csv";
    hiddenElement.click();
  };


  const handleCurrentMonth = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    let params = new URLSearchParams();
    if (role == "broker" || role == "employer") {
      params.append("groupNo", selectedGroup);
    } else {
      if (!groupNo) {
        alert("Please enter a group number");
        return;
      } else {
        params.append("groupNo", groupNo);
      }
    }

    setIsLoading(true);
    setCurrentMonth(true);
    if (activeStartMonth != null) params.append("activeStartMonth", activeStartMonth.toISOString());
    if (activeEndMonth != null) params.append("activeEndMonth", activeEndMonth.toISOString());
    if (termStartMonth != null) params.append("termStartMonth", termStartMonth.toISOString());
    if (termEndMonth != null) params.append("termEndMonth", termEndMonth.toISOString());
    params.append("currentMonth", "true");

    fetch(`${host}/api/enrollments/censusDownload?` + params, {
      method: "GET",
      headers: {
        authorization: `Bearer ${tk}`,
      },
    })
      .then(async (response: Response) => {
        if (response.status === 200) {
          response.json().then((res) => {
            if (res?.type === "wlt") {
              downloadWltCSV(res.data)
            } else {
              downloadCSV(res.data);
            }
          });
        } else {
          setIsLoading(false);

          setAlertMessage(`Error retrieving data. Please try again later. ${response.status}`);
          setAlertOpen(true);
        }
      })
      .catch((error: any) => {
        console.log(error);
        setIsLoading(false);
        setAlertMessage("An error occurred while processing your request. Please try again.");
        setAlertOpen(true);
      })
      .finally(() => {
        setIsLoading(false);
        console.log("Processing finished");
      });

    resetStates();
    console.log(
      addsBool,
      termsBool,
      groupNo,
      termStartMonth,
      termEndMonth,
      activeStartMonth,
      activeEndMonth,
      currentMonth
    );
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    let params = new URLSearchParams();
    if (role == "broker" || role == "employer") {
      params.append("groupNo", selectedGroup);
    } else {
      if (!groupNo) {
        alert("Please enter a group number");
        return;
      } else {
        params.append("groupNo", groupNo);
      }
    }
    setIsLoading(true);
    if (activeStartMonth != null) params.append("activeStartMonth", activeStartMonth.toISOString());
    if (activeEndMonth != null) params.append("activeEndMonth", activeEndMonth.toISOString());
    if (termStartMonth != null) params.append("termStartMonth", termStartMonth.toISOString());
    if (termEndMonth != null) params.append("termEndMonth", termEndMonth.toISOString());
    if (currentMonth) params.append("currentMonth", "true");

    console.log(params);

    fetch(`${host}/api/enrollments/censusDownload?` + params, {
      method: "GET",
      headers: {
        authorization: `Bearer ${tk}`,
      },
    })
      .then((response: Response) => {
        if (response.status === 200) {
          response.json().then((res) => {
            if (res?.type === "wlt") {
              downloadWltCSV(res.data)
            } else {
              downloadCSV(res.data);
            }
          });
        } else {
          setIsLoading(false);

          setAlertMessage(`An error occurred while processing your request. Please try again. ${response.status}`);
          setAlertOpen(true);
        }
      })
      .catch((error: any) => {
        console.log(error);
        setAlertMessage("An error occurred while processing your request. Please try again.");
        setAlertOpen(true);
      })
      .finally(() => {
        setIsLoading(false);
        console.log("Processing finished");
      });

    resetStates();
  };

  const resetStates = () => {
    setAddsBool(false);
    setTermsBool(false);
    setTermStartMonth(null);
    setTermEndMonth(null);
    setActiveStartMonth(null);
    setActiveEndMonth(null);
    setCurrentMonth(null);
    //setGroupNo("");
  };

  const resetStates2 = () => {
    setAddsBool(false);
    setTermsBool(false);
    setTermStartMonth(null);
    setTermEndMonth(null);
    setActiveStartMonth(null);
    setActiveEndMonth(null);
    setCurrentMonth(null);
    setGroupNo("");
  };

  if (isLoading) {
    return (
      <div className="loader-container">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="">
      <form onSubmit={handleSubmit}>
        <h2 className="centered-title">Download a Copy of Your Census</h2>
        <Typography variant="body1" color={"textSecondary"} align="center">
          Selected Group: {selectedGroup || groupNo}
        </Typography>

        <Typography variant="body2" color="textSecondary">
          Enter the appropriate information below to download a copy of your census.
        </Typography>
        <CustomAlert message={alertMessage} isOpen={isAlertOpen} onClose={closeAlert} />
        {role !== "broker" && role !== "employer" ? (
          <TextField
            type="text"
            name="groupNumber"
            value={groupNo}
            onChange={handleGroupNumberChange}
            placeholder="Group Number"
          />
        ) : (
          <></>
        )}
        <Grid container spacing={2} alignItems="center" justifyContent="center">
          <Grid item xs>
            <Button
              type="button"
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleAddsClick}
              style={{
                backgroundColor: addsBool ? "#FF8C00" : "#004e9d",
                color: "white",
              }}
            >
              Adds Only
            </Button>
          </Grid>
          <Grid item xs>
            <Button
              type="button"
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleTermsClick}
              style={{
                backgroundColor: termsBool ? "#FF8C00" : "#004e9d",
                color: "white",
              }}
            >
              Terms Only
            </Button>
          </Grid>
          <Grid item xs>
            <Button type="button" fullWidth variant="contained" color="primary" onClick={(e) => handleCurrentMonth(e)}>
              Download Active Members
            </Button>
          </Grid>
          <Grid item xs>
            <Button type="button" fullWidth variant="contained" color="primary" onClick={resetStates2}>
              Clear Fields
            </Button>
          </Grid>
        </Grid>

        {addsBool && (
          <Grid container spacing={2}>
            <Grid item>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  openTo="month"
                  views={["year", "month"]}
                  label="Start Date"
                  value={activeStartMonth}
                  onChange={handleStartMonthChange}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  openTo="month"
                  views={["year", "month"]}
                  label="End Date"
                  value={activeEndMonth}
                  onChange={handleEndMonthChange}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        )}

        {termsBool && (
          <Grid container spacing={2}>
            <Grid item>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  openTo="month"
                  views={["year", "month"]}
                  label="Start Date"
                  value={termStartMonth}
                  onChange={handleTermStartMonthChange}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  openTo="month"
                  views={["year", "month"]}
                  label="End Date"
                  value={termEndMonth}
                  onChange={handleTermEndMonthChange}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        )}
        <button type="submit">Submit</button>
        {state.data.length > 0 && (
          <CSVLink data={state.data} filename={"download.csv"}>
            Download data
          </CSVLink>
        )}
      </form>
    </div>
  );
};

export default Census;
