import React, { useContext, useState, FormEvent, ChangeEvent } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { AuthContext } from "../authProvider/authProvider";
import { app } from "../../config";
import {
  getAuth,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
  sendEmailVerification,
  UserCredential,
  User,
} from "firebase/auth";
import { getFirestore, doc, getDoc, DocumentData } from "firebase/firestore";
import Alert from "@mui/lab/Alert";
import { Link } from "react-router-dom";
import "./Login.css";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { getMFAResolver, startMFASignIn } from "components/mfa/mfaHelper";
import CustomAlert from "components/alert/CustomAlert";

const auth = getAuth();
const db = getFirestore(app);

const LogIn: React.FC = () => {
  const [resetEmail, setResetEmail] = useState<string>("");
  const [showReset, setShowReset] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null); // New state for error
  const [showResend, setShowResend] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  // const [userCredentialInfo, setUserCredentialInfo] = useState<User | null>(null);
  const [isAlertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState<string>("");

  const openAlert = () => {
    setAlertOpen(true);
  };

  const closeAlert = () => {
    setAlertOpen(false);
  };

  const navigate = useNavigate();

  const authContext = useContext(AuthContext);
  // Get Auth context
  if (!authContext) {
    throw new Error("AuthContext is undefined");
  }
  const { setCurrentUser } = authContext;

  //const navigate = useNavigate(); // Hook to navigate between routes
  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        console.log("Signed out");
        //goToHome();
      })
      .catch((error) => {
        console.error("Error signing out", error);
      })
      .finally(() => {
        setIsLoading(false);
        console.log("Processing finished");
      });
  };

  console.log("this is show resend");
  console.log(showResend);

  // const goToHome = () => {
  //   navigate("/");
  // };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { email, password } = e.currentTarget.elements as any;

    // setIsLoading(true);
    signInWithEmailAndPassword(auth, email.value, password.value)
      .then(async (userCredential) => {
        //Clear any existing errors
        const user = userCredential.user;

        //setUserCredentialInfo(user)

        console.log("checking user verified email");
        console.log(user.emailVerified);
        if (user.emailVerified) {
          // User is signed in and has verified their email address
          console.log("Email verified. Proceed to the app.");
          //setShowResend(false)
        } else {
          // User is signed in but has not verified their email address
          // alert("You still need to verify your email");
          setAlertMessage("You still need to verify your email. A new email has been sent");
          setAlertOpen(true);
          //  setShowResend(true)
          console.log("Please verify your email before proceeding.");
          if (user) {
            // console.log("yooooooooooooo active")
            sendEmailVerification(user)
              .then(() => {
                // Email verification sent!
                //alert("Please verify your email")
              })
              .catch((error) => {
                console.error("Error sending email verification:", error);
              })
              .finally(() => {
                setIsLoading(false);
                console.log("Processing finished");
              });
          } else {
            setIsLoading(false);
            console.error("Current user is null");
          }

          // Optionally: Sign the user out
          handleSignOut();
        }
        setError(null);
      })
      .catch(async (error) => {
        console.log(error);

        const str = JSON.stringify(error);
        const parsedObj = JSON.parse(str);

        const codeValue = parsedObj.code;
        if (error.code === "auth/multi-factor-auth-required") {
          setIsLoading(false);
          const resolver = getMFAResolver(error);
          await startMFASignIn(resolver.hints[0], resolver.session);
          navigate("/signInMFA");
        } else if (codeValue === "auth/user-not-found") {
          setIsLoading(false);
          setError("User not found"); // Set the error message
        } else {
          setIsLoading(false);
          setError(codeValue); // Set the error message
        }
      });
  };

  console.log("this is error");
  console.log(typeof error);
  console.log(error);

  const handleReset = async () => {
    try {
      // setIsLoading(true);
      await sendPasswordResetEmail(auth, resetEmail);
      alert("Please check your email for password reset link");
    } catch (error) {
      setIsLoading(false);
      alert(error);
    }
  };

  const { currentUser } = authContext; // Get current user from Auth context
  if (currentUser) {
    return <Navigate to="/basePage" />;
  }

  const clearError = () => {
    setError(null);
  };

  // const handleResendEmail = () => {
  //   //const user = auth.currentUser;
  //   if (userCredentialInfo) {
  //     sendEmailVerification(userCredentialInfo)
  //       .then(() => {
  //         alert("Verification email resent. Please check your inbox.");
  //       })
  //       .catch((error) => {
  //         console.error("Error resending email verification:", error);
  //       });
  //   } else {
  //     console.error("Current user is null");
  //   }
  // };
  if (isLoading) {
    return (
      <div className="loader-container">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="login-background">
      <CustomAlert message={alertMessage} isOpen={isAlertOpen} onClose={closeAlert} />
      <>
        {error && (
          <div>
            <Alert severity="error">{error}</Alert>
            <div style={{ textAlign: "center" }}>
              <Button variant="contained" color="primary" onClick={clearError}>
                Dismiss
              </Button>
            </div>
          </div>
        )}
        <h1 className="login-title">Log In</h1>
        <form onSubmit={handleSubmit} className="login-form">
          <label htmlFor="email" className="login-label">
            Email
          </label>
          <input type="email" name="email" placeholder="Email" required className="login-input" />
          <label htmlFor="password" className="login-label">
            Password
          </label>
          <input type="password" name="password" placeholder="Password" required className="login-input" />
          <button id="sign-in-button" type="submit" className="login-button">
            Submit
          </button>
          <br />
          <button type="button" onClick={() => setShowReset(true)} className="forgot-password-button">
            Forgot Password
          </button>
          {/* {showResend && (
        <div style={{ textAlign: 'center' }}>
          <Button variant="contained" color="secondary" onClick={handleResendEmail}>
            Resend Confirmation Email
          </Button>
        </div>
      )} */}
        </form>

        {showReset && (
          <div className="login-reset">
            <h2 className="login-reset-title">Password Reset</h2>
            <input
              type="email"
              placeholder="Email for password reset"
              onChange={(e: ChangeEvent<HTMLInputElement>) => setResetEmail(e.target.value)}
              className="login-reset-input"
            />
            <button onClick={handleReset} className="login-reset-button">
              Reset Password
            </button>
          </div>
        )}

        <div className="sign-in-box">
          New user? <Link to="/signup">Sign up here</Link>.
        </div>

        {/* {showResend && (
        <div style={{ textAlign: 'center' }}>
          <Button variant="contained" color="secondary" onClick={handleResendEmail}>
            Resend Confirmation Email
          </Button>
        </div>
      )} */}
      </>
    </div>
  );
};

export default LogIn;
