import React, { useState, useContext, useEffect } from "react";
import { host } from "../../config";
import { AuthContext } from "../authProvider/authProvider";
import "./implementationStatus.css";
import {
  Box,
  Button,
  CircularProgress,
  Card,
  TextField,
  CardContent,
  Typography,
  Grid,
  Modal,
  Alert,
  InputLabel,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import CustomAlert from "components/alert/CustomAlert";
import { useNavigate } from "react-router-dom";

interface git_information {
  [key: string]: boolean | number | string;
  enrollments_uploaded: boolean;
  cards_approved_by_sales: boolean;
  cards_released_for_mail: boolean;
  first_bill_sent: boolean;
  binder_received: number;
  underwriting_review_complete: boolean;
  enrollments_verified: boolean;
  submitters_email_gif: string;
  binder_posted: boolean;
  ach_form: boolean;
}

interface colors {
  green_bool: boolean;
  yellow_bool: boolean;
  red_bool: boolean;
}

interface statusObj {
  [key: string]: string | boolean | number | git_information | colors;
  group_name: string;
  git_information: git_information;
  colors: colors;
}

interface CardProps {
  title: string;
  content: string;
}

const ImplementationStatus: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [groupNo, setGroupNo] = useState<string | null>(null);
  const [statusObj, setStatusObj] = useState<statusObj>({
    group_name: "",
    git_information: {
      enrollments_uploaded: false,
      cards_approved_by_sales: false,
      cards_released_for_mail: false,
      first_bill_sent: false,
      binder_received: 0,
      underwriting_review_complete: false,
      enrollments_verified: false,
      submitters_email_gif: "",
      binder_posted: false,
      ach_form: false,
    },
    colors: {
      green_bool: false,
      yellow_bool: false,
      red_bool: false,
    },
  });
  const [showCard, setShowCard] = useState<boolean>(false);
  const [cardColor, setCardColor] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);
  const [errorAlert, setErrorAlert] = useState<boolean>(false);
  const [errorText, setErrorText] = useState<string>("");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [isAlertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState<string>("");

  const openAlert = () => {
    setAlertOpen(true);
  };

  const closeAlert = () => {
    setAlertOpen(false);
  };
  useEffect(() => {
    console.log("Effect ran due to change in isAlertOpen or alertMessage", isAlertOpen, alertMessage);
    // rest of your effect logic
  }, [isAlertOpen, alertMessage]);

  const navigate = useNavigate();

  const field_names = {
    sent_docusign_docs: "Plan Contracts and Agreements sent electronically to Broker and/or group",
    ach_form: "Initial payment has been provided by the group",
    enrollments_verified: "Enrollments have been reviewed and verified for processing",
    enrollments_uploaded: "Enrollments have been uploaded to our system for card generation and billing",
    cards_approved_by_sales: "Quality check on cards completed",
    cards_released_for_mail: "Cards have been mailed",
    underwriting_review_complete: "Underwriting review has been completed",
    submitters_email_gif: "Group information online form has been completed, reviewed, and processed",
    binder_posted: "The group’s payment has been posted to their account",
    binder_received: "Our billing team has processed the group’s payment",
    first_bill_sent: "Initial invoice generated and provided to group",
    documents_signed: "Contracting Documents Signed",
  };

  const tooltips = {
    enrollments_uploaded:
      "We are currently reviewing the enrollments for accuracy and are working to assign them to the group in our system",
    cards_approved_by_sales: "Currently our team is reviewing the cards for accuracy and awaiting final approval.",
    cards_released_for_mail:
      "Cards have not completed the quality checks and are not released for creation and mailing",
    first_bill_sent: "We have not generated the groups initial invoice.",
    binder_received: "We have not received the groups initial payment",
    underwriting_review_complete: "We have not completed the underwriting for this group",
  };

  const authContext = useContext(AuthContext);
  if (!authContext) {
    throw new Error("AuthContext is undefined");
  }

  const handleGroupNumberChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setGroupNo(event.target.value);
  };

  const goToDocumentUploads = () => {
    navigate("/upload");
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setErrorAlert(false);
    setErrorText("");
    if (!groupNo) {
      alert("Please enter a group number");
    } else {
      let params = new URLSearchParams();
      if (groupNo != null) params.append("groupNo", groupNo);

      console.log(params);
      setIsLoading(true);

      fetch(`${host}/api/implementation/groupStatus?` + params, {
        method: "GET",
        headers: {},
      })
        .then(async (response: Response) => {
          setIsLoading(false);
          if (response.status != 200) {
            setErrorAlert(true);
            setErrorText(await response.text());
            setAlertMessage("Invalid Input");
            setAlertOpen(true);
          } else {
            response
              .json()
              .then((res) => {
                setStatusObj(res);
                console.log(typeof res.git_information["submitters_email_gif"]);
                if (res.colors.green_bool == true) {
                  setCardColor("green");
                  handleOpen();
                } else if (res.colors.red_bool == true) setCardColor("red");
                else if (res.colors.yellow_bool == true) setCardColor("yellow");
                setIsLoading(false);
              })
              .then(() => {
                setShowCard(true);
              });
          }
        })
        .catch((error: any) => {
          console.log(error);
          setIsLoading(false);
          setAlertMessage("Invalid group number");
          setAlertOpen(true);
        })
        .finally(() => {
          // Code to execute in the finally block
          // This will run regardless of whether an error occurred or not
          setIsLoading(false);
        });
    }
  };

  if (isLoading) {
    return (
      <div className="loader-container">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <h2 className="centered-title">OptiMed Health Group Implementation Status</h2>
        <p className="centered-text">
          Please enter the group number in order for us to provide an update for you on the group’s implementation. If
          you do not have a group number please contact your account manager or sales resource.
        </p>
        <TextField
          type="text"
          name="groupNumber"
          value={groupNo}
          onChange={handleGroupNumberChange}
          placeholder="Group Number"
        />
        {errorAlert && <Alert severity="error">{errorText}</Alert>}
        <Button type="submit" className="blue-button" style={{ backgroundColor: "#004e9d", color: "#ffffff" }}>
          Submit
        </Button>
      </form>
      <CustomAlert message={alertMessage} isOpen={isAlertOpen} onClose={closeAlert} />
      <div className="status-section">
        {showCard && (
          <div className="status-cards-container">
            <Box className="status-box" component="span">
              <h2>{statusObj.group_name}</h2>
              <p className="smaller-text">
                {cardColor === "green" ? (
                  <div>
                    <Modal
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box
                        sx={{
                          position: "absolute" as "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          width: 400,
                          bgcolor: "background.paper",
                          border: "1px solid #ddd",
                          boxShadow: 24,
                          p: 4,
                        }}
                      >
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                          Congratulations!
                        </Typography>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                          This group has been successfully set up!
                        </Typography>
                      </Box>
                    </Modal>
                  </div>
                ) : (
                  "We are processing your group. Please note that the tasks below with a green outline have been completed. We have also provided our Document Upload link at the bottom of the page should you need to send us any documents."
                )}
              </p>
              <Box
                component="span"
                sx={{
                  border:
                    cardColor == "green"
                      ? "4px solid green"
                      : cardColor == "yellow"
                      ? "4px solid yellow"
                      : "4px solid red",
                  backgroundColor: cardColor == "green" ? "green" : cardColor == "yellow" ? "yellow" : "red",
                  flex: "100%",
                  display: "flex",
                  borderRadius: "6px",
                }}
              ></Box>
            </Box>
            <Grid container spacing={2}>
              {Object.keys(statusObj.git_information).map((key, index) => (
                <Grid item xs={6} sm={12} md={12} key={index}>
                  {statusObj.git_information[key] == true ||
                  statusObj.git_information[key] == 100000002 ||
                  typeof statusObj["git_information"][key] === "string" ? (
                    <Card
                      className="status-card"
                      variant="outlined"
                      sx={{
                        minWidth: 275,
                        border:
                          statusObj["git_information"][key] == true ||
                          statusObj["git_information"][key] == 100000002 ||
                          typeof statusObj["git_information"][key] === "string"
                            ? "4px solid green"
                            : "4px solid red",
                      }}
                    >
                      <CardContent>
                        <Grid container>
                          <Grid item xs={6} sm={10} md={10}>
                            <Typography className="status-card-content" sx={{ fontSize: 14 }} color="text.secondary">
                              {field_names[key as keyof typeof field_names]}
                            </Typography>
                          </Grid>
                          <Grid item xs={2} sm={2} md={2}>
                            {statusObj["git_information"][key] == true ||
                            statusObj["git_information"][key] == 100000002 ||
                            typeof statusObj["git_information"][key] === "string" ? (
                              <div>
                                <CheckIcon style={{ float: "right", color: "green" }} />
                              </div>
                            ) : (
                              <div>
                                <CloseIcon style={{ float: "right", color: "red" }} />
                              </div>
                            )}
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  ) : (
                    <Card
                      className="missing-status-card"
                      variant="outlined"
                      sx={{
                        minWidth: 275,
                        border:
                          statusObj["git_information"][key] == true || statusObj["git_information"][key] == 100000002
                            ? "4px solid green"
                            : "4px solid red",
                      }}
                    >
                      <Tooltip title={tooltips[key as keyof typeof tooltips]}>
                        <CardContent>
                          <Grid container>
                            <Grid item xs={6} sm={10} md={10}>
                              <Typography className="status-card-content" sx={{ fontSize: 14 }} color="text.secondary">
                                {field_names[key as keyof typeof field_names]}
                              </Typography>
                            </Grid>
                            <Grid item xs={2} sm={2} md={2}>
                              {statusObj["git_information"][key] == true ||
                              statusObj["git_information"][key] == 100000002 ? (
                                <div>
                                  <CheckIcon style={{ float: "right", color: "green" }} />
                                </div>
                              ) : (
                                <div>
                                  <CloseIcon style={{ float: "right", color: "red" }} />
                                </div>
                              )}
                            </Grid>
                          </Grid>

                          {/* Conditionally render Tooltip */}
                        </CardContent>
                      </Tooltip>
                    </Card>
                  )}
                </Grid>
              ))}
              <Button
                className="navigateButton"
                type="button"
                variant="contained"
                onClick={goToDocumentUploads}
                sx={{
                  margin: "12px",
                  backgroundColor: "#004e9d",
                  color: "#ffffff",
                }}
              >
                Document Uploads
              </Button>
            </Grid>
          </div>
        )}
      </div>
    </div>
  );
};

export default ImplementationStatus;
// ... Existing code
export {};
