import React, {
  useState,
  useContext,
  FormEvent,
  ChangeEvent,
  useEffect,
  SyntheticEvent,
  MouseEventHandler,
} from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { getFirestore, collection, query, where, getDocs, setDoc, doc, deleteDoc, Timestamp } from "firebase/firestore";
import { getAuth, signInWithEmailAndPassword, User, signInWithCustomToken } from "firebase/auth";
import { app, host } from "../../config";
import { AuthContext } from "../authProvider/authProvider";
import "./UserSearch.css";
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip from "@mui/material/Tooltip";
import Modal from "react-modal";
import CustomAlert from "components/alert/CustomAlert";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogContent,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
//import MaintenanceIcon from '@mui/icons-material/Maintenance';
import ViewListIcon from "@mui/icons-material/ViewList";
//import ClaimIcon from '@mui/icons-material/Claim';
import UploadFileIcon from "@mui/icons-material/UploadFile";
import PaymentIcon from "@mui/icons-material/Payment";
import InfoIcon from "@mui/icons-material/Info";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import BuildIcon from "@mui/icons-material/Build";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import SearchIcon from "@mui/icons-material/Search";
import AnnouncementIcon from "@mui/icons-material/Announcement";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import TableChartIcon from "@mui/icons-material/TableChart";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import RateReviewIcon from "@mui/icons-material/RateReview";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import AssessmentIcon from "@mui/icons-material/Assessment";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";

// Firebase Authentication setup
const auth = getAuth();

interface UserData {
  id: string;
  data: any;
}

interface Provider {
  Payee_ID: string;
  Name: string;
  Federal_ID: string;
}

const UserSearch: React.FC = () => {
  // Initialize component state variables
  const [searchEmail, setSearchEmail] = useState<string>("");
  const [searchFirstName, setSearchFirstName] = useState<string>("");
  const [user, setUser] = useState<UserData | null>(null);
  const [searched, setSearched] = useState<boolean>(false);
  const [searchedFirstNameVeri, setSearchedFirstNameVeri] = useState<boolean>(false);
  const [newRole, setNewRole] = useState<string>("employer");
  const [isSuspended, setIsSuspended] = useState<boolean>(false);
  const [viewCommissions, setViewCommissions] = useState<boolean>(false);
  const [newPassword, setNewPassword] = useState<string>("");
  const [requestedGroupName, setRequestedGroupName] = useState<string>("");
  const [requestedAgencyName, setRequestedAgencyName] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [providers, setProviders] = useState<Provider[]>([]);
  const [selectedProvider, setSelectedProvider] = useState<string>(null);
  const [groupsList, setGroupsList] = useState([]);
  const [overrideTaxId, setOverrideTaxId] = useState("");
  const [users, setUsers] = useState([]);

  const [alertMessage, setAlertMessage] = useState<string>("");
  const [isAlertOpen, setAlertOpen] = useState(false);

  const [open, setOpen] = useState(false);
  const [disableOverride, setDisableOverride] = useState<boolean>(true);

  const [emailVerificationLink, setEmailVerificationLink] = useState<string>("");

  const [showFallbackLink, setShowFallbackLink] = useState(false);

  const [userSearchVerification, setuserSearchVerification] = useState(false);

  const [triggerEmailVerification, setTriggerEmailVerification] = useState(false);

  const [openTileAccess, setOpenTileAccess] = useState(false); // Rename or repurpose this if it's specifically for another modal
  const [selectedKeywords, setSelectedKeywords] = useState<string[]>([]);

  const openAlert = () => {
    setAlertOpen(true);
  };

  const closeAlert = () => {
    setAlertOpen(false);
  };
  // Get Firestore instance
  const db = getFirestore(app);

  const authContext = useContext(AuthContext); // Get Auth context
  if (!authContext) {
    throw new Error("AuthContext is undefined");
  }
  const { currentUser } = authContext; // Get current user from Auth context

  let tk: string;

  currentUser?.getIdToken(false).then((idToken) => {
    tk = idToken;
  });

  const navigate = useNavigate();

  const handleSearch = async () => {
    setSearched(false);
    setuserSearchVerification(false);
    //setEmailVerificationLink("");
    setUser(null);
    const q = query(collection(db, "user-info"), where("Email", "==", searchEmail));
    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      querySnapshot.forEach((doc) => {
        setUser({ id: doc.id, data: doc.data() });
        setIsSuspended(doc.data().Suspended || false);
        setViewCommissions(doc.data().View_Commissions || false);
        setNewRole(doc.data().Role);
        setRequestedGroupName(doc.data().Requested_Group_Name);
        setGroupsList(doc.data().Group_numbers);
        setSelectedKeywords(doc.data().tileAccess || []);
      });

      setTriggerEmailVerification((prev) => !prev);
    }

    setSearched(true);
    setSearchedFirstNameVeri(false);
  };

  var tileData = user?.data.tileAccess || [];

  const handleSearchByFirstName = async () => {
    setSearchedFirstNameVeri(false);

    // Trim the input and prepare both original, lowercase, and uppercase versions
    const originalName = searchFirstName.trim();
    const lowerName = originalName.toLowerCase();
    const upperName = originalName.toUpperCase();
    const titleName = originalName.charAt(0).toUpperCase() + originalName.slice(1).toLowerCase();

    // Define a set to track unique user IDs to avoid duplicate entries
    const foundUserIds = new Set();
    const foundUsers = [];

    // Helper function to process query results
    const processQuery = async (name) => {
      const q = query(collection(db, "user-info"), where("First_name", "==", name));
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        querySnapshot.forEach((doc) => {
          if (!foundUserIds.has(doc.id)) {
            // Check if the user ID is already added
            foundUsers.push({ id: doc.id, data: doc.data() });
            foundUserIds.add(doc.id); // Add ID to the set to track uniqueness
          }
        });
      }
    };

    // Execute queries for original, lowercase, and uppercase names
    await processQuery(searchFirstName);
    if (searchFirstName !== originalName) {
      await processQuery(originalName);
    }
    if (originalName !== lowerName) {
      await processQuery(lowerName);
    }
    if (
      originalName !== upperName &&
      lowerName !== upperName &&
      searchFirstName !== upperName &&
      titleName !== upperName
    ) {
      // Check to avoid redundant queries
      await processQuery(upperName);
    }
    if (
      titleName !== originalName &&
      titleName !== lowerName &&
      titleName !== upperName &&
      titleName !== searchFirstName
    ) {
      await processQuery(titleName);
    }

    // Update state with the found users
    setUsers(foundUsers);
    console.log(users);
    console.log("checking for users");
    setSearchedFirstNameVeri(true);
  };

  // const handleSearchByFirstName = async () => {
  //   setSearchedFirstNameVeri(false);
  //   const q = query(collection(db, "user-info"), where("First_name", "==", searchFirstName));
  //   const querySnapshot = await getDocs(q);
  //   const foundUsers = [];
  //   if (!querySnapshot.empty) {
  //     querySnapshot.forEach((doc) => {
  //       foundUsers.push({ id: doc.id, data: doc.data() });
  //     });
  //   }
  //   setUsers(foundUsers);
  //   console.log(users);
  //   console.log("checking for users");
  //   setSearchedFirstNameVeri(true);
  // };

  useEffect(() => {
    if (triggerEmailVerification) {
      const timer = setTimeout(() => {
        checkEmailVerification();
      }, 500); // Delaying checkEmailVerification by 500 milliseconds

      return () => clearTimeout(timer); // Cleanup to clear the timer if the component unmounts
    }
  }, [triggerEmailVerification]);

  const handleRoleChange = async () => {
    console.log(newRole);

    let data = {
      userId: user?.id,
      role: newRole,
    };

    setIsLoading(true);

    fetch(`${host}/api/firebase/api/CData3`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${tk}`,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then(async (data: any) => {
        console.log(data);
        console.log("trigger");

        handleSearch();
        setIsLoading(false);

        try {
        } catch (error) {
          console.error("Error:", error);
          setIsLoading(false);
        }
      })
      .catch((error: any) => console.error("Error:", error))
      .finally(() => {
        setIsLoading(false);
        console.log("Processing finished");
      });
  };

  const generateEmailVerification = async () => {
    console.log("checking for email");
    console.log(user?.data.Email);

    let data = {
      userId: user?.id,
      email: user?.data.Email,
    };

    setIsLoading(true);

    fetch(`${host}/api/firebase/api/emailGen`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${tk}`,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then(async (data: any) => {
        console.log(data);
        console.log("trigger");

        if (data.Link) {
          // Assuming 'link' is the property containing the verification link
          console.log("Active");
          setEmailVerificationLink(data.Link);
        }

        console.log("check link");

        console.log(emailVerificationLink);
        handleSearch();
        setIsLoading(false);

        try {
        } catch (error) {
          console.error("Error:", error);
          setIsLoading(false);
        }
      })
      .catch((error: any) => console.error("Error:", error))
      .finally(() => {
        setIsLoading(false);
        console.log("Processing finished");
      });
  };

  const checkEmailVerification = async () => {
    console.log("checking for email AT top");
    console.log(user?.data.Email);

    let data = {
      userId: user?.id,
      email: user?.data.Email,
    };

    setIsLoading(true);

    fetch(`${host}/api/firebase/api/emailVerification`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${tk}`,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then(async (data: any) => {
        console.log(data);
        console.log("trigger");

        console.log("check link");
        console.log(data.Verification);
        console.log(data.Message);
        setuserSearchVerification(data.Verification);

        // handleSearch();
        setIsLoading(false);

        try {
        } catch (error) {
          console.error("Error:", error);
          setIsLoading(false);
        }
      })
      .catch((error: any) => console.error("Error:", error))
      .finally(() => {
        setIsLoading(false);
        console.log("Processing finished");
      });
  };

  const handleSuspendChange = async () => {
    console.log(newRole);

    const newSuspendedStatus = !isSuspended;
    setIsSuspended(newSuspendedStatus);

    let data = {
      userId: user?.id,
      suspended: newSuspendedStatus,
    };

    setIsLoading(true);

    fetch(`${host}/api/firebase/api/CData4`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${tk}`,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then(async (data: any) => {
        console.log(data);
        console.log("trigger");

        handleSearch();

        try {
        } catch (error) {
          setIsLoading(false);
          console.error("Error:", error);
        }
      })
      .catch((error: any) => console.error("Error:", error))
      .finally(() => {
        setIsLoading(false);
        console.log("Processing finished");
      });
  };

  // const apiCall = async () => {
  //   let data = {
  //     uid: user?.id,
  //   };

  //   fetch(`${host}/api/firebase/api/data`, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       authorization: `Bearer ${tk}`,
  //     },
  //     body: JSON.stringify(data),
  //   })
  //     .then((response) => response.json())
  //     .then(async (data: any) => {
  //       console.log(data);
  //     })
  //     .catch((error) => console.error("Error:", error));
  // };

  // const handleSuspendToggle = async () => {
  //   const newSuspendedStatus = !isSuspended;
  //   setIsSuspended(newSuspendedStatus);
  //   if (user) {
  //     await setDoc(
  //       doc(db, "user-info", user.id),
  //       { Suspended: newSuspendedStatus },
  //       { merge: true }
  //     );
  //     handleSearch();
  //   }
  // };

  const handleTerminateUser = async () => {
    if (window.confirm("Are you sure you want to terminate this user? This action cannot be undone.")) {
      if (user) {
        setIsLoading(true);
        // await deleteDoc(doc(db, "user-info", user.id));

        fetch(`${host}/api/firebase/api/users/${user.id}`, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${tk}`,
          },
        })
          .then((response) => response.json())
          .then(async (data: any) => {
            handleSearch();
            setIsLoading(false);
          })
          .catch((error) => console.error("Error:", error))
          .finally(() => {
            setIsLoading(false);
            console.log("Processing finished");
          });
      }
    }
  };

  const handlePasswordReset = async () => {
    if (newPassword.length < 6) {
      alert("Password must be at least 6 characters long.");
      return;
    }

    if (user) {
      var pass1 = newPassword;
      var pass2 = user.data.Last_pwd1;
      var pass3 = user.data.Last_pwd2;
      var pass4 = user.data.Last_pwd3;

      // await setDoc(
      //   doc(db, "user-info", user.id),
      //   {
      //     Last_pwd5: pass4,
      //     Last_pwd4: pass3,
      //     Last_pwd3: pass2,
      //     Last_pwd2: pass1,
      //     Last_pwd1: newPassword,
      //     Password_reset_date: Timestamp.now(),
      //   },
      //   { merge: true }
      // );

      let resetData = {
        uid: user.id,
        password: newPassword,
        pass1: pass1,
        pass2: pass2,
        pass3: pass3,
        pass4: pass4,
      };

      setIsLoading(true);

      fetch(`${host}/api/firebase/api/resetData`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${tk}`,
        },
        body: JSON.stringify(resetData),
      })
        .then((response) => response.json())
        .then(async (data: any) => {
          handleSearch();
          setIsLoading(false);
        })
        .catch((error) => console.error("Error:", error))
        .finally(() => {
          setIsLoading(false);
          console.log("Processing finished");
        });
    }
  };

  const goToDashboardPortal = () => {
    navigate("/basePage");
  };

  // const handleGhostUser = async () => {
  //   //NEED TO IMPLEMENT MORE LEGITAMATE METHOD USING BACKEND SUPPORT AND SUCH
  //   if (user) {
  //     try {
  //       let decodedPassword = Buffer.from(user.data.Last_pwd1, "base64").toString("utf-8");
  //       await signInWithEmailAndPassword(auth, user.data.Email, decodedPassword);
  //       goToDashboardPortal();
  //     } catch (error) {
  //       console.error("Error:", error);
  //     }
  //   }
  // };

  const updateTileAccess = async () => {
    console.log("Button clicked and check");
    //setSelectedKeywords([]);
    console.log(selectedKeywords);
    // setSelectedKeywords([]);
    const keywordsToAdd = selectedKeywords;
    const documentId = "yourDocumentId"; // Adjust the document ID as needed

    let tileFile = {
      uid: user.id,
      tiles: keywordsToAdd,
    };

    setIsLoading(true); // Assuming you manage loading state
    console.log("Button clicked");

    fetch(`${host}/api/firebase/api/tileAccess`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${tk}`, // Include your auth token if needed
      },
      body: JSON.stringify({ tileFile }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.message) {
          console.log(data.message);
        } else {
          throw new Error(data.error || "Unknown error");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      })
      .finally(() => {
        setIsLoading(false);
        window.location.reload();
      });
  };

  const handleDisableMFA = async (uid: string) => {
    setIsLoading(true);

    fetch(`${host}/api/firebase/api/disableMFA`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${tk}`,
      },
      body: JSON.stringify({ uid: uid }),
    })
      .then((response) => response.json())
      .then(async (data: any) => {
        console.log(data);
        console.log("trigger");

        handleSearch();

        try {
        } catch (error) {
          setIsLoading(false);
          console.error("Error:", error);
        }
      })
      .catch((error: any) => console.error("Error:", error))
      .finally(() => {
        setIsLoading(false);
        console.log("Processing finished");
      });
  };

  const handleGhostUser = async () => {
    //NEED TO IMPLEMENT MORE LEGITAMATE METHOD USING BACKEND SUPPORT AND SUCH
    if (!user) {
      alert("User not found.");
      return;
    }

    setIsLoading(true);

    console.log("ghost user");

    try {
      fetch(`${host}/api/firebase/api/createCustomToken`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${tk}`,
        },
        body: JSON.stringify({ userId: user.id }),
      })
        .then((response) => response.json())
        .then(async (data: any) => {
          // console.log(data);
          console.log("trigger");

          var trackerToken = data.token;

          signInWithCustomToken(auth, trackerToken)
            .then((userCredential) => {
              // Signed in
              console.log("this is user");
              const user = userCredential.user;
              console.log(user);

              goToDashboardPortal();
              // ...
            })
            .catch((error) => {
              const errorCode = error.code;
              const errorMessage = error.message;
              console.log(errorCode);
              console.log(errorMessage);
              // ...
            });
        })
        .catch((error: any) => console.error("Error:", error))
        .finally(() => {
          setIsLoading(false);
          console.log("Processing finished");
        });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleCommissionsViewChange = () => {
    const newCommissionsView = !viewCommissions;
    setViewCommissions(newCommissionsView);

    let data = {
      userId: user?.id,
      bool: newCommissionsView,
    };

    console.log(data);

    setIsLoading(true);

    fetch(`${host}/api/firebase/api/CData5`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${tk}`,
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.ok) {
        }
        response
          .json()
          .then(async (data: any) => {
            console.log(data);
            console.log("trigger");

            await handleSearch();

            try {
            } catch (error) {
              setIsLoading(false);
              console.error("Error:", error);
            }
          })
          .catch((error: any) => console.error("Error:", error))
          .finally(() => {
            setIsLoading(false);
            console.log("Processing finished");
          });
      })
      .catch((error: any) => console.error("Error: ", error))
      .finally(() => {
        setIsLoading(false);
        console.log("Processing finished");
      });
  };

  const handleProviderModalClose = () => {
    setOpen(false);
  };

  const modalStyles2 = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "40%", // This line controls the width of the modal
      height: "85%", // This line controls the height of the modal
    },
  };

  const getPossibleProviders = async () => {
    setIsLoading(true);
    let params = new URLSearchParams();
    params.append("groups", groupsList.toString());
    await fetch(`${host}/api/upload/getPossibleProviders?${params}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${tk}`,
      },
    })
      .then((response) => {
        if (response.status == 201) {
          setIsLoading(false);
          response.json().then(async (data: any) => {
            console.log(data);
            setProviders(data.providers);
            setOpen(true);
          });
        } else {
          console.log("check error in response 200");
          setIsLoading(false);
          setAlertMessage(`Could not find any registrations for this group.`);
          setAlertOpen(true);
        }
      })
      .catch((error: any) => {
        console.log(error);
        setIsLoading(false);
        setAlertMessage("Error retrieving data. Please try again later.");
        setAlertOpen(true);
        console.log(isAlertOpen);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleOverrideChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedProvider(event.target.value as string);
  };

  const handleSelectProviderChange = (event: SyntheticEvent<Element, Event>, value: any) => {
    console.log(value?.Federal_ID);
    setSelectedProvider(null);
    if (value != null) setSelectedProvider(value?.Federal_ID);
  };

  const handleClick = () => {
    setDisableOverride(false);
  };

  const saveSelectedProvider = () => {
    if (selectedProvider) {
      const cleanSelectedProvider = selectedProvider.replace(/[^0-9 ]/g, "");

      let data = {
        userId: user?.id,
        agentId: cleanSelectedProvider,
      };

      console.log(data);

      setIsLoading(true);

      fetch(`${host}/api/firebase/api/CData6`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${tk}`,
        },
        body: JSON.stringify(data),
      })
        .then((response) => {
          if (response.ok) {
          }
          response
            .json()
            .then(async (data: any) => {
              console.log(data);
              console.log("trigger");

              // await handleSearch();

              try {
              } catch (error) {
                setIsLoading(false);
                console.error("Error:", error);
              }
            })
            .catch((error: any) => console.error("Error:", error))
            .finally(() => {
              setIsLoading(false);
              setOpen(false);
              console.log("Processing finished");
            });
        })
        .catch((error: any) => console.error("Error: ", error))
        .finally(() => {
          setIsLoading(false);
          console.log("Processing finished");
        });
    } else {
      setIsLoading(false);
      setAlertMessage("Error retrieving data. Please try again later.");
      setAlertOpen(true);
    }
  };

  const copyToClipboardFallback = (text) => {
    // Indicate that the fallback is being used
    setShowFallbackLink(true);
    // Existing fallback logic to manually copy text
    const textArea = document.createElement("textarea");
    textArea.value = text;
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      document.execCommand("copy");
      console.log("Fallback: Copying text command was successful");
    } catch (err) {
      console.error("Fallback: Oops, unable to copy", err);
    }
    document.body.removeChild(textArea);
  };

  const keywords = [
    // update this with a default list of keywords
    "Keyword1",
    "Keyword2",
    "Keyword3",
    "Keyword4",
    "cash",
    "Keyword1",
    "Keyword2",
    "Keyword3",
    "Keyword4",
    "cash",
  ]; // Example keywords

  const cardData = [
    {
      title: "Add an Employee",
      iconComponent: <PersonAddIcon className="card-iconHome" />,
    },
    {
      title: "Employee Maintenance",
      iconComponent: <PeopleAltIcon className="card-iconHome" />,
    },
    {
      title: "Download Census",
      iconComponent: <ViewListIcon className="card-iconHome" />,
    },
    {
      title: "Make Payments",
      iconComponent: <PaymentIcon className="card-iconHome" />,
    },
    {
      title: "Claims Look up",
      iconComponent: <SearchIcon className="card-iconHome" />,
    },
    {
      title: "Claim Submission",
      iconComponent: <AssessmentIcon className="card-iconHome" />,
    },
    {
      title: "Invoices",
      iconComponent: <ViewListIcon className="card-iconHome" />,
    },
    {
      title: "Policy And Certs",
      iconComponent: <ViewListIcon className="card-iconHome" />,
    },
    {
      title: "OptiEnhance Member Activation",
      iconComponent: <AddCircleOutlineIcon className="card-iconHome" />,
    },
    {
      title: "Commissions",
      iconComponent: <ViewListIcon className="card-iconHome" />,
    },
    {
      title: "Implementation Status",
      iconComponent: <SystemUpdateAltIcon className="card-iconHome" />,
    },
    {
      title: "Upload Documents",
      iconComponent: <UploadFileIcon className="card-iconHome" />,
    },
    {
      title: "OptiRater Product Quoting",
      iconComponent: <AssessmentIcon className="card-iconHome" />,
    },
    {
      title: "Census Upload",
      iconComponent: <TableChartIcon className="card-iconHome" />,
    },
    {
      title: "OptiEnhance Registration Table",
      iconComponent: <SystemUpdateAltIcon className="card-iconHome" />,
    },
    {
      title: "Admin Control Panel",
      iconComponent: <AdminPanelSettingsIcon className="card-iconHome" />,
    },
  ];

  const cardData2 = [
    {
      title: "/enroll",
      iconComponent: <PersonAddIcon className="card-iconHome" />,
    },
    {
      title: "/employeeMaintenance",
      iconComponent: <PeopleAltIcon className="card-iconHome" />,
    },
    {
      title: "/census",
      iconComponent: <ViewListIcon className="card-iconHome" />,
    },
    {
      title: "/payments",
      iconComponent: <PaymentIcon className="card-iconHome" />,
    },
    {
      title: "/claimsLookup",
      iconComponent: <SearchIcon className="card-iconHome" />,
    },
    {
      title: "Claim Submission",
      iconComponent: <AssessmentIcon className="card-iconHome" />,
    },
    {
      title: "/invoice",
      iconComponent: <ViewListIcon className="card-iconHome" />,
    },
    {
      title: "/policyAndCerts",
      iconComponent: <ViewListIcon className="card-iconHome" />,
    },
    {
      title: "OptiEnhance Member Activation",
      iconComponent: <AddCircleOutlineIcon className="card-iconHome" />,
    },
    {
      title: "/claimsCommission",
      iconComponent: <ViewListIcon className="card-iconHome" />,
    },
    {
      title: "/implementationStatus",
      iconComponent: <SystemUpdateAltIcon className="card-iconHome" />,
    },
    {
      title: "/upload",
      iconComponent: <UploadFileIcon className="card-iconHome" />,
    },
    {
      title: "OptiRater Product Quoting",
      iconComponent: <AssessmentIcon className="card-iconHome" />,
    },
    {
      title: "/censusUpload",
      iconComponent: <TableChartIcon className="card-iconHome" />,
    },
    {
      title: "/optienhanceReg",
      iconComponent: <SystemUpdateAltIcon className="card-iconHome" />,
    },
    {
      title: "/adminPanel",
      iconComponent: <AdminPanelSettingsIcon className="card-iconHome" />,
    },
  ];

  const cardData3 = [
    {
      title: "Add an Employee",
      iconComponent: <PersonAddIcon className="card-iconHome" />,
      route: "/enroll",
    },
    {
      title: "Employee Maintenance",
      iconComponent: <PeopleAltIcon className="card-iconHome" />,
      route: "/employeeMaintenance",
    },
    {
      title: "Download Census",
      iconComponent: <ViewListIcon className="card-iconHome" />,
      route: "/census",
    },
    {
      title: "Make Payments",
      iconComponent: <PaymentIcon className="card-iconHome" />,
      route: "/payments",
    },
    {
      title: "Claims Look up",
      iconComponent: <SearchIcon className="card-iconHome" />,
      route: "/claimsLookup",
    },
    {
      title: "Claim Submission",
      iconComponent: <AssessmentIcon className="card-iconHome" />,
      route: "Claim Submission", // Assuming no change needed here since it matches.
    },
    {
      title: "Invoices",
      iconComponent: <ViewListIcon className="card-iconHome" />,
      route: "/invoice",
    },
    {
      title: "Policy And Certs",
      iconComponent: <ViewListIcon className="card-iconHome" />,
      route: "/policyAndCerts",
    },
    {
      title: "OptiEnhance Member Activation",
      iconComponent: <AddCircleOutlineIcon className="card-iconHome" />,
      route: "OptiEnhance Member Activation", // Assuming no change needed here since it matches.
    },
    {
      title: "Commissions",
      iconComponent: <ViewListIcon className="card-iconHome" />,
      route: "/claimsCommission",
    },
    {
      title: "Implementation Status",
      iconComponent: <SystemUpdateAltIcon className="card-iconHome" />,
      route: "/implementationStatus",
    },
    {
      title: "Upload Documents",
      iconComponent: <UploadFileIcon className="card-iconHome" />,
      route: "/upload",
    },
    {
      title: "OptiRater Product Quoting",
      iconComponent: <AssessmentIcon className="card-iconHome" />,
      route: "OptiRater Product Quoting", // Assuming no change needed here since it matches.
    },
    {
      title: "Census Upload",
      iconComponent: <TableChartIcon className="card-iconHome" />,
      route: "/censusUpload",
    },
    {
      title: "OptiEnhance Registration Table",
      iconComponent: <SystemUpdateAltIcon className="card-iconHome" />,
      route: "/optienhanceReg",
    },
    {
      title: "Admin Control Panel",
      iconComponent: <AdminPanelSettingsIcon className="card-iconHome" />,
      route: "/adminPanel",
    },
  ];

  const handleKeywordChange = (title) => {
    setSelectedKeywords((prev) => {
      if (prev.includes(title)) {
        return prev.filter((k) => k !== title);
      } else {
        return [...prev, title];
      }
    });
  };

  const renderKeywordsModal = () => {
    const modalStyles: React.CSSProperties = {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "60%",
      backgroundColor: "#f7f7f7",
      padding: "20px",
      borderRadius: "10px",
      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
      position: "absolute",
    };

    const tileStyle: React.CSSProperties = {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      padding: "10px",
      margin: "5px",
      border: "1px solid #ccc",
      borderRadius: "5px",
      backgroundColor: "#fff",
      cursor: "pointer",
    };

    const buttonStyle: React.CSSProperties = {
      margin: "10px",
      padding: "10px 20px",
      fontSize: "16px",
      color: "#fff",
      backgroundColor: "#007BFF",
      border: "none",
      borderRadius: "5px",
      cursor: "pointer",
    };

    return (
      <Modal isOpen={openTileAccess} onRequestClose={() => setOpenTileAccess(false)} style={{ content: modalStyles }}>
        <Tooltip title="Select tiles to display for user, and deselect tile to hide">
          <h2>Select Tiles</h2>
        </Tooltip>
        <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
          {cardData3.map((item) => (
            <div key={item.title} style={tileStyle} onClick={() => handleKeywordChange(item.route)}>
              <input
                type="checkbox"
                checked={!selectedKeywords.includes(item.route)}
                onChange={() => handleKeywordChange(item.route)}
                id={item.title}
                style={{ marginRight: "5px" }}
              />
              <label htmlFor={item.title}>{item.title}</label>
              {item.iconComponent}
            </div>
          ))}
        </div>
        <button
          onClick={() => {
            updateTileAccess();
            setOpenTileAccess(false);
          }}
          style={buttonStyle}
        >
          Save
        </button>
        <button onClick={() => setOpenTileAccess(false)} style={buttonStyle}>
          Cancel
        </button>
      </Modal>
    );
  };
  const copyToClipboard = (text) => {
    if (!navigator.clipboard) {
      copyToClipboardFallback(text);
      return;
    }
    navigator.clipboard.writeText(text).then(
      () => {
        console.log("Async: Copying to clipboard was successful!");
      },
      (err) => {
        console.error("Async: Could not copy text: ", err);
        copyToClipboardFallback(text);
      }
    );
  };

  // Inside YourComponent

  const handleInputClick = (event: React.MouseEvent<HTMLInputElement>) => {
    const input = event.target as HTMLInputElement; // Type assertion
    input.select();
  };

  if (isLoading) {
    return (
      <div className="loader-container">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="user-search-container">
      <input
        type="email"
        value={searchEmail}
        placeholder="Please search for the email here"
        onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchEmail(e.target.value)}
        style={{
          width: "100%", // Changed from 300px to 100%
          padding: "10px",
          margin: "5px 0",
          boxSizing: "border-box",
          border: "2px solid #ccc",
          borderRadius: "4px",
        }}
      />
      <button
        onClick={() => {
          handleSearch();
          setEmailVerificationLink(""); // Add this to clear the email verification link
        }}
      >
        Search
      </button>

      <br />
      <br />

      <h5>Enter First Name here if you would like to see all users with that first name</h5>

      <input
        type="text"
        value={searchFirstName}
        placeholder="Enter first name"
        onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchFirstName(e.target.value)}
        style={{
          width: "100%", // Changed from 300px to 100%
          padding: "10px",
          margin: "5px 0",
          boxSizing: "border-box",
          border: "2px solid #ccc",
          borderRadius: "4px",
        }}
      />
      <button onClick={handleSearchByFirstName}>Search</button>

      {searchedFirstNameVeri && users.length === 0 && <h2>No users found with this first name.</h2>}

      {searchedFirstNameVeri && users.length > 0 && (
        <div>
          <h2>All Users Found with name: {searchFirstName}</h2>
          {users.map((user, index) => (
            <div key={index}>
              <p>First Name: {user.data.First_name}</p>
              <p>Email: {user.data.Email}</p>
              <p>Role: {user.data.Role}</p>
              {/* Display other user details as needed */}
            </div>
          ))}
        </div>
      )}

      {searched && !user && <h2>No user found with this email.</h2>}

      {user && (
        <div>
          <h2>User Found:</h2>
          <p>Email: {user.data.Email}</p>
          <p>Requested Role: {user.data.Requested_Role}</p>
          <p>Role: {user.data.Role}</p>
          <select value={newRole} onChange={(e: ChangeEvent<HTMLSelectElement>) => setNewRole(e.target.value)}>
            <option value="internal_ugp">Internal UGP</option>
            <option value="admin">Admin</option>
            <option value="broker">Broker</option>
            <option value="employer">Employer</option>
          </select>
          <p>Suspended: {isSuspended ? "Yes" : "No"}</p>
          <p>Can View Commissions: {viewCommissions ? "Yes" : "No"}</p>
          <button onClick={handleRoleChange}>Change Role</button>
          {user.data.Role === "broker" ? (
            <>
              <button onClick={handleCommissionsViewChange}>
                {viewCommissions ? "Restrict Commissions Viewing" : "Allow to View Comissions"}
              </button>
              <button onClick={getPossibleProviders}>Assign Provider</button>
            </>
          ) : null}
          {!userSearchVerification ? (
            <button onClick={generateEmailVerification}>Generate Email verification Link</button>
          ) : (
            <Tooltip title="Use this feature to generate an email verification link if user is unable to see verification email in their inbox">
              <button>Email has already been verified</button>
            </Tooltip>
          )}

          {/*<button onClick={apiCall}>API Call</button>*/}
          {emailVerificationLink && (
            <div>
              <p>Email Verification Link:</p>
              <div style={{ maxWidth: "100%", overflowWrap: "break-word", wordBreak: "break-all" }}>
                <Tooltip title="Copies Link. Opening the link will verify the email. Send link to user">
                  <a
                    onClick={() => copyToClipboard(emailVerificationLink)}
                    style={{
                      cursor: "pointer",
                      color: "blue",
                      textDecoration: "underline",
                      display: "inline-flex",
                      alignItems: "center",
                    }}
                  >
                    <span style={{ marginRight: "5px" }}>Copy Link</span>
                    <ContentCopyIcon onClick={() => copyToClipboard(emailVerificationLink)} style={{ color: "gray" }} />
                  </a>
                </Tooltip>
                {showFallbackLink && (
                  <Tooltip title="If you are having issues copying the link, feel free to send this URL">
                    <div style={{ marginTop: "10px" }}>
                      <p>Copy this link:</p>
                      <input
                        type="text"
                        value={emailVerificationLink}
                        readOnly
                        style={{ width: "100%" }}
                        onClick={handleInputClick}
                      />
                    </div>
                  </Tooltip>
                )}
              </div>
            </div>
          )}

          <button onClick={() => setOpenTileAccess(true)}>Update Tile Access</button>
          <br />

          <p>Password:</p>
          <input
            type="password"
            value={newPassword}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setNewPassword(e.target.value)}
            placeholder="Enter new password"
          />
          <button onClick={handlePasswordReset}>Reset Password</button>

          <button onClick={handleSuspendChange}>{isSuspended ? "Unsuspend User" : "Suspend User"}</button>
          <button onClick={handleTerminateUser}>Terminate User</button>
          <Tooltip title="If the user has not set up MFA or hasn't confirmed their email, you may not be able to ghost">
            <button onClick={handleGhostUser}>Ghost User</button>
          </Tooltip>

          <button onClick={() => handleDisableMFA(user.id)}>Reset MFA</button>

          <CustomAlert message={alertMessage} isOpen={isAlertOpen} onClose={closeAlert} />

          <div>{renderKeywordsModal()}</div>

          {open && (
            <Modal isOpen={true} onRequestClose={handleProviderModalClose} style={modalStyles2}>
              <Box>
                <Typography id="providers-modal-title" variant="h6" component="h2">
                  Please select a provider
                </Typography>
                <Autocomplete
                  disablePortal
                  id="provider-autocomplete"
                  options={providers}
                  getOptionLabel={(option) => option.Name}
                  sx={{ width: 300 }}
                  renderInput={(params) => <TextField {...params} label="Providers" />}
                  onChange={handleSelectProviderChange}
                />
                <Typography id="providers-overide-title" variant="h6" component="h3" margin={"normal"}>
                  Overide Selection
                </Typography>
                <RadioGroup>
                  <FormControlLabel
                    value="OverrideSelection"
                    control={<Radio onClick={handleClick} />}
                    label="OverrideSelection"
                  />
                </RadioGroup>
                <TextField
                  label="Federal TaxId"
                  sx={{ width: 300 }}
                  margin="normal"
                  type="text"
                  onChange={handleOverrideChange}
                  disabled={disableOverride ? true : false}
                />
              </Box>
              <Button variant="contained" onClick={saveSelectedProvider}>
                Save
              </Button>
            </Modal>
          )}
        </div>
      )}
    </div>
  );
};

export default UserSearch;
