import React from "react";
import "./gapPage.css";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Box,
  TextField,
  Typography,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import Copyright2 from "components/footerpt2/Copyright2";
import pic from "components/gapPage/Frame-1.svg";
import pic2 from "components/gapPage/Asset-7@2x.png";
import pic3 from "components/gapPage/arrow-1.svg";

import pic5 from "components/gapPage/TONYGAPOnePager.pdf";

import pic6 from "components/gapPage/CaitlinGAP.pdf";

const gapOptions: React.FC = () => {
  const openLink = (url: string) => {
    window.open(url, "_blank");
  };

  return (
    <div>
      <div className="content-container">
        {/* GAP Group Supplemental Information Component */}
        <div className="gap-group-container">
          <div className="gap-group-header">
            <div className="gap-group-icon" />
            <h1 className="gap-group-title">GAP GROUP SUPPLEMENTAL</h1>
          </div>
          <div className="gap-group-body">
            <h2 className="gap-group-subtitle">Close the GAP on employee out-of-pocket costs.</h2>
            <p className="gap-group-text">
              We know high-deductible health plans can leave employees with unexpected medical bills. GAP supplemental
              coverage can help.
            </p>
          </div>
        </div>

        {/* YouTube Video Component */}
        <div className="video-container">
          <iframe
            className="responsive-iframe"
            src="https://www.youtube.com/embed/djvqOOZ6YSY?start=14"
            title="YouTube video"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>

      <div className="second-content-container">
        {/* Geometric Graphic */}
        <div className="graphic-container">
          {/* Inline SVG or img tag goes here */}
          <img src={pic} alt="Geometric Graphic" />
        </div>

        {/* Text Section */}
        <div className="text-section">
          <div className="text-header">ABOUT THIS SOLUTION</div>
          <div className="text-title">
            GAP Coverage is your tool to manage the ever-increasing cost of medical care for employers and employees.
            Customize your health benefits for your team and your business.
          </div>
          <div className="text-body">
            Combining GAP with a low-cost, ACA-compliant high deductible health plan — such as OptiMed Level Funded —
            enables employers to reduce premiums while limiting employee out-of-pocket expense.
          </div>
        </div>
      </div>

      <div className="third-content-container">
        {/* Text Section */}
        <div className="text-container">
          <h2 className="text-title2">WHY OPTIMED</h2>
          <p className="text-body2">
            The customizability of GAP coverage enables employers to find the sweet spot of healthcare premiums versus
            coverage.
          </p>
          {/* <br /> */}
          <p className="text-body2">
            GAP coverage produces overall savings for both employers and employees, compared to major medical plans with
            lower deductibles. GAP can be offered with an employer contribution from 0 to 100 percent, depending on your
            preference. OptiMed processes all claims quickly, so you don’t have to wait for your money.
          </p>
          <p className="text-body2">
            Looking for a health plan to pair with GAP? Combining OptiMed’s Level Funded Major Medical Plan means just
            one health plan administrator (and one insurance card).
            {/* ... more text ... */}
          </p>
          <button className="learn-more-button">Learn more about OptiMed’s GAP Supplemental</button>
        </div>

        {/* Image Section */}
        <div className="image-container">
          <img src={pic2} alt="Family" className="responsive-image" />
          {/* <div className="geometric-overlay"></div> */}
        </div>
      </div>

      <div className="sixth-content-container">
        <iframe src={pic5} title="Sixth Content Container PDF" frameBorder="0"></iframe>
      </div>

      <div className="seventh-content-container">
        <iframe
          src={pic6}
          width="60%"
          height="1000px" // Adjust height as needed
          title="Seventh Content Container PDF"
        ></iframe>
      </div>

      <div className="fourth-content-container">
        <h2 className="solution-details-title">Solution Details</h2>
        <div className="columns-container">
          {/* Left Column */}
          <div className="column">
            <h3 className="column-title">INPATIENT COVERAGE</h3>
            <p className="column-text">
              covers eligible expenses if insured person is hospital confined under the regular care and attendance of a
              physician.
            </p>

            <h3 className="column-title">INPATIENT COVERAGE</h3>
            <p className="column-text">
              covers eligible expenses if insured person is hospital confined under the regular care and attendance of a
              physician.
            </p>

            <h3 className="column-title">INPATIENT COVERAGE</h3>
            <p className="column-text">
              covers eligible expenses if insured person is hospital confined under the regular care and attendance of a
              physician.
            </p>
            {/* ... Add other items for the left column ... */}
          </div>
          {/* Right Column */}
          <div className="column">
            <h3 className="column-title">OUTPATIENT COVERAGE</h3>
            <p className="column-text">
              covers eligible expenses for treatment at a licensed facility, including physician’s office, diagnostic
              clinic, urgent care clinic, outpatient surgical center and others.
            </p>

            <h3 className="column-title">OUTPATIENT COVERAGE</h3>
            <p className="column-text">
              covers eligible expenses for treatment at a licensed facility, including physician’s office, diagnostic
              clinic, urgent care clinic, outpatient surgical center and others.
            </p>
            {/* ... Add other items for the right column ... */}
          </div>
        </div>
      </div>

      <div className="fifth-content-container">
        <span className="cta-text">Contact us to get started today.</span>
        <button className="cta-button">
          <img src={pic3} alt="Arrow" />
        </button>
      </div>

      <div className="copyright-fixed23">
        <Copyright2 />
      </div>
    </div>
  );
};

export default gapOptions;
// ... Existing code
export {};
