import React, { useState, useEffect, useContext } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { AuthContext } from "../../authProvider/authProvider";
import { getAuth, signOut } from "firebase/auth";
import { doc, getDoc, getFirestore, DocumentData } from "firebase/firestore";
import { app, host } from "../../../config";
import "./optienhanceReg.css";
import { SortedVBADataType, VBADataType } from "./optienhanceRegComp";
import OptiEnhanceTable from "./optienhanceRegComp";

import { Pagination } from "@mui/material";
import { WLTDataType } from "../../employeeMaintenance/WLTApiDataComponent";
import CircularProgress from "@mui/material/CircularProgress";
import CustomAlert from "components/alert/CustomAlert";
import { Typography } from "@mui/material";

const auth = getAuth();
const db = getFirestore(app);

const EmpMaintenance: React.FC = () => {
  const [groupNumber, setGroupNumber] = useState("");
  const [docData, setDocData] = useState<DocumentData | null>(null);
  const [VBAApiData, setVBAApiData] = useState<VBADataType[]>([]);
  const [flattenedVBAApiData, setFlattenedVBAApiData] = useState<VBADataType[]>([]);
  const [showVBAApiData, setShowVBAApiData] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [search, setSearch] = useState<string | null>(null);

  const [showTerminated, setShowTerminated] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [isAlertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState<string>("");

  const openAlert = () => {
    setAlertOpen(true);
  };

  const closeAlert = () => {
    setAlertOpen(false);
  };

  const authContext = useContext(AuthContext);
  if (!authContext) {
    throw new Error("AuthContext is undefined");
  }
  const { currentUser, selectedGroup, role } = authContext;

  const navigate = useNavigate();

  useEffect(() => {
    if (VBAApiData?.length > 0) {
      const sorted: SortedVBADataType = {};
      let filteredApiData = search
        ? VBAApiData.filter(
            (obj) =>
              obj.first_name?.toLowerCase().indexOf(search.toLowerCase()) != -1 ||
              obj.last_name?.toLowerCase().indexOf(search.toLowerCase()) != -1 ||
              (obj.subscriber_id == "01" &&
                VBAApiData.some(
                  (nested_obj) =>
                    obj.subscriber_id == nested_obj.subscriber_id &&
                    (nested_obj.first_name?.toLowerCase().indexOf(search.toLowerCase()) != -1 ||
                      nested_obj.last_name?.toLowerCase().indexOf(search.toLowerCase()) != -1)
                ))
          )
        : VBAApiData;
      filteredApiData.reduce((acc, currentValue) => {
        let groupKey =
          currentValue["Subscriber_ID"] +
          currentValue["Plan_ID"] +
          currentValue["Start_Date"] +
          currentValue["Plan_End_Date"];
        if (!acc[groupKey]) {
          acc[groupKey] = [];
        }
        acc[groupKey].push(currentValue);
        return acc;
      }, sorted);
      let arr: VBADataType[] = [];
      let pageCount = Math.ceil(Object.keys(sorted).length / pageSize);
      setTotalPages(pageCount);
      Object.keys(sorted)
        .slice((page - 1) * pageSize, page * pageSize)
        .map((key) => {
          arr = arr.concat(sorted[key]);
        });
      setFlattenedVBAApiData(arr);
    }
  }, [VBAApiData, search, page]);

  const sampleData2: VBADataType = {
    group_name: "Tech Innovations LLC",
    group_number: "12345",
    first_name: "John",
    last_name: "Doe",
    dob: "1985-04-12",
    subscriber_id: "SUB123456",
  };

  function getData(params: URLSearchParams) {
    setIsLoading(true);
    fetch(`${host}/api/reports/optiEnhanceRegistrations?${params}`, {
      method: "GET",
      headers: {
        authorization: `Bearer ${tk}`,
      },
    })
      .then((response: Response) => {
        if (response.status === 200) {
          setIsLoading(false);
          response.json().then((res) => {
            console.log("response");
            console.log(res);
            setIsLoading(false);
            setVBAApiData(res.data);
            setShowVBAApiData(true);
          });
        } else {
          console.log("check error in response 200");
          setIsLoading(false);
          setAlertMessage(`Could not find any registrations for this group.`);
          setAlertOpen(true);
        }
      })
      .catch((error: any) => {
        console.log(error);
        console.log("check error in emp maintenance");
        setIsLoading(false);
        setAlertMessage("Error retrieving data. Please try again later.");
        setAlertOpen(true);
        console.log("checking state of alert");
        console.log(isAlertOpen);
      })
      .finally(() => {
        // Code to execute in the finally block
        // This will run regardless of whether an error occurred or not
        setIsLoading(false);
      });
  }

  useEffect(() => {
    async function getDocument() {
      if (currentUser == null) {
        return <Navigate to="/login" />;
      }

      let docRef = doc(db, "user-info", currentUser.uid);

      try {
        let docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setDocData(docSnap.data());
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.log("Error getting document:", error);
      }
    }

    getDocument();
  }, []);

  useEffect(() => {
    console.log("Effect ran due to change in isAlertOpen or alertMessage", isAlertOpen, alertMessage);
    // rest of your effect logic
  }, [isAlertOpen, alertMessage]);

  var tk: string;
  useEffect(() => {
    if (role === "broker" || role === "employer") {
      setGroupNumber(selectedGroup);
      currentUser
        ?.getIdToken(false)
        .then((idToken: string) => {
          tk = idToken;
          let params = new URLSearchParams();
          params.append("groupNo", selectedGroup);
          params.append("pageSize", pageSize.toString());
          getData(params);
          console.log("submit hit");
        })
        .catch((error: any) => {
          console.log(error);
        });
    }
  }, [selectedGroup]);

  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  if (role !== "admin") {
    return <Navigate to="/basePage" />;
  }

  currentUser
    ?.getIdToken(false)
    .then((idToken: string) => {
      tk = idToken;
    })
    .catch((error: any) => {
      console.log(error);
    });

  if (docData?.Suspended == true) {
    signOut(auth)
      .then(() => {
        console.log("Signed out");
        return <Navigate to="/login" />;
      })
      .catch((error) => {
        console.error("Error signing out", error);
      });
  }

  const goToEmploymentEnrollment = () => {
    navigate("/enroll");
  };

  const handleGroupNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGroupNumber(e.target.value);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (groupNumber === "") {
      alert("Please fill the Group Number field before submitting!");
      return;
    }

    let params = new URLSearchParams();
    params.append("groupNo", groupNumber);
    params.append("pageSize", pageSize.toString());
    params.append("terminated", showTerminated.toString());
    console.log("submit hit");

    setIsLoading(true);
    console.log("loading checker");

    getData(params);
  };

  const handlePagination = (e: any, p: number) => {
    // e.preventDefault();

    setPage(p);
  };

  const handleSetSearch = (str: string) => {
    setSearch(str);
    setPage(1);
  };

  const goBack = () => {
    if (role == "admin" || role == "internal_ugp") {
      setShowVBAApiData(false);
    } else {
      setShowVBAApiData(false);
    }
  };

  if (isLoading) {
    return (
      <div className="loader-container">
        <CircularProgress />
      </div>
    );
  }
  if (showVBAApiData) {
    return (
      <div className="App">
        <button className="back-button" onClick={goBack}>
          Back
        </button>
        {/* <Pagination count={totalPages} onChange={handlePagination} /> */}
        <OptiEnhanceTable
          data={VBAApiData}
          setIsLoading={setIsLoading}
          search={search}
          setSearch={handleSetSearch}
          totalPages={totalPages}
          handlePagination={handlePagination}
        />

        <div className="paginationHolder">
          <Pagination count={totalPages} onChange={handlePagination} />
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <div className="headerEmpMain">
          <h1>Optienhance Registration</h1>
          {/* <p>NOTE: Your changes will reflect 24 hours after submission.</p> */}
        </div>

        <CustomAlert message={alertMessage} isOpen={isAlertOpen} onClose={closeAlert} />

        <form onSubmit={handleSubmit}>
          <label>
            Group Number:
            <input
              type="text"
              value={groupNumber}
              onChange={handleGroupNumberChange}
              placeholder="Enter Group Number"
            />
          </label>
          <Typography variant="body1" color={"textSecondary"} align="center">
            Selected Group: {selectedGroup || groupNumber}
          </Typography>
          <button type="submit">Look up</button>
        </form>
        <div className="centerDiv">
          <button onClick={goToEmploymentEnrollment} className="navigateButton">
            Add an Employee
          </button>
        </div>
      </div>
    );
  }
};

export default EmpMaintenance;
