import { AuthContext } from "components/authProvider/authProvider";
import { getAuth, signOut } from "firebase/auth";
import { DocumentData, getFirestore } from "firebase/firestore";
import React, { useState, useEffect, useContext } from "react";
import { Navigate } from "react-router-dom";
import styles from "./eligibilityLook.module.css";
import { app, host } from "../../config";
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip from "@mui/material/Tooltip";
import CustomAlert from "components/alert/CustomAlert";
import { v4 as uuidv4 } from "uuid";
import TextField from "@mui/material/TextField";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import TablePagination from "@mui/material/TablePagination";
import Box from "@mui/material/Box";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import * as XLSX from "xlsx";

type Member = {
  Group_ID: string;
  Member_Id: string;
  SSN: string;
  Effective_Date: string;
  First_Name: string;
  Last_Name: string;
  DOB: string;
  Term_Date: string;
  Carrier: string;
  On_File_Date: string;
  uid?: string;
};

const auth = getAuth();
const db = getFirestore(app);

const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          backgroundColor: "white",
          borderRadius: "4px",
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "#ced4da",
            },
            "&:hover fieldset": {
              borderColor: "#80bdff",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#80bdff",
            },
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: "0.875rem",
        },
      },
    },
  },
  palette: {
    primary: {
      main: "#1976d2",
    },
  },
});

const EligibilityLookUp: React.FC = () => {
  const [members, setMembers] = useState<Member[]>([]);
  const [lastName, setLastName] = useState("");
  const [dob, setDob] = useState<string | null>(null);
  const [ssn, setSsn] = useState("");
  const [docData, setDocData] = useState<DocumentData | null>(null);
  const [groupNumber, setGroupNumber] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const [isAlertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState<string>("");

  const [filterLastName, setFilterLastName] = useState("");
  const [filterSSN, setFilterSSN] = useState("");
  const [filterDOB, setFilterDOB] = useState<string | null>(null);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [page2, setPage2] = useState(0);
  const [rowsPerPage2, setRowsPerPage2] = useState(10);

  const openAlert = () => {
    setAlertOpen(true);
  };

  const closeAlert = () => {
    setAlertOpen(false);
  };

  const authContext = useContext(AuthContext); // Get Auth context
  if (!authContext) {
    throw new Error("AuthContext is undefined");
  }
  const { currentUser, suspended, role, selectedGroup } = authContext; // Get current user from Auth context

  var tk: string;
  useEffect(() => {
    if (role === "broker" || role === "employer") {
      setGroupNumber(selectedGroup);
      currentUser
        ?.getIdToken(false)
        .then((idToken: string) => {
          tk = idToken;
          let params = new URLSearchParams();
          params.append("groupNo", selectedGroup);
          fetchMembers();
          console.log("submit hit");
        })
        .catch((error: any) => {
          console.log(error);
        });
    }
  }, [selectedGroup]);

  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  currentUser
    ?.getIdToken(false)
    .then((idToken: string) => {
      tk = idToken;
    })
    .catch((error: any) => {
      console.log(error);
    });

  // If the user is suspended, sign them out and navigate to the login page
  if (suspended == true) {
    signOut(auth)
      .then(() => {
        console.log("Signed out");
        return <Navigate to="/login" />;
      })
      .catch((error) => {
        console.error("Error signing out", error);
      });
  }

  const filteredMembers =
    role === "broker" || role === "employer"
      ? members.filter((member) => {
          const matchesLastName = filterLastName
            ? member.Last_Name.toLowerCase().includes(filterLastName.toLowerCase())
            : true;
          const matchesSSN = filterSSN ? member.SSN.includes(filterSSN) : true;
          const matchesDOB = filterDOB ? formatDisplayDate(member.DOB) === formatDisplayDate(filterDOB) : true;

          return matchesLastName && matchesSSN && matchesDOB;
        })
      : members;

  // const formatDate = (date: dayjs.Dayjs | null) => {
  //   return date ? date.format("YYYYMMDD") : "";
  // };

  const formatDate = (date) => (date ? parseDate(date).format("YYYY-MM-DD") : "");

  const parseDate = (dateStr) => {
    if (!dateStr) return null;

    // Check for "YYYYMMDD" format
    if (/^\d{8}$/.test(dateStr)) {
      return dayjs(dateStr, "YYYYMMDD");
    }

    // Check for "YYYY-MM-DD HH:mm:ss" format
    if (/^\d{4}-\d{2}-\d{2}/.test(dateStr)) {
      return dayjs(dateStr.split(" ")[0]); // Only take the date part
    }

    // Fallback: Try parsing with dayjs
    return dayjs(dateStr);
  };

  const formatDisplayDate = (dateStr) => {
    const parsedDate = parseDate(dateStr);
    return parsedDate ? parsedDate.format("MM/DD/YYYY") : "";
  };

  const handleDateChange = (date: dayjs.Dayjs | null) => {
    setDob(formatDate(date));
  };

  const fetchMembers = () => {
    // Check if DOB and SSN are both empty
    const isDobAndSsnEmpty = !dob && !ssn;

    // Check if either Group Number or Last Name is empty when DOB and SSN are empty
    if (selectedGroup === null || selectedGroup === undefined || selectedGroup === "") {
      if (!groupNumber && role != "admin" && role != "internal_ugp") {
        setAlertMessage("Please provide Group Number");
        setAlertOpen(true);
        return; // Prevent the search from continuing
      }
      if (isDobAndSsnEmpty && !lastName) {
        setAlertMessage("Please provide Last Name if Date of Birth and SSN are not provided.");
        setAlertOpen(true);
        return; // Prevent the search from continuing
      }
    }

    let params = new URLSearchParams();
    params.append("groupnumber", groupNumber);
    setIsLoading(true);

    console.log("lock in right here");

    let MemberBody = {
      lastName: lastName,
      dob: dob,
      ssn: ssn,
      groupNumber: selectedGroup || groupNumber, // Simplified logic
    };

    console.log("trigger");
    console.log(MemberBody);

    fetch(`${host}/api/enrollments/vba/eligibilityLookUp?` + params, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${tk}`,
      },
      body: JSON.stringify(MemberBody),
    })
      .then((response: Response) => {
        setIsLoading(false);
        response.json().then((res) => {
          if (response.status === 200) {
            const uniqueMembers = getUniqueMembers(res);
            setMembers(uniqueMembers);
            setAlertMessage(`The Members Have Been queried`);
          } else {
            setAlertMessage("No data found for these search criteria.");
            setMembers([]);
            setAlertOpen(true);
          }
        });

        console.log("this is success");
        console.log(response);
      })
      .catch((error: any) => {
        console.error(error);
        setAlertMessage(`There Was An Error`);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getUniqueMembers = (members: Member[]) => {
    const uniqueMembers = members.reduce((acc: Member[], current: Member) => {
      const x = acc.find(
        (item) =>
          item.Member_Id === current.Member_Id &&
          item.Carrier === current.Carrier &&
          item.SSN === current.SSN &&
          item.First_Name === current.First_Name
      );
      if (!x) {
        acc.push({ ...current, uid: uuidv4() });
      }
      return acc;
    }, []);
    return uniqueMembers;
  };

  const handleDownload = () => {
    const dataToDownload = members.map(({ uid, ...rest }) => rest);
    const worksheet = XLSX.utils.json_to_sheet(dataToDownload);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Members");
    XLSX.writeFile(workbook, "members.xlsx");
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const paginatedMembers = filteredMembers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const handleChangePage2 = (event: unknown, newPage: number) => {
    setPage2(newPage);
  };

  const handleChangeRowsPerPage2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage2(parseInt(event.target.value, 10));
    setPage2(0);
  };

  const paginatedMembers2 = members.slice(page2 * rowsPerPage2, page2 * rowsPerPage2 + rowsPerPage2);

  if (isLoading) {
    return (
      <div className="loader-container">
        <CircularProgress />
      </div>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div className={styles["myProj-body"]}>
          <h1 className={styles["myProj-h1"]}>Eligibility Data:</h1>
          <div className={styles["container"]}>
            <button className={styles["myProj-download-button"]} onClick={handleDownload}>
              Download
            </button>
          </div>
          <CustomAlert message={alertMessage} isOpen={isAlertOpen} onClose={closeAlert} />
          {(role === "broker" || role === "employer") && (
            <div className={styles["myProj-search-container"]}>
              <TextField
                label="Filter by Last Name"
                variant="outlined"
                value={filterLastName}
                onChange={(e) => setFilterLastName(e.target.value)}
                className={styles["myProj-search-input"]}
              />
              <TextField
                label="Filter by SSN"
                variant="outlined"
                value={filterSSN}
                onChange={(e) => setFilterSSN(e.target.value)}
                className={styles["myProj-search-input"]}
              />
              <DatePicker
                label="Filter by DOB"
                value={filterDOB ? dayjs(filterDOB) : null}
                onChange={(date) => setFilterDOB(formatDate(date))}
                slotProps={{
                  textField: {
                    variant: "outlined",
                    fullWidth: false,
                    InputProps: {
                      style: {
                        backgroundColor: "white",
                        borderRadius: "4px",
                        width: "200px", // Adjust the width of the DatePicker input here
                      },
                    },
                  },
                }}
              />
            </div>
          )}
          {(role === "admin" || role === "internal_ugp") && (
            <div className={styles["myProj-search-container"]}>
              <Tooltip title="Either Group Number or Last Name is required">
                <input
                  className={styles["myProj-search-input"]}
                  type="text"
                  value={groupNumber}
                  onChange={(e) => setGroupNumber(e.target.value)}
                  placeholder="Enter Group Number"
                />
              </Tooltip>

              <input
                className={styles["myProj-search-input"]}
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                placeholder="Enter Last Name"
              />
              <Tooltip title="DOB is optional">
                <div className={styles["date-picker-container"]}>
                  <DatePicker
                    label="Enter Date of Birth"
                    value={dob ? dayjs(dob, "YYYYMMDD") : null}
                    onChange={handleDateChange}
                    slotProps={{
                      textField: {
                        variant: "outlined",
                        fullWidth: false,
                        InputProps: {
                          style: {
                            backgroundColor: "white",
                            borderRadius: "4px",
                            width: "200px", // Adjust the width of the DatePicker input here
                          },
                        },
                      },
                    }}
                  />
                </div>
              </Tooltip>
              <Tooltip title="SSN is Optional">
                <input
                  className={styles["myProj-search-input"]}
                  type="text"
                  value={ssn}
                  onChange={(e) => setSsn(e.target.value)}
                  placeholder="Enter SSN"
                />
              </Tooltip>
              <button className={styles["myProj-button"]} onClick={fetchMembers}>
                Search
              </button>
            </div>
          )}

          {(role === "broker" || role === "employer") && (
            <table className={styles["myProj-table"]}>
              <thead>
                <tr>
                  <th className={styles["myProj-th"]}>Group Number</th>
                  <th className={styles["myProj-th"]}>Member Id</th>
                  <th className={styles["myProj-th"]}>SSN</th>
                  <th className={styles["myProj-th"]}>Effective Date</th>
                  <th className={styles["myProj-th"]}>First Name</th>
                  <th className={styles["myProj-th"]}>Last Name</th>
                  <th className={styles["myProj-th"]}>DOB</th>
                  <th className={styles["myProj-th"]}>Term Date</th>
                  <th className={styles["myProj-th"]}>Carrier</th>
                  <th className={styles["myProj-th"]}>Last On File Date</th>
                </tr>
              </thead>
              <tbody>
                {paginatedMembers.map((member) => (
                  <tr key={member.uid} className={styles["myProj-tr"]}>
                    <td className={styles["myProj-td"]}>{member.Group_ID}</td>
                    <td className={styles["myProj-td"]}>{member.Member_Id}</td>
                    <td className={styles["myProj-td"]}>{member.SSN}</td>
                    <td className={styles["myProj-td"]}>{formatDisplayDate(member.Effective_Date)}</td>
                    <td className={styles["myProj-td"]}>{member.First_Name}</td>
                    <td className={styles["myProj-td"]}>{member.Last_Name}</td>
                    <td className={styles["myProj-td"]}>{formatDisplayDate(member.DOB)}</td>
                    <td className={styles["myProj-td"]}>{formatDisplayDate(member.Term_Date)}</td>
                    <td className={styles["myProj-td"]}>{member.Carrier}</td>
                    <td className={styles["myProj-td"]}>{member.On_File_Date}</td>
                  </tr>
                ))}
              </tbody>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "100%",
                  marginTop: "10px",
                  paddingRight: "20px", // Optional: Adjust this to add some spacing from the right edge
                }}
              >
                <TablePagination
                  component="div"
                  count={filteredMembers.length}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Box>
            </table>
          )}

          {(role === "admin" || role === "internal_ugp") && (
            <table className={styles["myProj-table"]}>
              <thead>
                <tr>
                  <th className={styles["myProj-th"]}>Group Number</th>
                  <th className={styles["myProj-th"]}>Member Id</th>
                  <th className={styles["myProj-th"]}>SSN</th>
                  <th className={styles["myProj-th"]}>Effective Date</th>
                  <th className={styles["myProj-th"]}>First Name</th>
                  <th className={styles["myProj-th"]}>Last Name</th>
                  <th className={styles["myProj-th"]}>DOB</th>
                  <th className={styles["myProj-th"]}>Term Date</th>
                  <th className={styles["myProj-th"]}>Carrier</th>
                  <th className={styles["myProj-th"]}>Last On File Date</th>
                </tr>
              </thead>
              <tbody>
                {paginatedMembers2.map((member) => (
                  <tr key={member.uid} className={styles["myProj-tr"]}>
                    <td className={styles["myProj-td"]}>{member.Group_ID}</td>
                    <td className={styles["myProj-td"]}>{member.Member_Id}</td>
                    <td className={styles["myProj-td"]}>{member.SSN}</td>
                    <td className={styles["myProj-td"]}>{formatDisplayDate(member.Effective_Date)}</td>
                    <td className={styles["myProj-td"]}>{member.First_Name}</td>
                    <td className={styles["myProj-td"]}>{member.Last_Name}</td>
                    <td className={styles["myProj-td"]}>{formatDisplayDate(member.DOB)}</td>
                    <td className={styles["myProj-td"]}>{formatDisplayDate(member.Term_Date)}</td>
                    <td className={styles["myProj-td"]}>{member.Carrier}</td>
                    <td className={styles["myProj-td"]}>{member.On_File_Date}</td>
                  </tr>
                ))}
              </tbody>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "100%",
                  marginTop: "10px",
                  paddingRight: "20px", // Optional: Adjust this to add some spacing from the right edge
                }}
              >
                <TablePagination
                  component="div"
                  count={members.length}
                  page={page2}
                  onPageChange={handleChangePage2}
                  rowsPerPage={rowsPerPage2}
                  onRowsPerPageChange={handleChangeRowsPerPage2}
                />
              </Box>
            </table>
          )}
        </div>
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default EligibilityLookUp;
