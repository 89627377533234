// Import necessary packages and components
import React, { useContext, useState, useEffect } from "react";
import "./Home.css";
import { Link } from "react-router-dom";
import { AuthContext } from "../authProvider/authProvider";
import Button from "@mui/material/Button";
import "App.css";
import pic from "components/assets/logo-tran.png";
import logo from "components/assets/OptiAdminLogo.png";

//import pic from "logo.svg"

//import logo from "logo.svg"
//import useTypewriter from "react-typewriter-hook";
//import logo from "components/assets/logo.png";

const Home = () => {
  // Get the current user from the Auth context
  //const { currentUser } = useContext(AuthContext);
  var currentUser = false;
  // Set up the typewriter effect
  const message = "Web Portal";
  const [text, setText] = useState("Benefits Administration Portal");
  const intervalSpeed = 10; // Speed of the typewriter effect

  // type UseTypewriterType = (message: string, intervalSpeed: number) => string;

  // @ts-ignore
  //const useTypewriter: UseTypewriterType = require('react-typewriter-hook');

  // then use it as before
  //const magic = useTypewriter(message, intervalSpeed);
  //useEffect(() => {
  //  setText(magic);
  //}, [magic]);

  // Define the styles for the buttons
  const buttonStyle1 = {
    backgroundColor: "#004e9d",
    color: "#ffffff",
    "&:hover": {
      backgroundColor: "#00008B",
    },
    padding: "20px 30px",
    fontSize: "16px",
  };

  const buttonStyle2 = {
    backgroundColor: "#FF8C00",
    color: "#ffffff",
    "&:hover": {
      backgroundColor: "#FF8C00",
    },
    padding: "20px 30px",
    fontSize: "16px",
  };

  const forgotButtonStyle = {
    backgroundColor: "#808080",
    color: "#ffffff",
    "&:hover": {
      backgroundColor: "#696969",
    },
    padding: "15px 20px",
    fontSize: "14px",
    marginTop: "15px",
  };

  const forgotButtonWrapperStyle: React.CSSProperties = {
    textAlign: "center",
    marginTop: "75px", // Adding margin-top to push the button lower
  };

  // Define the style for the images
  const imageStyle = {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    width: "25%", // Adjust the image size
    marginTop: "80px", // Added margin-top for spacing
  };

  const logoStyle = {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    width: "30%", // Adjust the logo size
    marginTop: "40px", // Added margin-top for spacing
  };

  const handleButtonClick = () => {
    // Your logic for button click, perhaps using some values from state to call `fetchData`
    //FORGOT LOGIN INFO LOGIC HERE
  };

  // Return the JSX to render
  return (
    <>
      <div className="home-container">
        <img src={logo} style={logoStyle} /> {/* Display the logo */}
        <div className="cardFirst">
          <h1>{text}</h1> {/* Display the typewriter text */}
          <h1 className="desc">
            Our Benefits Administration portal provides access to Plan administration and management and reporting
          </h1>
        </div>
      </div>
      <div className="button-containerHomeScreen">
        {currentUser ? ( // If a user is logged in
          <>
            <p>You are logged in</p>
            <br />
            <Button variant="contained" component={Link} to="/dashboard" sx={buttonStyle1}>
              View Dashboard
            </Button>
          </>
        ) : (
          // If no user is logged in
          <>
            <div className="button-containerHomeScreen">
              <div>
                <Button variant="contained" component={Link} to="/login" sx={buttonStyle1}>
                  Log In
                </Button>
              </div>

              <div>
                <Button variant="contained" component={Link} to="/signup" sx={buttonStyle2}>
                  Register
                </Button>
              </div>
            </div>
          </>
        )}
      </div>
      <div className="button-container2">
        {/* <Button
              variant="contained"
              //to="/forgot-password"
              sx={forgotButtonStyle}
              onClick={() => {
                handleButtonClick();
              }}
            >
              Forgot Login
            </Button> */}
      </div>
      <img src={pic} alt="description" style={imageStyle} /> {/* Display the image */}
    </>
  );
};

export default Home;
