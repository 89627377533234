import React, { useContext, useState, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { app } from "../../config";
import { AuthContext } from "../authProvider/authProvider";
import { getAuth, signOut, sendPasswordResetEmail } from "firebase/auth";
import { doc, getDoc, getFirestore, DocumentData } from "firebase/firestore";
import "./AccountProfile.css";

type UserProfile = {
  email: string;
  role: string;
  name: string;
};

const auth = getAuth();
const db = getFirestore(app);

const AccountProfile: React.FC = () => {
  const authContext = useContext(AuthContext); // Get Auth context
  if (!authContext) {
    throw new Error("AuthContext is undefined");
  }
  const { currentUser, email, firstName, lastName, role } = authContext; // Get current user from Auth context

  const navigate = useNavigate(); // Hook to navigate between routes

  // If there's no current user, navigate to the login page
  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  const handleForgotPassword = async () => {
    // Logic to handle forgotten password
    try {
      await sendPasswordResetEmail(auth, email as string);
      alert("Please check your email for password reset link");
    } catch (error) {
      alert(error);
    }
  };

  return (
    <div className="account-profile">
      <h1>Account Profile</h1>
      <div>
        <label>Name: </label>
        <span>
          {firstName} {lastName}
        </span>
      </div>
      <div>
        <label>Email: </label>
        <span>{email}</span>
      </div>
      <div>
        <label>Role: </label>
        <span>{role}</span>
      </div>
      <div>
        <a href="#" onClick={handleForgotPassword}>
          Forgot Password?
        </a>
      </div>
      {/* Additional information can be added here */}
    </div>
  );
};

export default AccountProfile;
