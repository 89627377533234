import { AuthContext } from "components/authProvider/authProvider";
import { getAuth, signOut } from "firebase/auth";
import { DocumentData, getFirestore } from "firebase/firestore";
import React, { useState, useEffect, useContext } from "react";
import { Navigate } from "react-router-dom";
import styles from "./invoiceTable.module.css";
import { app, host } from "../../config";
import CircularProgress from "@mui/material/CircularProgress";
import CustomAlert from "components/alert/CustomAlert";

type Invoice = {
  id: string;
  groupNumber: string;
  newestSubFolder: string;
  name: string;
  invoiceDate: string; // The URL to the PDF file
};

// Mock data - you would fetch this from an API in a real application
// const invoicesData: Invoice[] = [
//   {
//     name: "20039_378_012224_0622.ACE.pdf",
//     month: "February",
//     year: 2024,
//     pdfUrl: "/path/to/your/pdf/20039_378_012224_0622.ACE.pdf",
//   },
//   // ... other invoices
// ];

// const [searchGroupId, setSearchGroupId] = useState<string>("");
const auth = getAuth();
const db = getFirestore(app);

const InvoiceTable: React.FC = () => {
  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [groupNumber, setGroupNumber] = useState("");
  const [docData, setDocData] = useState<DocumentData | null>(null);

  const [isLoading, setIsLoading] = useState(false);

  const [isAlertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState<string>("");

  const openAlert = () => {
    setAlertOpen(true);
  };

  const closeAlert = () => {
    setAlertOpen(false);
  };

  const authContext = useContext(AuthContext);
  if (!authContext) {
    throw new Error("AuthContext is undefined");
  }
  const { currentUser, selectedGroup, role } = authContext;

  const monthList = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const fetchInvoices = (passedGroupNumber: string) => {
    setIsLoading(true);
    if (!passedGroupNumber) passedGroupNumber = groupNumber;
    let params = new URLSearchParams();
    params.append("groupNumber", passedGroupNumber);
    const url = `${host}/api/upload/listInvoices?${params}`;
    fetch(url, {
      headers: {
        authorization: `Bearer ${tk}`,
      },
    })
      .then((response: Response) => {
        if (response.status === 200) {
          setIsLoading(false);
          response.json().then((res) => {
            console.log(res.data.flat());
            setInvoices(res.data.flat());
          });
        } else {
          setAlertMessage("No data found for this group number.");
          setInvoices([]);
          setAlertOpen(true);
        }
      })
      .catch((err) => console.error("Error fetching invoices: ", err))
      .finally(() => setIsLoading(false));
  };

  // const handleSearch = () => {
  //   // Call the fetchInvoices function with the group ID
  //   fetchInvoices(searchGroupId);
  // };

  var tk: string;
  useEffect(() => {
    if (role === "broker" || role === "employer") {
      setIsLoading(true);
      const passedGroupNumber = selectedGroup;
      setGroupNumber(selectedGroup);
      currentUser
        ?.getIdToken(false)
        .then((idToken: string) => {
          tk = idToken;
          fetchInvoices(passedGroupNumber);
        })
        .catch((error: any) => {
          console.log(error);
        });
    }
  }, [selectedGroup]);

  currentUser
    ?.getIdToken(false)
    .then((idToken: string) => {
      tk = idToken;
    })
    .catch((error: any) => {
      console.log(error);
    });

  if (docData?.Suspended == true) {
    signOut(auth)
      .then(() => {
        console.log("Signed out");
        return <Navigate to="/login" />;
      })
      .catch((error) => {
        console.error("Error signing out", error);
      });
  }

  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  if (role !== "admin" && role !== "broker" && role !== "employer" && role !== "internal_ugp") {
    return <Navigate to="/basePage" />;
  }

  // Function to handle downloading an invoice
  const downloadInvoice = (newestSubFolder: string, name: string, id: string) => {
    const fileReader = new FileReader();
    const data = {
      newestSubFolder: newestSubFolder,
      name: name,
    };
    // Use the Fetch API to get the PDF file as a blob
    fetch(
      `${host}/api/upload/downloadInvoice?groupNumber=${groupNumber}&newestSubFolder=${newestSubFolder}&name=${name}&id=${id}`,
      {
        method: "GET",
        headers: {
          authorization: `Bearer ${tk}`,
        },
      }
    ).then((response: Response) => {
      if (response.status === 200) {
        response.arrayBuffer().then((resp) => {
          var blob = new Blob([resp], { type: "application/pdf" });
          var url = URL.createObjectURL(blob);
          window.open(url);
        });
      } else {
        response.json().then((res) => {
          setAlertMessage(res.error);
          setAlertOpen(true);
        });
      }
    });
  };

  if (isLoading) {
    return (
      <div className="loader-container">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className={styles["myProj-body"]}>
      <h1 className={styles["myProj-h1"]}>Manage and Download Invoices for:</h1>

      <CustomAlert message={alertMessage} isOpen={isAlertOpen} onClose={closeAlert} />

      {role === "admin" || role === "internal_ugp" ? (
        <div className={styles["myProj-search-container"]}>
          <input
            className={styles["myProj-search-input"]}
            type="text"
            value={groupNumber}
            onChange={(e) => setGroupNumber(e.target.value)}
            placeholder="Enter group ID"
          />
          <button
            className={styles["myProj-button"]}
            onClick={() => {
              fetchInvoices(groupNumber);
            }}
          >
            Search
          </button>
        </div>
      ) : null}

      <table className={styles["myProj-table"]}>
        <thead>
          <tr>
            <th className={styles["myProj-th"]}>Name</th>
            <th className={styles["myProj-th"]}>Invoice Month</th>
            <th className={styles["myProj-th"]}>Invoice Year</th>
            <th className={styles["myProj-th"]}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {invoices.map((invoice) => (
            <tr key={invoice.name} className={styles["myProj-tr"]}>
              <td className={styles["myProj-td"]}>{invoice.name}</td>
              <td className={styles["myProj-td"]}>{invoice.newestSubFolder.split(" ")[0]}</td>
              <td className={styles["myProj-td"]}>{invoice.newestSubFolder.split(" ")[1]}</td>
              <td className={styles["myProj-td"]}>
                <button
                  className={styles["myProj-button"]}
                  onClick={() => downloadInvoice(invoice.newestSubFolder, invoice.name, invoice.id)}
                >
                  Download
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default InvoiceTable;
