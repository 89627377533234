// Importing necessary packages and components
import React, { useContext, useState, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { AuthContext } from "../authProvider/authProvider";
import { getAuth, signOut } from "firebase/auth";
import styles from "./Dashboard.module.css";

// Initialize Firebase Auth and Firestore
const auth = getAuth();

const Dashboard: React.FC = () => {
  const authContext = useContext(AuthContext); // Get Auth context
  if (!authContext) {
    throw new Error("AuthContext is undefined");
  }
  const { currentUser, suspended, firstName, lastName, role } = authContext; // Get current user from Auth context

  const navigate = useNavigate(); // Hook to navigate between routes

  // If there's no current user, navigate to the login page
  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  // Function to navigate to the upload page
  const goToUploadPage = () => {
    navigate("/upload");
  };

  const goToEmploymentEnrollment = () => {
    navigate("/enroll");
  };
  // Function to navigate to the admin portal
  const goToAdminPortal = () => {
    // navigate("/admin");
  };

  const goToClaims = () => {
    // navigate("/claims");
  };

  const goToClaimsLookup = () => {
    navigate("/claimsLookup");
  };

  const goToEmployeeMaintenance = () => {
    navigate("/employeeMaintenance");
  };

  const goToCensus = () => {
    navigate("/census");
  };

  const goToBasePage = () => {
    navigate("/basePage");
  };

  const goToPaymentsPage = () => {
    navigate("/payments");
  };

  const goToCensusUpload = () => {
    navigate("/censusUpload");
  };

  // If the user is suspended, sign them out and navigate to the login page
  if (suspended == true) {
    signOut(auth)
      .then(() => {
        console.log("Signed out");
        return <Navigate to="/login" />;
      })
      .catch((error) => {
        console.error("Error signing out", error);
      });
  }

  // console.log(docData?.Role)

  // Render the dashboard
  return (
    <div className={styles.dashboard}>
      <h1>
        Welcome {firstName} {lastName}
      </h1>

      <p>This is the dashboard, if you can see this you're logged in.</p>

      <button
        onClick={() =>
          signOut(auth)
            .then(() => {
              console.log("Signed out");
            })
            .catch((error) => {
              console.error("Error signing out", error);
            })
        }
      >
        Sign out
      </button>

      <button onClick={goToUploadPage}>Document Uploads Page</button>

      <button onClick={goToEmploymentEnrollment}>Employee Enrollments</button>

      <button onClick={goToClaims} hidden>
        Go to Claims
      </button>

      <button onClick={goToClaimsLookup}>Claims Look Up</button>

      <button onClick={goToEmployeeMaintenance}>Employee Maintenance</button>

      <button onClick={goToCensus}>View a Census</button>

      <button onClick={goToBasePage}>Go to Card Page</button>

      <button onClick={goToPaymentsPage}>Payments</button>

      <button onClick={goToCensusUpload}>Group Census Upload</button>

      {role === "admin" && (
        <button onClick={goToAdminPortal} hidden>
          Go to Admin Portal
        </button>
      )}
    </div>
  );
};

export default Dashboard;
