import React, { useEffect, useState, ReactNode } from "react";
import { app, auth, host } from "config";
import { User, getAuth, signOut } from "firebase/auth";
import { DocumentData, doc, getDoc, getFirestore } from "firebase/firestore";

type authContextType = {
  currentUser: User | null;
  setCurrentUser: Function;
  role: string | null;
  setRole: Function;
  firstName: string | null;
  setFirstName: Function;
  lastName: string | null;
  setLastName: Function;
  email: string | null;
  setEmail: Function;
  suspended: boolean;
  setSuspended: Function;
  authorizedGroupNumber: string | null;
  setAuthorizedGroupNumber: Function;
  authorizedGroupNumbers: string[] | null;
  tileAccess: string[] | null;
  setTileAccess: Function;
  setAuthorizedGroupNumbers: Function;
  loading: boolean;
  setLoading: Function;
  selectedGroup: string;
  setSelectedGroup: Function;
  groupNames: groupNamesType;
  setGroupNames: Function;
  hasMultiFactor: boolean;
  setHasMultiFactor: Function;
  canViewCommissions: boolean;
  setCanViewCommissions: Function;
  agentId: string | null;
  setAgentId: Function;
  agencyName: string;
  setAgencyName: Function;
};

type groupNamesType = {
  [key: string]: string;
};

export const AuthContext = React.createContext<authContextType | undefined>(undefined);

interface AuthProviderProps {
  children: ReactNode;
}

const db = getFirestore(app);

console.log("this is auth");
console.log(auth);

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [role, setRole] = useState<string | null>(null);
  const [firstName, setFirstName] = useState<string | null>(null);
  const [lastName, setLastName] = useState<string | null>(null);
  const [email, setEmail] = useState<string | null>(null);
  const [suspended, setSuspended] = useState<boolean>(false);
  const [authorizedGroupNumber, setAuthorizedGroupNumber] = useState<string | null>(null);
  const [authorizedGroupNumbers, setAuthorizedGroupNumbers] = useState<string[] | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedGroup, setSelectedGroup] = useState<string>("");
  const [groupNames, setGroupNames] = useState<groupNamesType>({});
  const [canViewCommissions, setCanViewCommissions] = useState<boolean>(false);
  const [hasMultiFactor, setHasMultiFactor] = useState(false);
  const [agentId, setAgentId] = useState<string | null>(null);
  const [agencyName, setAgencyName] = useState<string | null>(null);
  const [tileAccess, setTileAccess] = useState<string[] | null>(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      setLoading(true);
      if (user) {
        setCurrentUser(user);
        const uid = user.uid as string;
        const userDoc = doc(db, "user-info", uid);
        try {
          // Fetch the document from Firestore
          let docSnap = await getDoc(userDoc);

          // If the document exists, save its data to state
          if (docSnap.exists()) {
            const data: DocumentData = docSnap.data();
            console.log("Test data tile access");
            console.log(data);
            console.log(data?.tileAccess);
            setFirstName(data?.First_name);
            setLastName(data?.Last_name);
            setRole(data?.Role);
            setEmail(data?.Email);
            setSuspended(data?.Suspended);
            setAuthorizedGroupNumber(data?.Group_number);
            setAuthorizedGroupNumbers(data?.Group_numbers);
            setAgencyName(data?.Requested_Agency_Name);
            console.log(data?.Group_number);
            setTileAccess(data?.tileAccess);
            if (data?.Role == "broker" && data?.Group_numbers != null && data?.Group_numbers.length > 0) {
              setCanViewCommissions(data?.View_Commissions);
              setAgentId(data?.Agent_ID);
              data?.Group_numbers?.length > 0 ? setSelectedGroup(data?.Group_numbers[0]) : setSelectedGroup("");
              user.getIdToken().then((idToken) => {
                fetch(`${host}/api/enrollments/vba/groupNames/broker`, {
                  method: "GET",
                  headers: {
                    authorization: `Bearer ${idToken}`,
                  },
                }).then((response: Response) => {
                  response.json().then((res) => {
                    let groupNames: groupNamesType = {};
                    res.data.map((x: any) => {
                      Object.assign(groupNames, { [x["Group_ID"]]: x["Name"] });
                    });
                    setGroupNames(groupNames);
                  });
                });
              });
            } else if (data?.Role == "employer" && data?.Group_number != null && data?.Group_number != "") {
              setSelectedGroup(data?.Group_number);
              await user.getIdToken().then((idToken) => {
                fetch(`${host}/api/enrollments/vba/groupNames/employer`, {
                  method: "GET",
                  headers: {
                    authorization: `Bearer ${idToken}`,
                  },
                }).then((response: Response) => {
                  response.json().then((res) => {
                    let groupNames: groupNamesType = {};
                    res.data.map((x: any) => {
                      Object.assign(groupNames, { [x["Group_ID"]]: x["Name"] });
                    });
                    setGroupNames(groupNames);
                  });
                });
              });
            }
            await user.getIdToken().then((idToken) => {
              fetch(`${host}/api/firebase/api/enrolledIn2FA`, {
                method: "GET",
                headers: {
                  authorization: `Bearer ${idToken}`,
                },
              }).then((response: Response) => {
                response.json().then((res) => {
                  setHasMultiFactor(res.data.hasMultiFactor);
                  setLoading(false);
                });
              });
            });
          } else {
            console.log("No such document!");
            setLoading(false);
          }
        } catch (error) {
          console.log("Error getting document:", error);
        }
      } else {
        setRole(null);
        setFirstName(null);
        setLastName(null);
        setEmail(null);
        setSuspended(false);
        setAuthorizedGroupNumber(null);
        setAuthorizedGroupNumbers(null);
        setSelectedGroup("");
        setCurrentUser(null);
        setLoading(false);
        setCanViewCommissions(false);
        setAgentId(null);
        setTileAccess(null);
      }
    });
    let inactivityTimer: NodeJS.Timeout;
    const MAX_INACTIVITY_TIME_MS = 1800000; // 30 minutes

    // 30 minutes

    const resetInactivityTimer = () => {
      clearTimeout(inactivityTimer);
      inactivityTimer = setTimeout(() => {
        logout(); // Call the logout function after the inactivity period
      }, MAX_INACTIVITY_TIME_MS);
    };

    document.addEventListener("mousemove", resetInactivityTimer);
    document.addEventListener("keypress", resetInactivityTimer);
    document.addEventListener("click", resetInactivityTimer);

    resetInactivityTimer();

    return () => {
      clearTimeout(inactivityTimer);
      document.removeEventListener("mousemove", resetInactivityTimer);
      document.removeEventListener("keypress", resetInactivityTimer);
      document.removeEventListener("click", resetInactivityTimer);
      unsubscribe();
    };
  }, []);

  const logout = () => {
    signOut(auth)
      .then(() => {
        setCurrentUser(null);
        setRole(null);
      })
      .catch((error) => {
        console.error("Logout Error:", error);
      });
  };

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        setCurrentUser,
        role,
        setRole,
        firstName,
        setFirstName,
        lastName,
        setLastName,
        email,
        setEmail,
        suspended,
        setSuspended,
        authorizedGroupNumber,
        setAuthorizedGroupNumber,
        authorizedGroupNumbers,
        setAuthorizedGroupNumbers,
        loading,
        setLoading,
        selectedGroup,
        setSelectedGroup,
        groupNames,
        setGroupNames,
        hasMultiFactor,
        setHasMultiFactor,
        canViewCommissions,
        setCanViewCommissions,
        agentId,
        setAgentId,
        agencyName,
        setAgencyName,
        tileAccess,
        setTileAccess,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
