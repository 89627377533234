// Importing fonts from @fontsource package
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

// Importing necessary packages and components from MUI and React
import { Typography, Link } from "@mui/material";
import React, { FC } from "react";

// Importing the custom CSS file
import "./Copyright.css";

// Importing logo image
//import logo from "./logo.png";

import logo from "./OptiAdminLogo.png";

// Copyright Components
const Copyright: FC = () => {
  // Rendering the Copyright Component
  return (
    <footer className="footer-style">
      <div className="first-div-style">
        <ul className="permalinks">
          <li>
            <a href="#">Home</a>
          </li>
          <li>
            <a href="#about">About</a>
          </li>
          <li>
            <a href="/claims">Claims</a>
          </li>
          <li>
            <a href="/upload">Upload</a>
          </li>
          <li>
            <a href="/ClaimsLookup">Claims Search</a>
          </li>
          <li>
            <a href="#">Support</a>
          </li>
          <li>
            <a href="#testimonials">Testimonials</a>
          </li>
          <li>
            <a href="#contact">FAQ</a>
          </li>
        </ul>
      </div>
      <div className="container-style">
        <img src={logo} className="img-style" alt="Logo" />
        <div className="copy">
          <Typography variant="body2" color="textSecondary" align="center">
            {"Copyright © "}
            <Link color="inherit">OptiMed Health</Link> {new Date().getFullYear()}
            {"."}
          </Typography>
        </div>
      </div>
    </footer>
  );
};

export default Copyright;
