// Copyright component
import React, { FC } from "react";
import { Typography, Link } from "@mui/material";
import logo from "components/assets/logo.png";
import "./Copyright2.css";

const Copyright: FC = () => {
  return (
    <footer className="footer-style2 footer2">
      <div className="first-div-style2">{/* ... */}</div>
      <div className="container-style2">
        <div className="copy2">
          <Typography variant="body2" color="textSecondary" align="center">
            {"Copyright © "}
            <Link color="inherit">OptiMed Health</Link> {new Date().getFullYear()}
            {"."}
          </Typography>
        </div>
        <img src={logo} className="img-style2" alt="Logo" />
      </div>
    </footer>
  );
};

export default Copyright;
