import React, { useState, useEffect, useContext, FormEvent, ChangeEvent } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { getAuth, User } from "firebase/auth";
import { AuthContext } from "../../authProvider/authProvider";
import {
  getFirestore,
  doc,
  updateDoc,
  setDoc,
  collection,
  Timestamp,
  onSnapshot,
  getDoc,
  DocumentData,
} from "firebase/firestore";
import { auth, app, host } from "../../../config";
import Roleeee from "../role";
import UserSearch from "../UserSearch";
import "./AddUserAdmin.css";
import CircularProgress from "@mui/material/CircularProgress";
import Main from "components/main/main";

interface DocData {
  Role?: string;
  Suspended?: boolean;
}

const AddUserAdmin: React.FC = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [selectedRole, setSelectedRole] = useState<string>("employer");
  const [firstname, setFirstname] = useState<string>("");
  const [lastname, setLastname] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);

  const auth2 = getAuth();
  const navigate = useNavigate(); // Hook to navigate between routes

  const authContext = useContext(AuthContext); // Get Auth context
  if (!authContext) {
    throw new Error("AuthContext is undefined");
  }
  const { currentUser, role, suspended } = authContext; // Get current user from Auth context

  var tk: string;
  currentUser
    ?.getIdToken(false)
    .then((idToken: string) => {
      tk = idToken;
    })
    .catch((error: any) => {
      console.log(error);
    });

  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  if (role !== "admin") {
    return <Navigate to="/basePage" />;
  }

  if (suspended === true) {
    return <Navigate to="/login" />;
  }

  const goToDashboard = () => {
    navigate("/dashboard");
  };

  const goBack = () => {
    if (role === "admin") {
      navigate("/adminPanel");
    }
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (password.length < 6) {
      alert("password needs to be at least 6 characters");
    }

    try {
      let data = {
        Email: email,
        Password: password,
        FirstName: firstname,
        LastName: lastname,
        Role: selectedRole,
      };

      setIsLoading(true);

      fetch(`${host}/api/firebase/api/CData`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${tk}`,
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then(async (data: any) => {
          // console.log(data)
          //console.log("trigger")
          if (data.message === "Email used") {
            alert("Email Already in use");
          }

          setEmail("");
          setPassword("");
          setSelectedRole("employee");
          setFirstname("");
          setLastname("");
          try {
            alert("Account successfully created");
          } catch (error) {
            console.error("Error:", error);
            alert(error);
          }
        })
        .catch((error: any) => console.error("Error:", error))
        .finally(() => {
          setIsLoading(false);
          console.log("Processing finished");
        });
    } catch (error) {
      setIsLoading(false);
      console.error("Error:", error);
    }
  };

  if (isLoading) {
    return (
      <div className="loader-container">
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      <div className="admin-portal-container">
        <button className="back-buttonAddUser" onClick={goBack}>
          Back
        </button>
        <div className="headerAdminCard">
          <h1>User Add</h1>
          <p className="adminUserNote">Fill in the fields to add a User</p>
        </div>
        <form onSubmit={handleSubmit} className="form-style">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            value={email}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
            className="input-style"
          />
          <label htmlFor="password">Password</label>
          <input
            type="password"
            value={password}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
            className="input-style"
          />
          <label htmlFor="firstname">First Name</label>
          <input
            type="text"
            name="firstname"
            placeholder="First Name"
            className="input-style"
            value={firstname}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setFirstname(e.target.value)}
          />
          <label htmlFor="lastname">Last Name</label>
          <input
            type="text"
            name="lastname"
            placeholder="Last Name"
            className="input-style"
            value={lastname}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setLastname(e.target.value)}
          />
          <select
            value={selectedRole}
            onChange={(e: ChangeEvent<HTMLSelectElement>) => setSelectedRole(e.target.value)}
            className="select-style"
          >
            <option value="employee">Employee</option>
            <option value="admin">Admin</option>
            <option value="broker">Broker</option>
            <option value="employer">Employer</option>
            <option value="internal_ugp">Internal UGP</option>
          </select>
          <button type="submit" className="button-style">
            Add user
          </button>
        </form>
        <button onClick={() => alert(`Current user's email is: ${currentUser?.email}`)} className="button-style">
          Show Current User's Email
        </button>

        {/* <button
          onClick={() =>
           goToDashboard
          }
          className="button-style"
        >
         Activate Old Dashboard
        </button> */}
      </div>
    </>
  );
};

export default AddUserAdmin;
